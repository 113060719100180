import classNames from 'classnames'
import { Select } from 'components/UIComponents'

export const ArraySubQuestionNumberAnswers = ({
  index,
  subQuestionindex,
  subQuestionsHeight = [],
  arrayAnswersWidth = [],
  dragIconSize = 1,
  showBackgroundUnderSubQuestion = false,
}) => {
  const options = [
    { value: '...', label: '...' },
    ...Array(10)
      .fill('-')
      .map((_, idx) => ({
        label: `${idx + 1}`,
        value: `${idx + 1}`,
      })),
  ]

  return (
    <div
      className={classNames(
        'array-question-item d-flex align-items-center justify-content-center py-1',
        {
          'bg-grey': showBackgroundUnderSubQuestion,
        }
      )}
      style={{
        minHeight: (subQuestionsHeight[subQuestionindex] || 0) + 10,
        width: `${arrayAnswersWidth[index] + dragIconSize * 2}px`,
      }}
    >
      <span
        onClick={(e) => e.stopPropagation()}
        style={{
          width: `${arrayAnswersWidth[index] + dragIconSize}px`,
        }}
      >
        <Select options={options} />
      </span>
    </div>
  )
}
