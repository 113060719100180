import {
  Input,
  NumericInput,
  Select,
  ToggleButtons,
} from 'components/UIComponents'

export const TIMER_ATTRIBUTES = {
  TIME_LIMIT_TIMER_CSS_STYLE: {
    component: Input,
    attributePath: 'attributes.time_limit_timer_style',
    props: {
      labelText: 'Time limit timer CSS style',
      dataTestId: 'minimum-value',
    },
  },
  TIME_LIMIT_EXPIRY_MESSAGE_DISPLAY_TIME: {
    component: NumericInput,
    attributePath: 'attributes.time_limit_message_delay',
    props: {
      labelText: 'Expiry message display time',
      dataTestId: 'time-limit-expiry-message-display-time',
      rightInputText: 'sec',
    },
  },
  TIME_LIMIT_EXPIRY_MESSAGE: {
    component: Input,
    attributePath: 'attributes.time_limit_message',
    props: {
      labelText: 'Expiry message',
      as: 'textarea',
      type: 'textarea',
      role: 'textarea',
      rows: '4',
    },
  },
  TIME_LIMIT_MESSAGE_CSS_STYLE: {
    component: Input,
    attributePath: 'attributes.time_limit_message_style',
    props: {
      labelText: 'Time limit message CSS style',
    },
  },
  FIRST_TIME_LIMIT_WARNING: {
    component: ToggleButtons,
    attributePath: 'attributes.time_limit_warning',
    props: {
      labelText: '1st time limit warning',
      id: 'time-limit-disable-next',
      toggleOptions: [
        { name: 'Yes', value: '1' },
        { name: 'No', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  FIRST_TIME_LIMIT_WARNING_TIMER: {
    component: NumericInput,
    attributePath: 'attributes.time_limit_warning_display_time',
    props: {
      labelText: '1st time limit timer',
      dataTestId: 'first-time-limit-warning-timer',
      rightInputText: 'sec',
    },
  },
  FIRST_TIME_LIMIT_WARNING_MESSAGE: {
    component: Input,
    attributePath: 'attributes.time_limit_warning_message',
    props: {
      labelText: '1st time limit message',
      as: 'textarea',
      type: 'textarea',
      role: 'textarea',
      rows: '4',
    },
  },
  FIRST_TIME_LIMIT_TIMER_CSS_STYLE: {
    component: Input,
    attributePath: 'attributes.time_limit_warning_style',
    props: {
      labelText: '1st time limit message CSS style',
    },
  },
  SECOND_TIME_LIMIT_WARNING: {
    component: ToggleButtons,
    attributePath: 'attributes.time_limit_warning_2',
    props: {
      labelText: '2nd time limit warning',
      dataTestId: 'second-time-limit-warning',
      id: 'time-limit-disable-next-2',
      toggleOptions: [
        { name: 'Yes', value: '1' },
        { name: 'No', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  TIME_LIMIT: {
    component: NumericInput,
    attributePath: 'attributes.time_limit',
    props: {
      labelText: 'Time limit',
      dataTestId: 'time-limit',
      rightInputText: 'sec',
    },
  },
  TIME_LIMIT_ACTION: {
    component: Select,
    attributePath: 'attributes.time_limit_action',
    props: {
      labelText: 'Time limit action',
      options: [
        {
          label: 'Warn and move on',
          value: '1',
        },
        {
          label: 'Move on without warning',
          value: '2',
        },
        {
          label: 'Disable only',
          value: '3',
        },
      ],
    },
  },
  TIME_LIMIT_DISABLE_NEXT: {
    component: ToggleButtons,
    attributePath: 'attributes.time_limit_disable_next',
    props: {
      labelText: 'Time limit disable next',
      id: 'time-limit-disable-next',
      toggleOptions: [
        { name: 'On', value: '1' },
        { name: 'Off', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  TIME_LIMIT_DISABLE_PREV: {
    component: ToggleButtons,
    attributePath: 'attributes.time_limit_disable_prev',
    props: {
      labelText: 'Time limit disable prev',
      id: 'time-limit-disable-prev',
      toggleOptions: [
        { name: 'On', value: '1' },
        { name: 'Off', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  TIME_LIMIT_COUNTDOWN_MESSAGE: {
    component: Input,
    attributePath: 'attributes.time_limit_countdown_message',
    props: {
      labelText: 'Time limit countdown message',
      as: 'textarea',
      type: 'textarea',
      role: 'textarea',
      rows: '4',
    },
  },
}
