import React, { useMemo } from 'react'

import { htmlPopup } from 'helpers'
import { useAuth } from 'hooks'

const feebackSurveyId = 516925

export const useFeedbackForm = () => {
  const { userId } = useAuth()

  const urlParams = useMemo(() => {
    return new URLSearchParams({
      domain: window.location.hostname,
      userId,
    })
  }, [userId])

  const showFeedbackForm = () => {
    const modalCompatibleDomains = ['.limequery.', '.limesurvey.']
    let modalCompatible = false
    modalCompatibleDomains.forEach((domain) => {
      if (window.location.hostname.includes(domain)) {
        modalCompatible = true
      }
    })

    if (modalCompatible) {
      showFeedbackModal()
    } else {
      window.open(
        'https://survey.limesurvey.org/' +
          feebackSurveyId +
          '?' +
          urlParams.toString(),
        '_blank'
      )
    }
  }

  const showFeedbackModal = () => {
    htmlPopup({
      html: feedBackHTML,
      showCloseButton: true,
      closeButtonClass: 'feedback-close-button',
    })
  }

  const feedBackHTML = useMemo(() => {
    // Each cloud domain has as sur subdomain which points to survey.limesurvey.org
    // - this allows us to avoid cross site cookie issues, so the session and CSRF
    // - cookies inside the frame are always on the same domain as the calling site
    // The survey will not work on custom domains which do not have this subdomain
    const feebackSubdomain = 'sur'
    //eslint-disable-next-line
    const domainReplaceRegex = /^[^\/]*\.([^\/]+\.[^\/]+)/
    const feebackDomain = window.location.hostname.replace(
      domainReplaceRegex,
      feebackSubdomain + '.$1'
    )
    const feebackUrl =
      'https://' +
      feebackDomain +
      '/' +
      feebackSurveyId +
      '?' +
      urlParams.toString()

    return (
      <div className="feedback-container">
        <div className="feedback-title mt-5 mb-3">
          Thanks for helping us improve!
        </div>
        <div className="feedback-description mb-4">
          Your feedback is important to us. Please take a minute to answer two
          quick questions.
        </div>
        <div className="feedback-cover" />
        <iframe
          className="survey"
          src={feebackUrl}
          title="Feedback Survey"
        ></iframe>
      </div>
    )
  }, [urlParams])

  return {
    showFeedbackForm,
  }
}
