import { useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap'

import { dayJs } from 'helpers'
import { useAppState, useFeedbackForm } from 'hooks'

export const FeedbackPopup = () => {
  const delayMins = process.env.REACT_APP_FEEDBACK_POPUP_DELAY_MINS
  const intervalMinutes = process.env.REACT_APP_FEEDBACK_POPUP_INTERVAL_MINS
  const { showFeedbackForm } = useFeedbackForm()
  const [show, setShow] = useState(false)
  const [data, setData] = useAppState('feedback_popup', {
    initialLoad: new Date(),
    previouslyShown: null,
    dontAskAgain: false,
    feedbackGiven: false,
  })

  const updateData = (values) => {
    setData((prevState) => ({ ...prevState, ...values }))
  }

  if (!data || !data.initialLoad) {
    updateData({
      initialLoad: dayJs().toISOString(),
    })
  }

  if (
    data &&
    !data.feedbackGiven &&
    !data.dontAskAgain &&
    data.initialLoad &&
    dayJs(data.initialLoad) < dayJs().subtract(delayMins, 'minute') &&
    (!data.previouslyShown ||
      dayJs(data.previouslyShown) < dayJs().subtract(intervalMinutes, 'minute'))
  ) {
    setShow(true)
    updateData({
      previouslyShown: dayJs().toISOString(),
    })
  }

  return (
    <Card
      style={{
        position: 'fixed',
        bottom: 60,
        right: 10,
        zIndex: 101,
        width: 600,
        display: show ? 'block' : 'none',
      }}
    >
      <Card.Body>
        <Card.Title as="h2">What do you think so far?</Card.Title>
        <div className="mb-4">
          We would very much appreciate it if you could answer two quick
          questions about your experience with the new editor?
        </div>
        <div className="mb-4">
          <div className="row row-cols-2">
            <div className="col">
              <Button
                variant="light"
                className="not-now w-100"
                style={{ backgroundColor: 'white' }}
                onClick={() => setShow(false)}
              >
                Not now
              </Button>
            </div>
            <div className="col">
              <Button
                variant="primary"
                className="w-100"
                onClick={() => {
                  showFeedbackForm()
                  updateData({ feedbackGiven: true })
                  setShow(false)
                }}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
        <div className="mb-2 d-flex justify-content-end">
          <Form.Check
            inline
            label="Don't ask again"
            type="checkbox"
            onClick={() => updateData({ dontAskAgain: true })}
          />
        </div>
      </Card.Body>
    </Card>
  )
}
