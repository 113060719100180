import { RichTextPlugin as LexicalRichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'

export const RichTextPlugin = ({ placeholder, testId = '' }) => {
  const [editor] = useLexicalComposerContext()

  return (
    <LexicalRichTextPlugin
      placeholder={
        <span
          className="lexical-rich-text-plugin-placeholder"
          onClick={() => editor.focus()}
        >
          {placeholder}
        </span>
      }
      contentEditable={
        <ContentEditable
          data-testid={testId}
          data-placeholder={placeholder}
          className="content-area"
        />
      }
    />
  )
}
