export const getSubquestionById = (id, survey) => {
  // Iterate through each question group
  for (let i = 0; i < survey.questionGroups.length; i++) {
    const group = survey.questionGroups[i]
    // Iterate through each question in the group
    for (let j = 0; j < group.questions.length; j++) {
      const question = group.questions[j]
      // Check if subquestions exist and iterate through them if they do
      if (question.subquestions && question.subquestions.length) {
        for (let k = 0; k < question.subquestions.length; k++) {
          const subquestion = question.subquestions[k]
          // Compare the subquestion ID
          if (subquestion.qid === id) {
            // Return the subquestion along with its location
            return {
              subquestion,
              groupIndex: i,
              questionIndex: j,
              subquestionIndex: k,
            }
          }
        }
      }
    }
  }

  // Return empty object if no subquestion is found
  return {}
}
