import { URLS } from 'helpers'

import { Button, TooltipContainer } from 'components'
import { ArrowBackIcon } from 'components/icons'

export const ButtonBackToClassicEditor = ({ surveyId }) => {
  const classicEditorUrl = URLS.SURVEY_OVERVIEW + surveyId
  return (
    <TooltipContainer
      placement="bottom"
      showTip={true}
      tip="Go back to classic editor"
    >
      <Button
        href={classicEditorUrl}
        variant="light"
        className="align-items-center ms-2"
      >
        <ArrowBackIcon className="me-1" />
        Back to classic editor
      </Button>
    </TooltipContainer>
  )
}
