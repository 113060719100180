import React from 'react'
export const ArrowRightIcon = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7518 6.12297L8.50183 2.87296C8.40595 2.77871 8.27378 2.72021 8.12808 2.72021C7.83341 2.72021 7.59508 2.95909 7.59508 3.25322C7.59508 3.39892 7.65358 3.53109 7.74837 3.62697L10.0727 5.95126H1.62537C1.32258 5.95126 1.07666 6.19663 1.07666 6.49997C1.07666 6.8033 1.32204 7.04867 1.62537 7.04867H10.0727L7.74837 9.37297C7.64979 9.46992 7.58858 9.60426 7.58858 9.75322C7.58858 10.0479 7.82745 10.2862 8.12158 10.2862C8.27053 10.2862 8.40487 10.225 8.50183 10.127L11.7518 6.87697C11.8482 6.78055 11.9078 6.6473 11.9078 6.49997C11.9078 6.35263 11.8482 6.21938 11.7518 6.12297Z"
        fill="#8146F6"
      />
    </svg>
  )
}
