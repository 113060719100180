import axios from 'axios'

export class AuthService {
  constructor(baseUrl) {
    this.baseurl = baseUrl
  }
  login = async (username, password) => {
    const res = await axios.post(`${this.baseurl}/auth`, {
      username,
      password,
    })
    return res.data
  }
  refresh = async (headers) => {
    const res = await axios.put(
      `${this.baseurl}/auth`,
      {},
      {
        headers,
      }
    )
    return res.data
  }
  logout = async (headers) => {
    const res = await axios.delete(`${this.baseurl}/auth`, {
      headers,
    })
    return res.data
  }
}
