import { ToggleButtons, Input } from 'components/UIComponents'

import { SurveySettingComponent } from '../SurveySettingComponent'
import { AdminNotificationsEmail } from '../NotificationsData/AdminNotificationsEmail'
import { GoogleAnalytics } from '../NotificationsData/GoogleAnalytics'

export const surveyNotificationsDataAttributes = [
  {
    component: SurveySettingComponent,
    keyPath: 'datestamp',
    props: {
      id: 'datestamp',
      mainText: 'Date stamp',
      childComponent: ToggleButtons,
      toggleOptions: [
        { name: 'On', value: true },
        { name: 'Off', value: false },
      ],
      activeDisabled: true,
      noPermissionDisabled: true,
    },
  },
  {
    component: SurveySettingComponent,
    keyPath: 'ipAddr',
    props: {
      id: 'ipAddr',
      mainText: 'Save IP address',
      childComponent: ToggleButtons,
      toggleOptions: [
        { name: 'On', value: true },
        { name: 'Off', value: false },
      ],
      activeDisabled: true,
      noPermissionDisabled: true,
    },
  },
  {
    component: SurveySettingComponent,
    keyPath: 'ipAnonymize',
    props: {
      id: 'ipAnonymize',
      mainText: 'Anonymize IP address',
      childComponent: ToggleButtons,
      toggleOptions: [
        { name: 'On', value: true },
        { name: 'Off', value: false },
      ],
      activeDisabled: true,
      noPermissionDisabled: true,
    },
  },
  {
    component: SurveySettingComponent,
    keyPath: 'refUrl',
    props: {
      id: 'refUrl',
      mainText: 'Save referrer URL',
      childComponent: ToggleButtons,
      toggleOptions: [
        { name: 'On', value: true },
        { name: 'Off', value: false },
      ],
      activeDisabled: true,
      noPermissionDisabled: true,
    },
  },
  {
    component: SurveySettingComponent,
    keyPath: 'saveTimings',
    props: {
      id: 'saveTimings',
      mainText: 'Save timings',
      childComponent: ToggleButtons,
      toggleOptions: [
        { name: 'On', value: true },
        { name: 'Off', value: false },
      ],
      activeDisabled: true,
      noPermissionDisabled: true,
    },
  },
  {
    component: SurveySettingComponent,
    keyPath: 'assessments',
    props: {
      id: 'assessments',
      mainText: 'Enable assessment mode',
      childComponent: ToggleButtons,
      toggleOptions: [
        { name: 'On', value: true },
        { name: 'Off', value: false },
      ],
      activeDisabled: true,
      noPermissionDisabled: true,
    },
  },
  {
    component: SurveySettingComponent,
    keyPath: 'allowSave',
    props: {
      id: 'allowSave',
      mainText: 'Participant may save and resume later',
      childComponent: ToggleButtons,
      toggleOptions: [
        { name: 'On', value: true },
        { name: 'Off', value: false },
      ],
      activeDisabled: true,
      noPermissionDisabled: true,
    },
  },
  {
    component: AdminNotificationsEmail,
    keyPath: 'emailNotificationTo',
    props: {
      id: 'emailNotificationTo',
      mainText: 'Send basic admin notifications',
      ignore: true,
      activeDisabled: true,
      noPermissionDisabled: true,
      childComponent: ToggleButtons,
      toggleOptions: [
        { name: 'Yes', value: true },
        { name: 'No', value: false },
      ],
      inputProps: {
        childComponent: Input,
        childOnNewLine: true,
        mainText: 'Basic admin notifications email address',
      },
    },
  },
  {
    component: AdminNotificationsEmail,
    keyPath: 'emailResponseTo',
    props: {
      id: 'emailResponseTo',
      mainText: 'Send detailed admin notifications',
      ignore: true,
      activeDisabled: true,
      noPermissionDisabled: true,
      childComponent: ToggleButtons,
      toggleOptions: [
        { name: 'Yes', value: true },
        { name: 'No', value: false },
      ],
      inputProps: {
        childComponent: Input,
        childOnNewLine: true,
        mainText: 'Detailed admin notifications email address',
      },
    },
  },
  {
    component: GoogleAnalytics,
    keyPath: 'googleAnalyticsApiKeySetting',
    ignore: true,
    props: {
      apiSetting: {
        id: 'googleAnalyticsApiKeySetting',
        mainText: 'Google Analytics settings',
        childComponent: ToggleButtons,
        toggleOptions: [
          { name: 'None', value: 'N' },
          { name: 'Survey settings', value: 'Y' },
          { name: 'Global settings', value: 'G' },
        ],
        defaultValue: 'N',
        activeDisabled: true,
        noPermissionDisabled: true,
      },
      apiKey: {
        id: 'googleAnalyticsApiKey',
        keyPath: 'googleAnalyticsApiKey',
        mainText: 'Google Analytics Tracking ID',
        childComponent: Input,
        childOnNewLine: true,
        activeDisabled: true,
        noPermissionDisabled: true,
      },
      apiStyle: {
        id: 'googleAnalyticsStyle',
        keyPath: 'googleAnalyticsStyle',
        mainText: 'Google Analytics style',
        childComponent: ToggleButtons,
        toggleOptions: [
          { name: 'Off', value: 0 },
          { name: 'Default', value: 1 },
          { name: 'Survey-SID/Group', value: 2 },
        ],
        activeDisabled: true,
        noPermissionDisabled: true,
      },
    },
  },
]
