import React from 'react'

import { QuestionTypeSelector } from 'components/QuestionTypeSelector'
import { QuestionTypeInfo } from 'components/QuestionTypes'
import {
  RandomNumber,
  SCALE_1,
  SCALE_2,
  STATES,
  NEW_OBJECT_ID_PREFIX,
  createBufferOperation,
} from 'helpers'
import { useAppState, useBuffer, useFocused, useSurvey } from 'hooks'

export const TopBarQuestionInserter = ({ surveyID }) => {
  const [, setIsAddingQuestionOrGroup] = useAppState(
    STATES.IS_ADDING_QUESTION_OR_GROUP,
    false
  )
  const [clickedQuestionGroupIndex, setClickedQuestionGroupIndex] = useAppState(
    STATES.CLICKED_QUESTION_GROUP_INDEX,
    null
  )
  const { survey, update, language } = useSurvey(surveyID)
  const { addToBuffer } = useBuffer()
  const { setFocused, groupIndex } = useFocused()
  const questionGroupToAddQuestion = clickedQuestionGroupIndex
    ? survey?.questionGroups[clickedQuestionGroupIndex]
    : groupIndex
      ? survey?.questionGroups[groupIndex]
      : survey?.questionGroups[0]

  const handleAddQuestionGroup = (newQuestionGroup, index) => {
    if (!survey.questionGroups) {
      survey.questionGroups = []
    }

    const newQuestionGroupIndex = index
      ? index + 1
      : survey.questionGroups.length

    const updatedQuestionGroups = [
      ...survey.questionGroups.slice(0, newQuestionGroupIndex),
      newQuestionGroup,
      ...survey.questionGroups.slice(newQuestionGroupIndex),
    ].map((questionGroup, index) => {
      questionGroup.groupOrder = index + 1
      return questionGroup
    })

    survey.questionGroups = updatedQuestionGroups

    const operation = createBufferOperation(newQuestionGroup.gid)
      .questionGroup()
      .create({
        questionGroup: {
          ...newQuestionGroup,
          groupOrder: 1,
          gRelevance: '',
          sid: surveyID,
          sortOrder: 1,
          tempId: newQuestionGroup.gid,
        },
        questionGroupL10n: newQuestionGroup.l10ns,
      })

    update({ ...survey })
    addToBuffer(operation)
    setFocused(newQuestionGroup, newQuestionGroupIndex)
  }

  const addQuestionGroup = () => {
    const groupId = RandomNumber()
    const newQuestionGroup = {
      gid: NEW_OBJECT_ID_PREFIX + groupId,
      sid: surveyID,
      type: QuestionTypeInfo.QUESTION_GROUP.type,
      theme: QuestionTypeInfo.QUESTION_GROUP.theme,
      newObject: true,
      l10ns: {
        [language]: {
          groupName: '',
          description: '',
        },
      },
      questions: [],
    }

    handleAddQuestionGroup(newQuestionGroup)
    setIsAddingQuestionOrGroup(false)
  }

  const handleAddQuestion = (question) => {
    const _groupIndex = clickedQuestionGroupIndex
      ? clickedQuestionGroupIndex
      : groupIndex
        ? groupIndex
        : 0

    const updatedQuestionGroups = [...survey.questionGroups]
    updatedQuestionGroups[_groupIndex] = {
      ...updatedQuestionGroups[_groupIndex],
      questions: [...updatedQuestionGroups[_groupIndex].questions, question],
    }

    survey.questionGroups = updatedQuestionGroups
    update({ ...survey })

    setFocused(
      question,
      _groupIndex,
      updatedQuestionGroups[_groupIndex].questions.length - 1
    )

    setClickedQuestionGroupIndex(null)

    const operation = createBufferOperation(question.qid)
      .question()
      .create({
        question: { ...question, tempId: question.qid },
        questionL10n: { ...question.l10ns },
        attributes: { ...question.attributes },
        answers: { ...question.answers },
        subquestions: { ...question.subquestions },
      })
    addToBuffer(operation)
  }

  const addNewQuestion = (questionTypeInfo) => {
    if (
      (!questionGroupToAddQuestion &&
        questionTypeInfo.type !== QuestionTypeInfo.QUESTION_GROUP.type) ||
      !questionTypeInfo.type
    ) {
      return
    }

    if (questionTypeInfo.type === QuestionTypeInfo.QUESTION_GROUP.type) {
      addQuestionGroup()
      return
    }

    const questionId = NEW_OBJECT_ID_PREFIX + RandomNumber()

    const newQuestion = {
      title: `Q${RandomNumber(1, 9999)}`,
      qid: questionId,
      tempId: questionId,
      newObject: true,
      gid: questionGroupToAddQuestion.gid,
      sid: questionGroupToAddQuestion.sid,
      type: questionTypeInfo.type,
      questionThemeName: questionTypeInfo.questionThemeName,
      l10ns: {
        [language]: {
          question: '',
          language: language,
        },
      },
      answers: [],
      attributes: [],
      subquestions: [],
    }

    if (
      newQuestion.type === QuestionTypeInfo.LIST_RADIO.type ||
      newQuestion.type === QuestionTypeInfo.LIST_RADIO_WITH_COMMENT.type
    ) {
      const answer1Id = RandomNumber()
      const answer2Id = RandomNumber()

      newQuestion.answers = [
        {
          tempId: answer1Id,
          aid: answer1Id,
          qid: questionId,
          code: `A${RandomNumber(999, 9999)}`,
          assessmentValue: 0,
          sortOrder: 1,
          l10ns: {
            [language]: {
              answer: 'Answer option 1',
              language: language,
            },
          },
        },
        {
          tempId: answer2Id,
          aid: answer2Id,
          qid: questionId,
          code: `A${RandomNumber(999, 9999)}`,
          assessmentValue: 0,
          sortOrder: 2,
          l10ns: {
            [language]: {
              answer: 'Answer option 2',
              language: language,
            },
          },
        },
      ]
    } else if (
      [
        QuestionTypeInfo.ARRAY.theme,
        QuestionTypeInfo.ARRAY_NUMBERS.theme,
        QuestionTypeInfo.ARRAY_TEXT.theme,
        QuestionTypeInfo.ARRAY_COLUMN.theme,
      ].includes(newQuestion.questionThemeName)
    ) {
      if (
        [QuestionTypeInfo.ARRAY.theme].includes(newQuestion.questionThemeName)
      ) {
        const answerId = RandomNumber()
        const answerId2 = RandomNumber()
        newQuestion.answers = [
          {
            aid: `${NEW_OBJECT_ID_PREFIX}${answerId}`,
            tempId: `${NEW_OBJECT_ID_PREFIX}${answerId}`,
            qid: questionId,
            code: `A${RandomNumber(999, 9999)}`,
            sortOrder: 1,
            assessmentValue: 0,
            scaleId: SCALE_1,
            l10ns: {
              [language]: {
                answer: 'Answer option 1',
                language: language,
              },
            },
          },
          {
            aid: `${NEW_OBJECT_ID_PREFIX}${answerId2}`,
            tempId: `${NEW_OBJECT_ID_PREFIX}${answerId2}`,
            qid: questionId,
            code: `A${RandomNumber(999, 9999)}`,
            sortOrder: 2,
            assessmentValue: 0,
            scaleId: SCALE_1,
            l10ns: {
              [language]: {
                answer: 'Answer option 2',
                language: language,
              },
            },
          },
        ]
      } else if (
        [
          QuestionTypeInfo.ARRAY_NUMBERS.theme,
          QuestionTypeInfo.ARRAY_TEXT.theme,
        ].includes(newQuestion.questionThemeName)
      ) {
        let subQuestionId1 = RandomNumber()
        let subQuestionId2 = RandomNumber()

        newQuestion.subquestions = [
          {
            sortOrder: 1,
            parentQid: questionId,
            qid: `${NEW_OBJECT_ID_PREFIX}${subQuestionId1}`,
            tempId: `${NEW_OBJECT_ID_PREFIX}${subQuestionId1}`,
            title: `SQ${RandomNumber(1, 999)}`,
            l10ns: {
              [language]: {
                question: 'Subquestion 1',
                help: '',
                language: language,
              },
            },
            gid: newQuestion.gid,
            sid: newQuestion.sid,
            type: 'T',
            scaleId: SCALE_2,
          },
          {
            sortOrder: 2,
            parentQid: questionId,
            qid: `${NEW_OBJECT_ID_PREFIX}${subQuestionId2}`,
            tempId: `${NEW_OBJECT_ID_PREFIX}${subQuestionId2}`,
            title: `SQ${RandomNumber(1, 999)}`,
            l10ns: {
              [language]: {
                question: 'Subquestion 2',
                help: '',
                language: language,
              },
            },
            gid: newQuestion.gid,
            sid: newQuestion.sid,
            type: 'T',
            scaleId: SCALE_2,
          },
        ]
      }

      let questionText1 = 'Subquestion A'
      let questionText2 = 'Subquestion B'
      if (
        [QuestionTypeInfo.ARRAY_COLUMN.theme].includes(
          newQuestion.questionThemeName
        )
      ) {
        const answerId = RandomNumber()
        const answerId2 = RandomNumber()
        newQuestion.answers = [
          {
            aid: `${NEW_OBJECT_ID_PREFIX}${answerId}`,
            tempId: `${NEW_OBJECT_ID_PREFIX}${answerId}`,
            qid: questionId,
            code: `A${RandomNumber(999, 9999)}`,
            sortOrder: 1,
            assessmentValue: 0,
            scaleId: SCALE_1,
            l10ns: {
              [language]: {
                answer: 'Answer option 1',
                language: language,
              },
            },
          },
          {
            aid: `${NEW_OBJECT_ID_PREFIX}${answerId2}`,
            tempId: `${NEW_OBJECT_ID_PREFIX}${answerId2}`,
            qid: questionId,
            code: `A${RandomNumber(999, 9999)}`,
            sortOrder: 2,
            assessmentValue: 0,
            scaleId: SCALE_1,
            l10ns: {
              [language]: {
                answer: 'Answer option 2',
                language: language,
              },
            },
          },
        ]
        questionText1 = 'Subquestion 1'
        questionText2 = 'Subquestion 2'
      }

      let subQuestionId1 = RandomNumber()
      let subQuestionId2 = RandomNumber()

      newQuestion.subquestions = [
        ...(newQuestion.subquestions || []),
        {
          sortOrder: 1,
          parentQid: questionId,
          qid: `${NEW_OBJECT_ID_PREFIX}${subQuestionId1}`,
          tempId: `${NEW_OBJECT_ID_PREFIX}${subQuestionId1}`,
          title: `SQ${RandomNumber(1, 999)}`,
          l10ns: {
            [language]: {
              question: questionText1,
              help: '',
              language: language,
            },
          },
          gid: newQuestion.gid,
          sid: newQuestion.sid,
          type: 'T',
          scaleId: SCALE_1,
        },
        {
          sortOrder: 2,
          parentQid: questionId,
          qid: `${NEW_OBJECT_ID_PREFIX}${subQuestionId2}`,
          tempId: `${NEW_OBJECT_ID_PREFIX}${subQuestionId2}`,
          title: `SQ${RandomNumber(1, 999)}`,
          l10ns: {
            [language]: {
              question: questionText2,
              help: '',
              language: language,
            },
          },
          gid: newQuestion.gid,
          sid: newQuestion.sid,
          type: 'T',
          scaleId: SCALE_1,
        },
      ]
    } else if (
      QuestionTypeInfo.ARRAY_DUAL_SCALE.theme === newQuestion.questionThemeName
    ) {
      const firstAnswerId = RandomNumber()
      const secondAnswerId = RandomNumber()
      const thirdAnswerId = RandomNumber()
      const fourthAnswerId = RandomNumber()

      newQuestion.answers = [
        {
          aid: firstAnswerId,
          tempId: firstAnswerId,
          qid: questionId,
          code: `A${RandomNumber(999, 9999)}`,
          scaleId: SCALE_1,
          sortOrder: 1,
          assessmentValue: 0,
          l10ns: {
            [language]: {
              answer: 'Answer option 1',
              language: language,
            },
          },
        },
        {
          aid: secondAnswerId,
          tempId: secondAnswerId,
          qid: questionId,
          code: `A${RandomNumber(999, 9999)}`,
          scaleId: SCALE_1,
          sortOrder: 2,
          assessmentValue: 0,
          l10ns: {
            [language]: {
              answer: 'Answer option 2',
              language: language,
            },
          },
        },
        {
          aid: thirdAnswerId,
          qid: questionId,
          code: `A${RandomNumber(999, 9999)}`,
          scaleId: SCALE_2,
          sortOrder: 3,
          assessmentValue: 0,
          l10ns: {
            [language]: {
              answer: 'Answer option 3',
              language: language,
            },
          },
        },
        {
          aid: fourthAnswerId,
          tempId: fourthAnswerId,
          qid: questionId,
          code: `A${RandomNumber(999, 9999)}`,
          scaleId: SCALE_2,
          sortOrder: 4,
          assessmentValue: 0,
          l10ns: {
            [language]: {
              answer: 'Answer option 4',
              language: language,
            },
          },
        },
      ]

      let subQuestionId1 = RandomNumber()
      let subQuestionId2 = RandomNumber()

      newQuestion.subquestions = [
        {
          sortOrder: 1,
          parentQid: questionId,
          qid: subQuestionId1,
          tempId: subQuestionId1,
          title: `SQ${RandomNumber(1, 999)}`,
          l10ns: {
            [language]: {
              question: '',
              help: '',
              language: language,
            },
          },
          gid: newQuestion.gid,
          sid: newQuestion.sid,
          type: 'T',
          scaleId: SCALE_1,
        },
        {
          sortOrder: 2,
          parentQid: questionId,
          qid: subQuestionId2,
          tempId: subQuestionId2,
          title: `SQ${RandomNumber(1, 999)}`,
          l10ns: {
            [language]: {
              question: '',
              language: language,
            },
          },
          gid: newQuestion.gid,
          sid: newQuestion.sid,
          type: 'T',
          scaleId: SCALE_1,
        },
      ]
    } else if (QuestionTypeInfo.RANKING_ADVANCED.type === newQuestion.type) {
      newQuestion.firstAnswers = []
      newQuestion.secondAnswers = []
    }

    handleAddQuestion(newQuestion)
    setIsAddingQuestionOrGroup(false)
  }

  return (
    <div
      data-testid="topbar-question-inserter"
      className="mt-4 position-fixed question-type-selector-container"
    >
      <QuestionTypeSelector
        disableAddingQuestions={!questionGroupToAddQuestion}
        callBack={addNewQuestion}
      />
    </div>
  )
}
