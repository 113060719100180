import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export const TooltipContainer = ({
  children,
  offset = [0, 10],
  tip,
  placement = 'top',
  showTip = true,
  className = '',
}) => {
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 100, hide: 100 }}
      offset={offset}
      overlay={tip && showTip ? <Tooltip>{tip}</Tooltip> : <> </>}
    >
      <span className={className}>{children}</span>
    </OverlayTrigger>
  )
}
