import React from 'react'
export const PushPinIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M12.606 1.68604L21.092 10.172L19.677 11.586L18.97 10.879L14.728 15.121L14.021 18.657L12.606 20.071L8.364 15.828L3.414 20.778L2 19.364L6.95 14.414L2.707 10.172L4.121 8.75704L7.658 8.05004L11.9 3.80804L11.193 3.10104L12.607 1.68604H12.606ZM13.314 5.22204L8.643 9.89204L5.821 10.457L12.321 16.957L12.885 14.135L17.556 9.46504L13.314 5.22204Z"
        fill="black"
      />
    </svg>
  )
}
