import { useQuery } from '@tanstack/react-query'
import { cloneDeep } from 'lodash'

import { queryClient } from 'query'

// This hook uses react-query to cache current focused question.
// It shouldn't be used for any other use cases.
export const useFocused = (focused, groupIndex, questionIndex) => {
  const { data } = useQuery({
    queryKey: ['focusedQuestion'],
    queryFn: () => {
      return {
        focused,
        groupIndex,
        questionIndex,
      }
    },
  })

  const setFocused = (
    focusElement,
    groupIndex,
    questionIndex,
    unfocus = true
  ) => {
    if (unfocus) {
      unFocus()
    }

    const focused = cloneDeep(focusElement)
    return queryClient.setQueryData(['focusedQuestion'], {
      focused: focused,
      groupIndex,
      questionIndex,
    })
  }

  const unFocus = () => {
    return queryClient.setQueryData(['focusedQuestion'], {})
  }

  return {
    setFocused,
    unFocus,
    ...data,
  }
}
