import { useEffect, useMemo, useState } from 'react'
import { Collapse, FormCheck } from 'react-bootstrap'
import classNames from 'classnames'

import { useFocused, useBuffer } from 'hooks'
import { ReactComponent as DownArrow } from 'assets/icons/down-arrow.svg'
import { createBufferOperation, L10ns, TOOLTIP_MESSAGES } from 'helpers'
import { Button, ContentEditor, Select } from 'components/UIComponents'
import { LanguageSwitch } from 'components/SurveySettings/GeneralSettings/Attributes/LanguageSwitch'
import { QuestionTypeInfo } from 'components/QuestionTypes'
import { LanguageIcon } from 'components/icons'

import { Section } from './Section'
import { TooltipContainer } from '../TooltipContainer/TooltipContainer'

export const SurveyHeader = ({
  update,
  numberOfQuestions,
  survey: {
    sid,
    languageSettings,
    language,
    welcomeImage,
    showWelcome,
    showXQuestions,
    allowLanguageSwitch,
    showPrivacyPolicy,
    privacyPolicyCheckBox,
    privacyPolicyMessage,
    legalNoticeMessage,
    privacyPolicyLabelMessage,
    showLegalNotice,
    languages = [],
    hasSurveyUpdatePermission,
  },
}) => {
  const [collapse, setCollapse] = useState(false)
  const { setFocused, focused } = useFocused()
  const { addToBuffer } = useBuffer()

  useEffect(() => {
    setCollapse(!showWelcome)
  }, [showWelcome])

  const languageOptionsAsArray = useMemo(
    () =>
      languages.map((language) => {
        return {
          value: language,
          label: language.toUpperCase(),
        }
      }),
    []
  )

  const handleUpdate = (updated) => {
    const updateData = {
      ...languageSettings,
    }

    updateData[language] = {
      ...updateData[language],
      ...updated,
    }

    const operation = createBufferOperation(null)
      .languageSetting()
      .update({
        [language]: updateData[language],
      })

    addToBuffer(operation)
    update({ languageSettings: updateData })
  }

  const handleLanguageChange = (value) => {
    update({ language: value })

    const props = {
      language: value,
    }

    props['additionalLanguages'] = languages

    const operation = createBufferOperation(sid).survey().update(props)
    addToBuffer(operation)
  }

  const handleOnClick = () => {
    if (hasSurveyUpdatePermission) {
      setFocused({ info: QuestionTypeInfo.WELCOME_SCREEN })
    }
  }

  if (!sid) {
    return <></>
  }

  return (
    <TooltipContainer
      tip={TOOLTIP_MESSAGES.NO_PERMISSION}
      showTip={!hasSurveyUpdatePermission}
      placement="left"
    >
      <Section
        onClick={handleOnClick}
        testId="survey-header-section"
        className={classNames('survey-header', {
          'participant-hidden': !showWelcome,
          'cursor-not-allowed': !hasSurveyUpdatePermission,
        })}
      >
        <div
          id="survey-header"
          className="collapse-control d-flex justify-content-between mb-2"
        >
          <div className="d-flex align-items-center gap-3 header">
            <div>
              <span
                className="p-0 cursor-pointer"
                onClick={() => setCollapse(!collapse)}
              >
                <DownArrow
                  className={classNames('transition-all mb-1', {
                    'rotate-180': collapse,
                  })}
                />
              </span>
            </div>
            <div>Welcome screen</div>
          </div>
        </div>
        <Collapse in={!collapse}>
          <div>
            <div
              className={classNames(
                'w-100 d-flex flex-column justify-content-between hover-element survey-header-container',
                {
                  'w-50': welcomeImage && !collapse,
                  'disabled': !showWelcome,
                  'focus-element':
                    focused?.info?.theme ===
                    QuestionTypeInfo.WELCOME_SCREEN.theme,
                }
              )}
            >
              <div
                data-testid="language-change-select"
                className="language-change-header d-flex align-items-center gap-2"
              >
                <LanguageIcon />
                Change Language
                <Select
                  onChange={({ value }) => handleLanguageChange(value)}
                  value={language}
                  options={languageOptionsAsArray}
                />
              </div>
              <div>
                <ContentEditor
                  value={L10ns({
                    prop: 'welcomeText',
                    language,
                    l10ns: languageSettings,
                  })}
                  id="survey-header-welcome-title"
                  className="welcome-title"
                  update={(value) => handleUpdate({ welcomeText: value })}
                  placeholder="Welcome title"
                  language={language}
                  useRichTextEditor={true}
                  noPermissionDisabled={true}
                  showToolTip={false}
                  testId="survey-header-welcome-title"
                />
              </div>
              <ContentEditor
                id="survey-header-welcome-description"
                className="welcome-description"
                value={L10ns({
                  prop: 'description',
                  language,
                  l10ns: languageSettings,
                  disabled: !hasSurveyUpdatePermission,
                })}
                update={(value) => handleUpdate({ description: value })}
                placeholder="Welcome description"
                language={language}
                useRichTextEditor={true}
                noPermissionDisabled={true}
                showToolTip={false}
                testId="survey-header-welcome-description"
              />
              <div className={classNames('ms-1 transition-all')}>
                {showXQuestions && (
                  <p className="text-secondary mt-3 show-x-questions">
                    There are {numberOfQuestions} questions in this survey.
                  </p>
                )}
                {showPrivacyPolicy && (
                  <div className="survey-privacy">
                    <div className="d-flex align-items-center ms-1">
                      <FormCheck
                        checked={privacyPolicyCheckBox}
                        label={privacyPolicyLabelMessage || 'Privacy Policy'}
                        type="checkbox"
                        name="privacy-policy-checkbox"
                        data-testid="privacy-policy-checkbox"
                        onChange={(e) =>
                          update({ privacyPolicyCheckBox: e.target.checked })
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <h6>
                        <ContentEditor
                          value={privacyPolicyMessage}
                          update={(value) =>
                            update({ privacyPolicyMessage: value })
                          }
                          placeholder="Privacy Policy Message"
                        />
                      </h6>
                    </div>
                    {allowLanguageSwitch && (
                      <div className="mt-4 ms-1">
                        <LanguageSwitch
                          handleLanguageChange={(language) =>
                            update({ language })
                          }
                          language={language}
                          label="Survey language"
                        />
                      </div>
                    )}
                  </div>
                )}
                {showLegalNotice && (
                  <div className="d-flex ">
                    <a href="/legalNotice" className="text-success ms-auto">
                      {legalNoticeMessage}
                    </a>
                  </div>
                )}
              </div>

              <div className="start-survey-section mt-4 ms-1 d-flex align-items-center gap-3">
                <Button className="start-button">Start Survey</Button>
                <span className="or-press-enter-text">or press ↩</span>
              </div>
            </div>
          </div>
        </Collapse>
      </Section>
    </TooltipContainer>
  )
}
