import React, { useState } from 'react'
import { Button, OverlayTrigger } from 'react-bootstrap'

import { useAppState } from 'hooks'
import { TooltipContainer } from 'components/TooltipContainer/TooltipContainer'
import { SmallThreeDotIcon } from 'components/icons'
import { STATES, TOOLTIP_MESSAGES } from 'helpers'

export const MeatballMenu = ({
  duplicateText,
  deleteText,
  handleDuplicate,
  handleDelete,
  testId = '',
}) => {
  const [showMeatballMenu, setShowMeatballMenu] = useState(false)
  const [isSurveyActive] = useAppState(STATES.IS_SURVEY_ACTIVE, false)
  const [hasSurveyUpdatePermission] = useAppState(
    STATES.HAS_SURVEY_UPDATE_PERMISSION
  )

  const onDuplicate = () => {
    setShowMeatballMenu(false)
    handleDuplicate()
  }

  const onDelete = () => {
    setShowMeatballMenu(false)
    handleDelete()
  }
  const toolTip = isSurveyActive
    ? TOOLTIP_MESSAGES.ACTIVE_DISABLED
    : TOOLTIP_MESSAGES.NO_PERMISSION

  const meatballMenu = (
    <div
      className="meatball-menu ps-3 bg-white"
      data-testid="meatball-menu-overlay"
    >
      <TooltipContainer
        tip={toolTip}
        showTip={isSurveyActive || !hasSurveyUpdatePermission}
      >
        <Button
          data-testid="duplicate-button"
          disabled={isSurveyActive || !hasSurveyUpdatePermission}
          variant="layout"
          onClick={onDuplicate}
        >
          {duplicateText}
        </Button>
      </TooltipContainer>
      <TooltipContainer
        tip={toolTip}
        showTip={isSurveyActive || !hasSurveyUpdatePermission}
      >
        <Button
          data-testid="delete-button"
          disabled={isSurveyActive || !hasSurveyUpdatePermission}
          className="meat-ball-delete-button"
          variant="layout"
          onClick={onDelete}
        >
          {deleteText}
        </Button>
      </TooltipContainer>
    </div>
  )

  return (
    <div data-testid={testId}>
      <OverlayTrigger
        overlay={meatballMenu}
        trigger="click"
        placement="right"
        show={showMeatballMenu}
        onToggle={(show) => {
          setShowMeatballMenu(show)
        }}
        offset={[6, 22]}
        rootClose
      >
        <Button
          variant="outline"
          className="p-0"
          data-testid="meatball-menu-button"
        >
          <SmallThreeDotIcon />
        </Button>
      </OverlayTrigger>
    </div>
  )
}
