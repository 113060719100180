import { useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'

import { queryClient } from 'query'
import { STATES } from 'helpers'

export const useErrors = () => {
  const { data = {} } = useQuery({
    queryKey: [STATES.ERRORS],
    queryFn: () => [],
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  const setErrorsFromPatchResponse = useCallback((patchResponse = []) => {
    queryClient.setQueryData([STATES.ERRORS], {})

    const errors = organizeErrors(patchResponse)
    queryClient.setQueryData([STATES.ERRORS], { ...errors })
  }, [])

  const setErrors = (errors) => {
    queryClient.setQueryData([STATES.ERRORS], { ...errors })
  }

  const clearErrors = () => {
    setErrorsFromPatchResponse([])
  }

  const getError = (id, entity) => {
    if (data[id] && data[id][entity]) {
      return data[id][entity]
    }

    return null
  }

  const removeError = (id, entity) => {
    const errors = { ...data }

    if (getError(id, entity)) {
      delete errors[id][entity]
    }

    setErrors(errors)
  }

  const organizeErrors = (data) => {
    let errorInfo = {}

    data.forEach((item) => {
      const { id, entity, error } = item
      if (!errorInfo[id]) {
        errorInfo[id] = {}
      }
      errorInfo[id][entity] = error
    })

    return errorInfo
  }

  return {
    errors: data,
    clearErrors,
    getError,
    setErrorsFromPatchResponse,
    removeError,
  }
}
