import { useEffect } from 'react'
import classNames from 'classnames'
import Col from 'react-bootstrap/Col'

import { useAppState, useFocused, useSurvey } from 'hooks'
import { STATES } from 'helpers'

import { SurveyHeader } from './SurveyHeader'
import { SurveyFooter } from './SurveyFooter'
import { SurveyBody } from './SurveyBody'

export const Survey = ({ id }) => {
  const { survey = {}, update, language } = useSurvey(id)
  const { setFocused } = useFocused()

  const [, setIsSurveyActive] = useAppState(STATES.IS_SURVEY_ACTIVE, false)

  const [, setCodeToQuestion] = useAppState(STATES.CODE_TO_QUESTION, {})
  const [, setSaveStatue] = useAppState(STATES.SAVE_STATUS, {})
  const [numberOfQuestions, setNumberOfQuestions] = useAppState(
    STATES.NUMBER_OF_QUESTIONS,
    0
  )

  useEffect(() => {
    let numberOfQuestions = 0

    if (!survey?.questionGroups || !survey?.showXQuestions) {
      return
    }

    for (const questionGroup of survey.questionGroups) {
      numberOfQuestions += questionGroup.questions.length
    }

    setNumberOfQuestions(numberOfQuestions)
  }, [survey.questionGroups])

  useEffect(() => {
    if (!survey.questionGroups || !survey.newSurvey) {
      return
    }

    if (survey?.questionGroups[0]?.questions?.length === 1) {
      setFocused(survey?.questionGroups[0].questions[0], 0, 0)
      update({ newSurvey: false })
    }
  }, [survey.newSurvey])

  useEffect(() => {
    setSaveStatue('')
  }, [survey.sid])

  useEffect(() => {
    setIsSurveyActive(survey?.active)
  }, [survey.sid, survey?.active])

  useEffect(() => {
    if (!survey?.questionGroups) {
      return
    }

    const codeToQuestion = {}
    for (const questionGroup of survey.questionGroups) {
      for (const question of questionGroup.questions) {
        if (question.title) {
          codeToQuestion[question.title] = { question }
        }
      }
    }

    setCodeToQuestion(codeToQuestion)
  }, [setCodeToQuestion, survey.questionGroups, survey.sid])

  return (
    <Col
      className={classNames(
        'd-flex',
        'mx-4',
        'flex-column',
        'justify-content-center',
        'survey'
      )}
    >
      <SurveyHeader
        update={(updated) => update(updated)}
        numberOfQuestions={numberOfQuestions}
        survey={survey}
      />
      <SurveyBody
        language={language}
        update={update}
        questionGroups={survey.questionGroups}
        surveyId={survey.sid}
      />
      <SurveyFooter
        survey={survey}
        update={(languageSettings) => update(languageSettings)}
      />
    </Col>
  )
}
