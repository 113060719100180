import classNames from 'classnames'
import { Input } from 'components/UIComponents'

export const ArraySubQuestionTextAnswers = ({
  subQuestionindex,
  subQuestionsHeight = [],
  arrayAnswersWidth = [],
  dragIconSize = 1,
  index,
  showBackgroundUnderSubQuestion = false,
}) => {
  return (
    <div
      className={classNames(
        'array-question-item d-flex align-items-center py-1',
        {
          'bg-grey': showBackgroundUnderSubQuestion,
        }
      )}
      style={{
        minHeight: (subQuestionsHeight[subQuestionindex] || 50) + 10,
        cursor: 'not-allowed',
      }}
    >
      <Input
        disabled
        style={{
          width: `${arrayAnswersWidth[index] + dragIconSize * 2}px`,
          padding: '0 10px',
        }}
        className="disabled"
      />
    </div>
  )
}
