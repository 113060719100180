export const LANGUAGE_CODES = {
  DE_INFORMAL: 'de-informal',
  EN: 'en',
  ES: 'es',
  OM: 'om',
  AA: 'aa',
  AF: 'af',
  AK: 'ak',
  AN: 'an',
  IG: 'ig',
  GN: 'gn',
  AE: 'ae',
  AY: 'ay',
  AZ: 'az',
  ID: 'id',
  MS: 'ms',
  BM: 'bm',
  JV: 'jv',
  SU: 'su',
  BI: 'bi',
  BS: 'bs',
  BR: 'br',
  CA: 'ca',
  CH: 'ch',
  NY: 'ny',
  SN: 'sn',
  CO: 'co',
  CY: 'cy',
  DA: 'da',
  SE: 'se',
  DE: 'de',
  NV: 'nv',
  ET: 'et',
  NA: 'na',
  EO: 'eo',
  EU: 'eu',
  EE: 'ee',
  TO: 'to',
  MG: 'mg',
  FR: 'fr',
  FY: 'fy',
  FF: 'ff',
  FO: 'fo',
  GA: 'ga',
  GV: 'gv',
  SM: 'sm',
  GL: 'gl',
  SQ: 'sq',
  GD: 'gd',
  KI: 'ki',
  HA: 'ha',
  HO: 'ho',
  HR: 'hr',
  IO: 'io',
  RW: 'rw',
  RN: 'rn',
  IA: 'ia',
  ND: 'nd',
  NR: 'nr',
  XH: 'xh',
  ZU: 'zu',
  IT: 'it',
  IK: 'ik',
  PL: 'pl',
  MH: 'mh',
  KL: 'kl',
  KR: 'kr',
  KW: 'kw',
  KG: 'kg',
  SW: 'sw',
  HT: 'ht',
  KJ: 'kj',
  KU: 'ku',
  LA: 'la',
  LV: 'lv',
  LT: 'lt',
  RO: 'ro',
  LI: 'li',
  LN: 'ln',
  LG: 'lg',
  LB: 'lb',
  HU: 'hu',
  MT: 'mt',
  NL: 'nl',
  NO: 'no',
  NB: 'nb',
  NN: 'nn',
  UZ: 'uz',
  OC: 'oc',
  IE: 'ie',
  HZ: 'hz',
  NG: 'ng',
  PT: 'pt',
  TY: 'ty',
  RM: 'rm',
  QU: 'qu',
  SC: 'sc',
  ZA: 'za',
  ST: 'st',
  TN: 'tn',
  SS: 'ss',
  SL: 'sl',
  SK: 'sk',
  SO: 'so',
  FI: 'fi',
  SV: 'sv',
  MI: 'mi',
  VI: 'vi',
  LU: 'lu',
  VE: 've',
  TW: 'tw',
  TK: 'tk',
  TR: 'tr',
  UG: 'ug',
  VO: 'vo',
  FJ: 'fj',
  WA: 'wa',
  TL: 'tl',
  WO: 'wo',
  TS: 'ts',
  YO: 'yo',
  SG: 'sg',
  IS: 'is',
  CS: 'cs',
  EL: 'el',
  AV: 'av',
  AB: 'ab',
  BA: 'ba',
  BE: 'be',
  BG: 'bg',
  OS: 'os',
  KV: 'kv',
  KY: 'ky',
  MK: 'mk',
  MN: 'mn',
  CE: 'ce',
  RU: 'ru',
  SR: 'sr',
  TT: 'tt',
  TG: 'tg',
  UK: 'uk',
  CV: 'cv',
  CU: 'cu',
  KK: 'kk',
  HY: 'hy',
  YI: 'yi',
  HE: 'he',
  UR: 'ur',
  AR: 'ar',
  FA: 'fa',
  PS: 'ps',
  KS: 'ks',
  NE: 'ne',
  PI: 'pi',
  BH: 'bh',
  MR: 'mr',
  SA: 'sa',
  SD: 'sd',
  HI: 'hi',
  AS: 'as',
  BN: 'bn',
  PA: 'pa',
  GU: 'gu',
  OR: 'or',
  TA: 'ta',
  TE: 'te',
  KN: 'kn',
  ML: 'ml',
  SI: 'si',
  TH: 'th',
  LO: 'lo',
  BO: 'bo',
  DZ: 'dz',
  MY: 'my',
  KA: 'ka',
  TI: 'ti',
  AM: 'am',
  IU: 'iu',
  OJ: 'oj',
  CR: 'cr',
  KM: 'km',
  ZH: 'zh',
  JA: 'ja',
  II: 'ii',
  KO: 'ko',
  CA_VALENCIA: 'ca-valencia',
  CEB: 'ceb',
  ZH_HANS: 'zh-Hans',
  HAT: 'hat',
  ZH_HANT_HK: 'zh-Hant-HK',
  ZH_HANT_TW: 'zh-Hant-TW',
  CS_INFORMAL: 'cs-informal',
  PRS: 'prs',
  NL_INFORMAL: 'nl-informal',
  FIL: 'fil',
  FUL: 'ful',
  DE_EASY: 'de-easy',
  KAL: 'kal',
  HIL: 'hil',
  IKE: 'ike',
  ILO: 'ilo',
  IT_INFORMAL: 'it-informal',
  RUN: 'run',
  CKB: 'ckb',
  KMR: 'kmr',
  CNR: 'cnr',
  MYA: 'mya',
  PAP_CW: 'pap-CW',
  PL_INFORMAL: 'pl-informal',
  PT_BR: 'pt-BR',
  ROH: 'roh',
  SMI: 'smi',
  SR_LATN: 'sr-Latn',
  ES_INFORMAL: 'es-informal',
  ES_AR: 'es-AR',
  ES_AR_INFORMAL: 'es-AR-informal',
  ES_CL: 'es-CL',
  ES_CO: 'es-CO',
  ES_MX: 'es-MX',
  SWH: 'swh',
  XHO: 'xho',
  SAH: 'sah',
  YOR: 'yor',
}

export const LanguageOptions = {
  [LANGUAGE_CODES.EN]: {
    label: 'English',
    value: LANGUAGE_CODES.EN,
  },
  [LANGUAGE_CODES.DE_INFORMAL]: {
    label: 'Deutsch (informell)',
    value: LANGUAGE_CODES.DE_INFORMAL,
  },
  [LANGUAGE_CODES.OM]: {
    label: 'Afaan Oromoo',
    value: LANGUAGE_CODES.OM,
  },
  [LANGUAGE_CODES.AA]: {
    label: 'Afaraf',
    value: LANGUAGE_CODES.AA,
  },
  [LANGUAGE_CODES.AF]: {
    label: 'Afrikaans',
    value: LANGUAGE_CODES.AF,
  },
  [LANGUAGE_CODES.AK]: {
    label: 'Akan',
    value: LANGUAGE_CODES.AK,
  },
  [LANGUAGE_CODES.AN]: {
    label: 'Aragonés',
    value: LANGUAGE_CODES.AN,
  },
  [LANGUAGE_CODES.IG]: {
    label: 'Asụsụ Igbo',
    value: LANGUAGE_CODES.IG,
  },
  [LANGUAGE_CODES.GN]: {
    label: "Avañe'ẽ",
    value: LANGUAGE_CODES.GN,
  },
  [LANGUAGE_CODES.AE]: {
    label: 'Avesta',
    value: LANGUAGE_CODES.AE,
  },
  [LANGUAGE_CODES.AY]: {
    label: 'Aymar aru',
    value: LANGUAGE_CODES.AY,
  },
  [LANGUAGE_CODES.AZ]: {
    label: 'Azərbaycan dili',
    value: LANGUAGE_CODES.AZ,
  },
  [LANGUAGE_CODES.ID]: {
    label: 'Bahasa Indonesia',
    value: LANGUAGE_CODES.ID,
  },
  [LANGUAGE_CODES.MS]: {
    label: 'Bahasa Melayu',
    value: LANGUAGE_CODES.MS,
  },
  [LANGUAGE_CODES.BM]: {
    label: 'Bamanankan',
    value: LANGUAGE_CODES.BM,
  },
  [LANGUAGE_CODES.JV]: {
    label: 'Basa Jawa',
    value: LANGUAGE_CODES.JV,
  },
  [LANGUAGE_CODES.SU]: {
    label: 'Basa Sunda',
    value: LANGUAGE_CODES.SU,
  },
  [LANGUAGE_CODES.BI]: {
    label: 'Bislama',
    value: LANGUAGE_CODES.BI,
  },
  [LANGUAGE_CODES.BS]: {
    label: 'Bosanski jezik',
    value: LANGUAGE_CODES.BS,
  },
  [LANGUAGE_CODES.BR]: {
    label: 'Brezhoneg',
    value: LANGUAGE_CODES.BR,
  },
  [LANGUAGE_CODES.CA]: {
    label: 'Català',
    value: LANGUAGE_CODES.CA,
  },
  [LANGUAGE_CODES.CH]: {
    label: 'Chamoru',
    value: LANGUAGE_CODES.CH,
  },
  [LANGUAGE_CODES.NY]: {
    label: 'ChiCheŵa',
    value: LANGUAGE_CODES.NY,
  },
  [LANGUAGE_CODES.SN]: {
    label: 'ChiShona',
    value: LANGUAGE_CODES.SN,
  },
  [LANGUAGE_CODES.CO]: {
    label: 'Corsu',
    value: LANGUAGE_CODES.CO,
  },
  [LANGUAGE_CODES.CY]: {
    label: 'Cymraeg',
    value: LANGUAGE_CODES.CY,
  },
  [LANGUAGE_CODES.DA]: {
    label: 'Dansk',
    value: LANGUAGE_CODES.DA,
  },
  [LANGUAGE_CODES.SE]: {
    label: 'Davvisámegiella',
    value: LANGUAGE_CODES.SE,
  },
  [LANGUAGE_CODES.DE]: {
    label: 'Deutsch',
    value: LANGUAGE_CODES.DE,
  },
  [LANGUAGE_CODES.NV]: {
    label: 'Diné bizaad',
    value: LANGUAGE_CODES.NV,
  },
  [LANGUAGE_CODES.ET]: {
    label: 'Eesti',
    value: LANGUAGE_CODES.ET,
  },
  [LANGUAGE_CODES.NA]: {
    label: 'Ekakairũ Naoero',
    value: LANGUAGE_CODES.NA,
  },
  [LANGUAGE_CODES.EN]: {
    label: 'English',
    value: LANGUAGE_CODES.EN,
  },
  [LANGUAGE_CODES.ES]: {
    label: 'Español',
    value: LANGUAGE_CODES.ES,
  },
  [LANGUAGE_CODES.EO]: {
    label: 'Esperanto',
    value: LANGUAGE_CODES.EO,
  },
  [LANGUAGE_CODES.EU]: {
    label: 'Euskara',
    value: LANGUAGE_CODES.EU,
  },
  [LANGUAGE_CODES.EE]: {
    label: 'Eʋegbe',
    value: LANGUAGE_CODES.EE,
  },
  [LANGUAGE_CODES.TO]: {
    label: 'Faka Tonga',
    value: LANGUAGE_CODES.TO,
  },
  [LANGUAGE_CODES.MG]: {
    label: 'Fiteny malagasy',
    value: LANGUAGE_CODES.MG,
  },
  [LANGUAGE_CODES.FR]: {
    label: 'Français',
    value: LANGUAGE_CODES.FR,
  },
  [LANGUAGE_CODES.FY]: {
    label: 'Frysk',
    value: LANGUAGE_CODES.FY,
  },
  [LANGUAGE_CODES.FF]: {
    label: 'Fulfulde',
    value: LANGUAGE_CODES.FF,
  },
  [LANGUAGE_CODES.FO]: {
    label: 'Føroyskt',
    value: LANGUAGE_CODES.FO,
  },
  [LANGUAGE_CODES.GA]: {
    label: 'Gaeilge',
    value: LANGUAGE_CODES.GA,
  },
  [LANGUAGE_CODES.GV]: {
    label: 'Gaelg',
    value: LANGUAGE_CODES.GV,
  },
  [LANGUAGE_CODES.SM]: {
    label: "Gagana fa'a Samoa",
    value: LANGUAGE_CODES.SM,
  },
  [LANGUAGE_CODES.GL]: {
    label: 'Galego',
    value: LANGUAGE_CODES.GL,
  },
  [LANGUAGE_CODES.SQ]: {
    label: 'Gjuha shqipe',
    value: LANGUAGE_CODES.SQ,
  },
  [LANGUAGE_CODES.GD]: {
    label: 'Gàidhlig',
    value: LANGUAGE_CODES.GD,
  },
  [LANGUAGE_CODES.KI]: {
    label: 'Gĩkũyũ',
    value: LANGUAGE_CODES.KI,
  },
  [LANGUAGE_CODES.HA]: {
    label: 'Hausa',
    value: LANGUAGE_CODES.HA,
  },
  [LANGUAGE_CODES.HO]: {
    label: 'Hiri Motu',
    value: LANGUAGE_CODES.HO,
  },
  [LANGUAGE_CODES.HR]: {
    label: 'Hrvatski jezik',
    value: LANGUAGE_CODES.HR,
  },
  [LANGUAGE_CODES.IO]: {
    label: 'Ido',
    value: LANGUAGE_CODES.IO,
  },
  [LANGUAGE_CODES.RW]: {
    label: 'Ikinyarwanda',
    value: LANGUAGE_CODES.RW,
  },
  [LANGUAGE_CODES.RN]: {
    label: 'Ikirundi',
    value: LANGUAGE_CODES.RN,
  },
  [LANGUAGE_CODES.IA]: {
    label: 'Interlingua',
    value: LANGUAGE_CODES.IA,
  },
  [LANGUAGE_CODES.ND]: {
    label: 'IsiNdebele',
    value: LANGUAGE_CODES.ND,
  },
  [LANGUAGE_CODES.NR]: {
    label: 'IsiNdebele',
    value: LANGUAGE_CODES.NR,
  },
  [LANGUAGE_CODES.XH]: {
    label: 'IsiXhosa',
    value: LANGUAGE_CODES.XH,
  },
  [LANGUAGE_CODES.ZU]: {
    label: 'IsiZulu',
    value: LANGUAGE_CODES.ZU,
  },
  [LANGUAGE_CODES.IT]: {
    label: 'Italiano',
    value: LANGUAGE_CODES.IT,
  },
  [LANGUAGE_CODES.IK]: {
    label: 'Iñupiaq',
    value: LANGUAGE_CODES.IK,
  },
  [LANGUAGE_CODES.PL]: {
    label: 'Polski',
    value: LANGUAGE_CODES.PL,
  },
  [LANGUAGE_CODES.MH]: {
    label: 'Kajin M̧ajeļ',
    value: LANGUAGE_CODES.MH,
  },
  [LANGUAGE_CODES.KL]: {
    label: 'Kalaallisut',
    value: LANGUAGE_CODES.KL,
  },
  [LANGUAGE_CODES.KR]: {
    label: 'Kanuri',
    value: LANGUAGE_CODES.KR,
  },
  [LANGUAGE_CODES.KW]: {
    label: 'Kernewek',
    value: LANGUAGE_CODES.KW,
  },
  [LANGUAGE_CODES.KG]: {
    label: 'KiKongo',
    value: LANGUAGE_CODES.KG,
  },
  [LANGUAGE_CODES.SW]: {
    label: 'Kiswahili',
    value: LANGUAGE_CODES.SW,
  },
  [LANGUAGE_CODES.HT]: {
    label: 'Kreyòl ayisyen',
    value: LANGUAGE_CODES.HT,
  },
  [LANGUAGE_CODES.KJ]: {
    label: 'Kuanyama',
    value: LANGUAGE_CODES.KJ,
  },
  [LANGUAGE_CODES.KU]: {
    label: 'Kurdî',
    value: LANGUAGE_CODES.KU,
  },
  [LANGUAGE_CODES.LA]: {
    label: 'Latine',
    value: LANGUAGE_CODES.LA,
  },
  [LANGUAGE_CODES.LV]: {
    label: 'Latviešu valoda',
    value: LANGUAGE_CODES.LV,
  },
  [LANGUAGE_CODES.LT]: {
    label: 'Lietuvių kalba',
    value: LANGUAGE_CODES.LT,
  },
  [LANGUAGE_CODES.RO]: {
    label: 'Limba română',
    value: LANGUAGE_CODES.RO,
  },
  [LANGUAGE_CODES.LI]: {
    label: 'Limburgs',
    value: LANGUAGE_CODES.LI,
  },
  [LANGUAGE_CODES.LN]: {
    label: 'Lingála',
    value: LANGUAGE_CODES.LN,
  },
  [LANGUAGE_CODES.LG]: {
    label: 'Luganda',
    value: LANGUAGE_CODES.LG,
  },
  [LANGUAGE_CODES.LB]: {
    label: 'Lëtzebuergesch',
    value: LANGUAGE_CODES.LB,
  },
  [LANGUAGE_CODES.HU]: {
    label: 'Magyar',
    value: LANGUAGE_CODES.HU,
  },
  [LANGUAGE_CODES.MT]: {
    label: 'Malti',
    value: LANGUAGE_CODES.MT,
  },
  [LANGUAGE_CODES.NL]: {
    label: 'Nederlands',
    value: LANGUAGE_CODES.NL,
  },
  [LANGUAGE_CODES.NO]: {
    label: 'Norsk',
    value: LANGUAGE_CODES.NO,
  },
  [LANGUAGE_CODES.NB]: {
    label: 'Norsk bokmål',
    value: LANGUAGE_CODES.NB,
  },
  [LANGUAGE_CODES.NN]: {
    label: 'Norsk nynorsk',
    value: LANGUAGE_CODES.NN,
  },
  [LANGUAGE_CODES.UZ]: {
    label: "O'zbek",
    value: LANGUAGE_CODES.UZ,
  },
  [LANGUAGE_CODES.OC]: {
    label: 'Occitan',
    value: LANGUAGE_CODES.OC,
  },
  [LANGUAGE_CODES.IE]: {
    label: 'Interlingue',
    value: LANGUAGE_CODES.IE,
  },
  [LANGUAGE_CODES.HZ]: {
    label: 'Otjiherero',
    value: LANGUAGE_CODES.HZ,
  },
  [LANGUAGE_CODES.NG]: {
    label: 'Owambo',
    value: LANGUAGE_CODES.NG,
  },
  [LANGUAGE_CODES.PT]: {
    label: 'Português',
    value: LANGUAGE_CODES.PT,
  },
  [LANGUAGE_CODES.TY]: {
    label: 'Reo Tahiti',
    value: LANGUAGE_CODES.TY,
  },
  [LANGUAGE_CODES.RM]: {
    label: 'Rumantsch grischun',
    value: LANGUAGE_CODES.RM,
  },
  [LANGUAGE_CODES.QU]: {
    label: 'Runa Simi',
    value: LANGUAGE_CODES.QU,
  },
  [LANGUAGE_CODES.SC]: {
    label: 'Sardu',
    value: LANGUAGE_CODES.SC,
  },
  [LANGUAGE_CODES.ZA]: {
    label: 'Saɯ cueŋƅ',
    value: LANGUAGE_CODES.ZA,
  },
  [LANGUAGE_CODES.ST]: {
    label: 'Sesotho',
    value: LANGUAGE_CODES.ST,
  },
  [LANGUAGE_CODES.TN]: {
    label: 'Setswana',
    value: LANGUAGE_CODES.TN,
  },
  [LANGUAGE_CODES.SS]: {
    label: 'SiSwati',
    value: LANGUAGE_CODES.SS,
  },
  [LANGUAGE_CODES.SL]: {
    label: 'Slovenski jezik',
    value: LANGUAGE_CODES.SL,
  },
  [LANGUAGE_CODES.SK]: {
    label: 'Slovenčina',
    value: LANGUAGE_CODES.SK,
  },
  [LANGUAGE_CODES.SO]: {
    label: 'Soomaaliga',
    value: LANGUAGE_CODES.SO,
  },
  [LANGUAGE_CODES.FI]: {
    label: 'Suomi',
    value: LANGUAGE_CODES.FI,
  },
  [LANGUAGE_CODES.SV]: {
    label: 'Svenska',
    value: LANGUAGE_CODES.SV,
  },
  [LANGUAGE_CODES.MI]: {
    label: 'Te reo Māori',
    value: LANGUAGE_CODES.MI,
  },
  [LANGUAGE_CODES.VI]: {
    label: 'Tiếng Việt',
    value: LANGUAGE_CODES.VI,
  },
  [LANGUAGE_CODES.LU]: {
    label: 'Tshiluba',
    value: LANGUAGE_CODES.LU,
  },
  [LANGUAGE_CODES.VE]: {
    label: 'Tshivenḓa',
    value: LANGUAGE_CODES.VE,
  },
  [LANGUAGE_CODES.TW]: {
    label: 'Twi',
    value: LANGUAGE_CODES.TW,
  },
  [LANGUAGE_CODES.TK]: {
    label: 'Türkmen',
    value: LANGUAGE_CODES.TK,
  },
  [LANGUAGE_CODES.TR]: {
    label: 'Türkçe',
    value: LANGUAGE_CODES.TR,
  },
  [LANGUAGE_CODES.UG]: {
    label: 'Uyƣurqə',
    value: LANGUAGE_CODES.UG,
  },
  [LANGUAGE_CODES.VO]: {
    label: 'Volapük',
    value: LANGUAGE_CODES.VO,
  },
  [LANGUAGE_CODES.FJ]: {
    label: 'Vosa Vakaviti',
    value: LANGUAGE_CODES.FJ,
  },
  [LANGUAGE_CODES.WA]: {
    label: 'Walon',
    value: LANGUAGE_CODES.WA,
  },
  [LANGUAGE_CODES.TL]: {
    label: 'Wikang Tagalog',
    value: LANGUAGE_CODES.TL,
  },
  [LANGUAGE_CODES.WO]: {
    label: 'Wollof',
    value: LANGUAGE_CODES.WO,
  },
  [LANGUAGE_CODES.TS]: {
    label: 'Xitsonga',
    value: LANGUAGE_CODES.TS,
  },
  [LANGUAGE_CODES.YO]: {
    label: 'Yorùbá',
    value: LANGUAGE_CODES.YO,
  },
  [LANGUAGE_CODES.SG]: {
    label: 'Yângâ tî sängö',
    value: LANGUAGE_CODES.SG,
  },
  [LANGUAGE_CODES.IS]: {
    label: 'Íslenska',
    value: LANGUAGE_CODES.IS,
  },
  [LANGUAGE_CODES.CS]: {
    label: 'čeština',
    value: LANGUAGE_CODES.CS,
  },
  [LANGUAGE_CODES.EL]: {
    label: 'ελληνικά',
    value: LANGUAGE_CODES.EL,
  },
  [LANGUAGE_CODES.AV]: {
    label: 'авар мацӀ',
    value: LANGUAGE_CODES.AV,
  },
  [LANGUAGE_CODES.AB]: {
    label: 'аҧсуа бызшәа',
    value: LANGUAGE_CODES.AB,
  },
  [LANGUAGE_CODES.BA]: {
    label: 'башҡорт теле',
    value: LANGUAGE_CODES.BA,
  },
  [LANGUAGE_CODES.BE]: {
    label: 'беларуская мова',
    value: LANGUAGE_CODES.BE,
  },
  [LANGUAGE_CODES.BG]: {
    label: 'български език',
    value: LANGUAGE_CODES.BG,
  },
  [LANGUAGE_CODES.OS]: {
    label: 'ирон æвзаг',
    value: LANGUAGE_CODES.OS,
  },
  [LANGUAGE_CODES.KV]: {
    label: 'коми кыв',
    value: LANGUAGE_CODES.KV,
  },
  [LANGUAGE_CODES.KY]: {
    label: 'Кыргызча',
    value: LANGUAGE_CODES.KY,
  },
  [LANGUAGE_CODES.MK]: {
    label: 'македонски јазик',
    value: LANGUAGE_CODES.MK,
  },
  [LANGUAGE_CODES.MN]: {
    label: 'монгол',
    value: LANGUAGE_CODES.MN,
  },
  [LANGUAGE_CODES.CE]: {
    label: 'нохчийн мотт',
    value: LANGUAGE_CODES.CE,
  },
  [LANGUAGE_CODES.RU]: {
    label: 'Русский язык',
    value: LANGUAGE_CODES.RU,
  },
  [LANGUAGE_CODES.SR]: {
    label: 'српски језик',
    value: LANGUAGE_CODES.SR,
  },
  [LANGUAGE_CODES.TT]: {
    label: 'татар теле',
    value: LANGUAGE_CODES.TT,
  },
  [LANGUAGE_CODES.TG]: {
    label: 'тоҷикӣ',
    value: LANGUAGE_CODES.TG,
  },
  [LANGUAGE_CODES.UK]: {
    label: 'українська мова',
    value: LANGUAGE_CODES.UK,
  },
  [LANGUAGE_CODES.CV]: {
    label: 'чӑваш чӗлхи',
    value: LANGUAGE_CODES.CV,
  },
  [LANGUAGE_CODES.CU]: {
    label: 'ѩзыкъ словѣньскъ',
    value: LANGUAGE_CODES.CU,
  },
  [LANGUAGE_CODES.KK]: {
    label: 'қазақ тілі',
    value: LANGUAGE_CODES.KK,
  },
  [LANGUAGE_CODES.HY]: {
    label: 'Հայերեն',
    value: LANGUAGE_CODES.HY,
  },
  [LANGUAGE_CODES.YI]: {
    label: 'ייִדיש',
    value: LANGUAGE_CODES.YI,
  },
  [LANGUAGE_CODES.HE]: {
    label: 'עברית',
    value: LANGUAGE_CODES.HE,
  },
  [LANGUAGE_CODES.UR]: {
    label: 'اردو',
    value: LANGUAGE_CODES.UR,
  },
  [LANGUAGE_CODES.AR]: {
    label: 'العربية',
    value: LANGUAGE_CODES.AR,
  },
  [LANGUAGE_CODES.FA]: {
    label: 'فارسی',
    value: LANGUAGE_CODES.FA,
  },
  [LANGUAGE_CODES.PS]: {
    label: 'پښتو',
    value: LANGUAGE_CODES.PS,
  },
  [LANGUAGE_CODES.KS]: {
    label: 'कश्मीरी',
    value: LANGUAGE_CODES.KS,
  },
  [LANGUAGE_CODES.NE]: {
    label: 'नेपाली',
    value: LANGUAGE_CODES.NE,
  },
  [LANGUAGE_CODES.PI]: {
    label: 'पाऴि',
    value: LANGUAGE_CODES.PI,
  },
  [LANGUAGE_CODES.BH]: {
    label: 'भोजपुरी',
    value: LANGUAGE_CODES.BH,
  },
  [LANGUAGE_CODES.MR]: {
    label: 'मराठी',
    value: LANGUAGE_CODES.MR,
  },
  [LANGUAGE_CODES.SA]: {
    label: 'संस्कृतम्',
    value: LANGUAGE_CODES.SA,
  },
  [LANGUAGE_CODES.SD]: {
    label: 'सिन्धी',
    value: LANGUAGE_CODES.SD,
  },
  [LANGUAGE_CODES.HI]: {
    label: 'हिन्दी',
    value: LANGUAGE_CODES.HI,
  },
  [LANGUAGE_CODES.AS]: {
    label: 'অসমীয়া',
    value: LANGUAGE_CODES.AS,
  },
  [LANGUAGE_CODES.BN]: {
    label: 'বাংলা',
    value: LANGUAGE_CODES.BN,
  },
  [LANGUAGE_CODES.PA]: {
    label: 'ਪੰਜਾਬੀ',
    value: LANGUAGE_CODES.PA,
  },
  [LANGUAGE_CODES.GU]: {
    label: 'ગુજરાતી',
    value: LANGUAGE_CODES.GU,
  },
  [LANGUAGE_CODES.OR]: {
    label: 'ଓଡ଼ିଆ',
    value: LANGUAGE_CODES.OR,
  },
  [LANGUAGE_CODES.TA]: {
    label: 'தமிழ்',
    value: LANGUAGE_CODES.TA,
  },
  [LANGUAGE_CODES.TE]: {
    label: 'తెలుగు',
    value: LANGUAGE_CODES.TE,
  },
  [LANGUAGE_CODES.KN]: {
    label: 'ಕನ್ನಡ',
    value: LANGUAGE_CODES.KN,
  },
  [LANGUAGE_CODES.ML]: {
    label: 'മലയാളം',
    value: LANGUAGE_CODES.ML,
  },
  [LANGUAGE_CODES.SI]: {
    label: 'සිංහල',
    value: LANGUAGE_CODES.SI,
  },
  [LANGUAGE_CODES.TH]: {
    label: 'ไทย',
    value: LANGUAGE_CODES.TH,
  },
  [LANGUAGE_CODES.LO]: {
    label: 'ພາສາລາວ',
    value: LANGUAGE_CODES.LO,
  },
  [LANGUAGE_CODES.BO]: {
    label: 'བོད་ཡིག',
    value: LANGUAGE_CODES.BO,
  },
  [LANGUAGE_CODES.DZ]: {
    label: 'རྫོང་ཁ',
    value: LANGUAGE_CODES.DZ,
  },
  [LANGUAGE_CODES.MY]: {
    label: 'ဗမာစာ',
    value: LANGUAGE_CODES.MY,
  },
  [LANGUAGE_CODES.KA]: {
    label: 'ქართული',
    value: LANGUAGE_CODES.KA,
  },
  [LANGUAGE_CODES.TI]: {
    label: 'ትግርኛ',
    value: LANGUAGE_CODES.TI,
  },
  [LANGUAGE_CODES.AM]: {
    label: 'አማርኛ',
    value: LANGUAGE_CODES.AM,
  },
  [LANGUAGE_CODES.IU]: {
    label: 'ᐃᓄᒃᑎᑐᑦ',
    value: LANGUAGE_CODES.IU,
  },
  [LANGUAGE_CODES.OJ]: {
    label: 'ᐊᓂᔑᓈᐯᒧᐎᓐ',
    value: LANGUAGE_CODES.OJ,
  },
  [LANGUAGE_CODES.CR]: {
    label: 'ᓀᐦᐃᔭᐍᐏᐣ',
    value: LANGUAGE_CODES.CR,
  },
  [LANGUAGE_CODES.KM]: {
    label: 'ខ្មែរ',
    value: LANGUAGE_CODES.KM,
  },
  [LANGUAGE_CODES.ZH]: {
    label: '中文 (Zhōngwén)',
    value: LANGUAGE_CODES.ZH,
  },
  [LANGUAGE_CODES.JA]: {
    label: '日本語 (にほんご)',
    value: LANGUAGE_CODES.JA,
  },
  [LANGUAGE_CODES.II]: {
    label: 'ꆈꌠ꒿ Nuosuhxop',
    value: LANGUAGE_CODES.II,
  },
  [LANGUAGE_CODES.KO]: {
    label: '한국어 (韓國語)',
    value: LANGUAGE_CODES.KO,
  },
  [LANGUAGE_CODES.CA_VALENCIA]: {
    label: 'Valencià',
    value: LANGUAGE_CODES.CA_VALENCIA,
  },
  [LANGUAGE_CODES.CEB]: {
    label: 'Cebuano',
    value: LANGUAGE_CODES.CEB,
  },
  [LANGUAGE_CODES.ZH_HANS]: {
    label: '简体中文',
    value: LANGUAGE_CODES.ZH_HANS,
  },
  [LANGUAGE_CODES.HAT]: {
    label: 'Kreyòl ayisyen',
    value: LANGUAGE_CODES.HAT,
  },
  [LANGUAGE_CODES.ZH_HANT_HK]: {
    label: '繁體中文語系',
    value: LANGUAGE_CODES.ZH_HANT_HK,
  },
  [LANGUAGE_CODES.ZH_HANT_TW]: {
    label: '繁體中文（台灣）',
    value: LANGUAGE_CODES.ZH_HANT_TW,
  },
  [LANGUAGE_CODES.CS_INFORMAL]: {
    label: 'Česky neformální',
    value: LANGUAGE_CODES.CS_INFORMAL,
  },
  [LANGUAGE_CODES.PRS]: {
    label: 'دری',
    value: LANGUAGE_CODES.PRS,
  },
  [LANGUAGE_CODES.NL_INFORMAL]: {
    label: 'Nederlands (informeel)',
    value: LANGUAGE_CODES.NL_INFORMAL,
  },
  [LANGUAGE_CODES.FIL]: {
    label: 'Wikang Filipino',
    value: LANGUAGE_CODES.FIL,
  },
  [LANGUAGE_CODES.FUL]: {
    label: 'Fulfulde',
    value: LANGUAGE_CODES.FUL,
  },
  [LANGUAGE_CODES.DE_EASY]: {
    label: 'Deutsch - Leichte Sprache',
    value: LANGUAGE_CODES.DE_EASY,
  },
  [LANGUAGE_CODES.KAL]: {
    label: 'Kalaallisut',
    value: LANGUAGE_CODES.KAL,
  },
  [LANGUAGE_CODES.HIL]: {
    label: 'Ilonggo',
    value: LANGUAGE_CODES.HIL,
  },
  [LANGUAGE_CODES.IKE]: {
    label: 'ᐃᓄᒃᑎᑐᑦ',
    value: LANGUAGE_CODES.IKE,
  },
  [LANGUAGE_CODES.ILO]: {
    label: 'Ilokano',
    value: LANGUAGE_CODES.ILO,
  },
  [LANGUAGE_CODES.IT_INFORMAL]: {
    label: 'Italiano (informale)',
    value: LANGUAGE_CODES.IT_INFORMAL,
  },
  [LANGUAGE_CODES.RUN]: {
    label: 'Ikirundi',
    value: LANGUAGE_CODES.RUN,
  },
  [LANGUAGE_CODES.CKB]: {
    label: 'کوردیی ناوەندی',
    value: LANGUAGE_CODES.CKB,
  },
  [LANGUAGE_CODES.KMR]: {
    label: 'Kurmancî',
    value: LANGUAGE_CODES.KMR,
  },
  [LANGUAGE_CODES.CNR]: {
    label: 'Crnogorski',
    value: LANGUAGE_CODES.CNR,
  },
  [LANGUAGE_CODES.MYA]: {
    label: 'မြန်မာဘာသာ',
    value: LANGUAGE_CODES.MYA,
  },
  [LANGUAGE_CODES.PAP_CW]: {
    label: 'Papiamentu',
    value: LANGUAGE_CODES.PAP_CW,
  },
  [LANGUAGE_CODES.PL_INFORMAL]: {
    label: 'Polski (nieformalny)',
    value: LANGUAGE_CODES.PL_INFORMAL,
  },
  [LANGUAGE_CODES.PT_BR]: {
    label: 'Português do Brasil',
    value: LANGUAGE_CODES.PT_BR,
  },
  [LANGUAGE_CODES.ROH]: {
    label: 'Rumantsch',
    value: LANGUAGE_CODES.ROH,
  },
  [LANGUAGE_CODES.SMI]: {
    label: 'Davvisámegiella',
    value: LANGUAGE_CODES.SMI,
  },
  [LANGUAGE_CODES.SR_LATN]: {
    label: 'Srpski',
    value: LANGUAGE_CODES.SR_LATN,
  },
  [LANGUAGE_CODES.ES_INFORMAL]: {
    label: 'Español (informal)',
    value: LANGUAGE_CODES.ES_INFORMAL,
  },
  [LANGUAGE_CODES.ES_AR]: {
    label: 'Español rioplatense',
    value: LANGUAGE_CODES.ES_AR,
  },
  [LANGUAGE_CODES.ES_AR_INFORMAL]: {
    label: 'Español rioplatense informal',
    value: LANGUAGE_CODES.ES_AR_INFORMAL,
  },
  [LANGUAGE_CODES.ES_CL]: {
    label: 'Español chileno',
    value: LANGUAGE_CODES.ES_CL,
  },
  [LANGUAGE_CODES.ES_CO]: {
    label: 'Español colombiano',
    value: LANGUAGE_CODES.ES_CO,
  },
  [LANGUAGE_CODES.ES_MX]: {
    label: 'Español mexicano',
    value: LANGUAGE_CODES.ES_MX,
  },
  [LANGUAGE_CODES.SWH]: {
    label: 'Kiswahili',
    value: LANGUAGE_CODES.SWH,
  },
  [LANGUAGE_CODES.XHO]: {
    label: 'isiXhosa',
    value: LANGUAGE_CODES.XHO,
  },
  [LANGUAGE_CODES.SAH]: {
    label: 'Саха тыла',
    value: LANGUAGE_CODES.SAH,
  },
  [LANGUAGE_CODES.YOR]: {
    label: 'Èdè Yorùbá',
    value: LANGUAGE_CODES.YOR,
  },
}
