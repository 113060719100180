import { Entities } from 'helpers'

import { ContentEditor, ToggleButtons } from 'components/UIComponents'

import { SurveySettingComponent } from '../SurveySettingComponent'

export const surveyPrivacyPolicyAttributes = [
  {
    component: SurveySettingComponent,
    keyPath: 'showLegalNoticeButton',
    props: {
      id: 'show-legal-notice',
      mainText: 'Show legal notice in survey',
      subText: 'optional multiline text description',
      childComponent: ToggleButtons,
      toggleOptions: [
        { name: 'On', value: true },
        { name: 'Off', value: false },
      ],
      noPermissionDisabled: true,
    },
  },
  {
    component: SurveySettingComponent,
    entity: Entities.languageSetting,
    keyPath: 'languageSettings.legalNotice',
    renderCondition: 'showLegalNoticeButton',
    props: {
      childComponent: ContentEditor,
      extraClass: 'editable-content-editor',
      id: 'legal-notice-message',
      mainText: 'Legal Notice Message',
      placeholder: 'Legal Notice Message',
      renderFullWidth: true,
      subText: 'Legal Notice message',
      noPermissionDisabled: true,
    },
  },
  {
    component: SurveySettingComponent,
    keyPath: 'showDataPolicyButton',
    props: {
      id: 'show-privacy-policy',
      mainText: 'Show data policy in survey',
      subText: 'optional multiline text description',
      childComponent: ToggleButtons,
      toggleOptions: [
        { name: 'On', value: true },
        { name: 'Off', value: false },
      ],
      noPermissionDisabled: true,
    },
  },
  {
    component: SurveySettingComponent,
    entity: Entities.languageSetting,
    keyPath: 'languageSettings.policyNotice',
    renderCondition: 'showDataPolicyButton',
    props: {
      id: 'privacy-policy-message',
      placeholder: 'Privacy Policy Message',
      extraClass: 'editable-content-editor',
      renderFullWidth: true,
      subText: 'Privacy policy message',
      childComponent: ContentEditor,
      noPermissionDisabled: true,
    },
  },

  {
    component: SurveySettingComponent,
    entity: Entities.languageSetting,
    keyPath: 'languageSettings.policyError',
    renderCondition: 'showDataPolicyButton',
    props: {
      id: 'privacy-policy-message',
      placeholder: 'Privacy policy error message',
      extraClass: 'editable-content-editor',
      renderFullWidth: true,
      subText: 'Privacy policy error message',
      childComponent: ContentEditor,
      noPermissionDisabled: true,
    },
  },

  {
    component: SurveySettingComponent,
    entity: Entities.languageSetting,
    keyPath: 'languageSettings.policyNoticeLabel',
    renderCondition: 'showDataPolicyButton',
    props: {
      id: 'privacy-policy-label-message',
      placeholder: 'Label (Text for the Checkbox)',
      extraClass: 'editable-content-editor',
      renderFullWidth: true,
      subText: 'Label (Text for the Checkbox)',
      childComponent: ContentEditor,
      noPermissionDisabled: true,
    },
  },
]
