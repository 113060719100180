import React from 'react'
export const AddIcon = (props) => {
  return (
    <svg viewBox="0 0 12 12" {...props} width={12} height={12}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 6.75V12H6.75V6.75H12V5.25H6.75V0H5.25V5.25H0V6.75H5.25Z"
      />
    </svg>
  )
}
