import { LANGUAGE_CODES, NEW_OBJECT_ID_PREFIX } from 'helpers'
import { RandomNumber } from './RandomNumber'

export const getAnswerExample = ({
  qid,
  sortOrder = 0,
  scaleId = 0,
  language = LANGUAGE_CODES.EN,
  languageValue = '',
}) => {
  const aid = `${NEW_OBJECT_ID_PREFIX}${RandomNumber()}`

  return {
    aid,
    tempId: aid,
    qid,
    code: `AO${RandomNumber(1, 999)}`,
    sortOrder,
    assessmentValue: 0,
    scaleId,
    l10ns: {
      [language]: {
        aid: aid,
        answer: languageValue,
        language,
      },
    },
  }
}
