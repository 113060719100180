import { useState, useEffect } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import classNames from 'classnames'
import Button from 'react-bootstrap/Button'

import {
  arrayDeleteItem,
  DuplicateQuestionGroup,
  IsElementOnScreen,
  ScrollToElement,
  confirmAlert,
  MoveQuestionGroup,
  createBufferOperation,
  getReorganizedQuestionGroups,
  STATES,
  MoveQuestion,
  getReorganizedQuestions,
} from 'helpers'
import { useFocused, useAppState, useBuffer } from 'hooks'
import { SideBarHeader } from 'components/SideBar'
import { CloseIcon } from 'components/icons'

import { QuestionTypeInfo } from '../QuestionTypes'
import { RowPinned } from './RowPinned'
import { RowQuestionGroup } from './RowQuestionGroup'
import { arrayInsertItem } from '../../helpers/arrayInsertItem'

export const SurveyStructure = ({
  userSettings: { showQuestionCode } = {},
  survey: { questionGroups = [], language, showWelcome },
  update,
}) => {
  const { setFocused, unFocus } = useFocused()

  const { addToBuffer } = useBuffer()

  const [, setEditorStructurePanelOpen] = useAppState(
    STATES.IS_EDITOR_STRUCTURE_PANEL_OPEN,
    true
  )

  const [hasSurveyUpdatePermission] = useAppState(
    STATES.HAS_SURVEY_UPDATE_PERMISSION
  )

  const [isSurveyActive] = useAppState(STATES.IS_SURVEY_ACTIVE, false)
  const [isDraggingOutOfGroup, setIsDraggingOutOfGroup] = useState(false)

  useEffect(() => {
    const allGroupsElement = document.querySelector(
      '[data-rbd-droppable-id="all-groups"]'
    )
    if (allGroupsElement) {
      if (isDraggingOutOfGroup || !hasSurveyUpdatePermission) {
        allGroupsElement.style.cursor = 'not-allowed'
      } else {
        allGroupsElement.style.cursor = 'auto'
      }

      return () => {
        allGroupsElement.style.cursor = 'auto'
      }
    }
  }, [isDraggingOutOfGroup, hasSurveyUpdatePermission])

  const handleUpdateQuestionGroup = (questionGroup, index) => {
    const updatedQuestionGroups = [...questionGroups]
    updatedQuestionGroups[index] = questionGroup

    update(updatedQuestionGroups)
  }

  const handleGroupDeletion = (questionGroup, index) => {
    confirmAlert({ icon: 'warning' }).then(({ isConfirmed }) => {
      if (!isConfirmed) {
        return
      }

      const [updatedQuestionGroups] = arrayDeleteItem(questionGroups, index)

      const operation = createBufferOperation(questionGroup.gid)
        .questionGroup()
        .delete()

      unFocus()
      update(updatedQuestionGroups)
      addToBuffer(operation)
    })
  }

  const handleOnDragEnd = (dropResult) => {
    setIsDraggingOutOfGroup(false)
    const { source, destination, type } = dropResult
    // Dropped outside the list or no permission
    if (!destination || !hasSurveyUpdatePermission) {
      return
    }

    const currentIndex = dropResult.source.index
    const newIndex = dropResult.destination.index

    // Handle group reordering
    let reordered = reorderGroup(type, currentIndex, newIndex)

    if (!reordered) {
      // Handle moving question within the same group
      reordered = reorderQuestionWithinGroup(
        source,
        destination,
        currentIndex,
        newIndex
      )
    }

    if (!reordered) {
      // Handle moving question between groups
      reorderQuestionToNewGroup(source, destination)
    }
  }

  const onDragUpdate = (update) => {
    const { destination, source } = update
    if (!destination) {
      return
    }
    const isOutOfGroup =
      destination.droppableId !== source.droppableId && isSurveyActive
    setIsDraggingOutOfGroup(isOutOfGroup)
  }

  const reorderGroup = (type, currentIndex, newIndex) => {
    if (type === 'group') {
      const { movedQuestionGroup, reorderedQuestionGroups } = MoveQuestionGroup(
        questionGroups,
        currentIndex,
        newIndex
      )

      const operation = createBufferOperation()
        .questionGroupReorder()
        .update(getReorganizedQuestionGroups(reorderedQuestionGroups))
      addToBuffer(operation)
      update(reorderedQuestionGroups)
      setFocused({ ...movedQuestionGroup }, newIndex)
      return true
    }
    return false
  }

  const reorderQuestionWithinGroup = (
    source,
    destination,
    currentIndex,
    newIndex
  ) => {
    if (source.droppableId === destination.droppableId) {
      const groupIndex = source.droppableId.replace('g', '')
      const questions = questionGroups[groupIndex].questions
      const { movedQuestion, reorderedQuestions } = MoveQuestion(
        questions,
        currentIndex,
        newIndex
      )
      const groupOrder = groupIndex + 1
      const props = {
        [movedQuestion.gid]: {
          sortOrder: groupOrder,
          questions: getReorganizedQuestions(reorderedQuestions),
        },
      }
      const operation = createBufferOperation()
        .questionGroupReorder()
        .update(props)
      addToBuffer(operation)
      questionGroups[groupIndex].questions = reorderedQuestions
      update(questionGroups)
      setFocused(movedQuestion, groupIndex, newIndex)
      return true
    }
    return false
  }

  const reorderQuestionToNewGroup = (source, destination) => {
    if (!isSurveyActive) {
      const sourceGroupIndex = source.droppableId.replace('g', '')
      const destGroupIndex = destination.droppableId.replace('g', '')
      // remove question from source and add it to destination group
      const [sourceQuestions, [movedQuestion]] = arrayDeleteItem(
        questionGroups[sourceGroupIndex].questions,
        source.index
      )
      const destQuestions = arrayInsertItem(
        questionGroups[destGroupIndex].questions,
        destination.index,
        movedQuestion
      )

      //refresh both affected groups with new updated questions
      questionGroups[sourceGroupIndex].questions = sourceQuestions
      questionGroups[destGroupIndex].questions = destQuestions

      const operation = createBufferOperation()
        .questionGroupReorder()
        .update(getReorganizedQuestionGroups(questionGroups))
      addToBuffer(operation)
      update(questionGroups)
      setFocused(movedQuestion, destGroupIndex, destination.index)
    }
  }

  const handleQuestionGroupDuplication = (questionGroup, index) => {
    const duplicatedQuestionGroup = DuplicateQuestionGroup(questionGroup)

    let updatedQuestionGroups = [...questionGroups]
    updatedQuestionGroups.splice(index + 1, 0, duplicatedQuestionGroup)

    updatedQuestionGroups = updatedQuestionGroups.map(
      (questionGroup, index) => {
        questionGroup.groupOrder = index + 1
        return questionGroup
      }
    )

    const operation = createBufferOperation(duplicatedQuestionGroup.gid)
      .questionGroup()
      .create({
        questionGroup: {
          ...duplicatedQuestionGroup,
          tempId: duplicatedQuestionGroup.gid,
        },
        questionGroupL10n: duplicatedQuestionGroup.l10ns,
      })

    update(updatedQuestionGroups)
    addToBuffer(operation)

    duplicatedQuestionGroup.questions.map((question) => {
      const operation = createBufferOperation(question.qid)
        .question()
        .create({
          question: { ...question, tempId: question.qid },
          questionL10n: { ...question.l10ns },
          attributes: { ...question.attributes },
          answers: { ...question.answers },
          subquestions: { ...question.subquestions },
        })
      addToBuffer(operation)
    })

    setFocused(duplicatedQuestionGroup, index + 1)
  }

  const getQuestionGroupDragStyle = (draggableStyle) => ({
    userSelect: 'none',
    margin: questionGroups.length > 0 ? `0 0 18px 0` : '',
    ...draggableStyle,
  })

  const scrollToElement = (id) => {
    const element = document.getElementById(id)

    if (!element) {
      return
    }

    const isElementOnScreen = IsElementOnScreen(element)

    if (!isElementOnScreen) {
      ScrollToElement(element)
    }
  }

  return (
    <div
      data-testid="editor-structure-panel"
      className="d-flex"
      style={{ height: '100%' }}
    >
      <div
        className="survey-structure px-2"
        style={{ overflowY: 'auto', width: '290px' }}
      >
        <SideBarHeader className="primary">
          Structure
          <Button
            variant="link"
            className="p-0 btn-close-lime"
            data-testid="btn-close-structure"
            onClick={() => setEditorStructurePanelOpen(false)}
          >
            <CloseIcon className="text-black fill-current" />
          </Button>
        </SideBarHeader>
        <div
          data-testid="survey-structure-header"
          onClick={() => {
            scrollToElement('survey-header')
            setFocused({ info: QuestionTypeInfo.WELCOME_SCREEN })
          }}
        >
          <RowPinned title="Welcome" disabled={!showWelcome} />
        </div>
        <DragDropContext
          onDragEnd={handleOnDragEnd}
          onDragUpdate={onDragUpdate}
        >
          <Droppable droppableId="all-groups" type="group" direction="vertical">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {questionGroups.map((questionGroup, index) => (
                  <Draggable
                    index={index}
                    key={`questionGroupStructure-${index}`}
                    draggableId={`questionGroupStructure-${index}`}
                  >
                    {(provided, snapshot) => (
                      <div
                        data-ordervalue={questionGroup.groupOrder}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getQuestionGroupDragStyle(
                          provided.draggableProps.style
                        )}
                        className={classNames({
                          'focus-element': snapshot.isDragging,
                        })}
                      >
                        <RowQuestionGroup
                          provided={provided}
                          questionGroup={questionGroup}
                          language={language}
                          update={(questionGroup) =>
                            handleUpdateQuestionGroup(questionGroup, index)
                          }
                          duplicateGroup={() =>
                            handleQuestionGroupDuplication(questionGroup, index)
                          }
                          deleteGroup={() =>
                            handleGroupDeletion(questionGroup, index)
                          }
                          onTitleClick={() =>
                            setFocused({ ...questionGroup }, index)
                          }
                          groupIndex={index}
                          showQuestionCode={showQuestionCode}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {questionGroups.length > 0 ? (
          <div
            data-testid="survey-structure-footer"
            onClick={() => {
              scrollToElement('survey-footer-title')
              setFocused({ info: QuestionTypeInfo.END_SCREEN })
            }}
          >
            <RowPinned title="End" />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
