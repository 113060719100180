import React from 'react'
export const QuestionInserterSingleChoiceIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      fill="none"
      className={props.className}
    >
      <rect width="15" height="3.111" fill="#D3D5DA" opacity=".2" rx="1" />
      <rect width="15" height="3.111" y="5.444" fill="#333641" rx="1" />
      <rect
        width="15"
        height="3.111"
        y="10.889"
        fill="#333641"
        opacity=".2"
        rx="1"
      />
    </svg>
  )
}
