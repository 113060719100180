import { SETTINGS_TITLES } from 'helpers'

import {
  STATISTICS_ATTRIBUTES,
  LOGIC_ATTRIBUTES,
  DISPLAY_ATTRIBUTES,
  GENERAL_ATTRIBUTES,
  TIMER_ATTRIBUTES,
  LOCATION_ATTRIBUTES,
} from '../attributes'

const simpleSettings = [
  GENERAL_ATTRIBUTES.QUESTION_CODE,
  GENERAL_ATTRIBUTES.QUESTION_TYPE,
  GENERAL_ATTRIBUTES.MANDATORY,
  DISPLAY_ATTRIBUTES.IMAGE_SETTINGS,
  GENERAL_ATTRIBUTES.LOGIC,
  DISPLAY_ATTRIBUTES.SHOW_PLATFORM_INFORMATION,
  STATISTICS_ATTRIBUTES.SHOW_IN_STATISTICS,
  LOCATION_ATTRIBUTES.USE_MAPPING_SERVICE,
]

const generalSettings = [
  GENERAL_ATTRIBUTES.QUESTION_CODE,
  GENERAL_ATTRIBUTES.QUESTION_TYPE,
  GENERAL_ATTRIBUTES.MANDATORY,
  GENERAL_ATTRIBUTES.ENCRYPTED,
]

const displaySettings = [
  DISPLAY_ATTRIBUTES.IMAGE_SETTINGS,
  DISPLAY_ATTRIBUTES.ALWAYS_HIDE_THIS_QUESTION,
  DISPLAY_ATTRIBUTES.CSS_CLASSES,
  DISPLAY_ATTRIBUTES.SHOW_PLATFORM_INFORMATION,
  DISPLAY_ATTRIBUTES.PAGE_BREAK_IN_PRINTABLE_VIEW,
]

const logicSettings = [
  LOGIC_ATTRIBUTES.RANDOMIZATION_GROUP_NAME,
  LOGIC_ATTRIBUTES.QUESTION_VALIDATION_EQUATION,
  LOGIC_ATTRIBUTES.QUESTION_VALIDATION_TIP,
]

const inputSettings = []

const statisticsSettings = [
  STATISTICS_ATTRIBUTES.SHOW_IN_PUBLIC_STATISTICS,
  STATISTICS_ATTRIBUTES.SHOW_IN_STATISTICS,
]

const timerSettings = Object.values(TIMER_ATTRIBUTES)

const themeOptionsSettings = []

const fileMetaDataSettings = []

const locationSettings = Object.values(LOCATION_ATTRIBUTES)

const sliderAttributes = []

export const BROWSER_DETECTION_SETTINGS = [
  { title: SETTINGS_TITLES.SIMPLE, attributes: simpleSettings },
  { title: SETTINGS_TITLES.GENERAL, attributes: generalSettings },
  { title: SETTINGS_TITLES.DISPLAY, attributes: displaySettings },
  { title: SETTINGS_TITLES.LOGIC, attributes: logicSettings },
  { title: SETTINGS_TITLES.INPUT, attributes: inputSettings },
  { title: SETTINGS_TITLES.STATISTICS, attributes: statisticsSettings },
  { title: SETTINGS_TITLES.TIMER, attributes: timerSettings },
  {
    title: SETTINGS_TITLES.THEME_OPTIONS,
    attributes: themeOptionsSettings,
  },
  {
    title: SETTINGS_TITLES.FILE_META_DATA,
    attributes: fileMetaDataSettings,
  },
  { title: SETTINGS_TITLES.LOCATION, attributes: locationSettings },
  {
    title: SETTINGS_TITLES.SLIDER,
    attributes: sliderAttributes,
  },
]
