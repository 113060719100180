import React, { useState } from 'react'
import classNames from 'classnames'

import { ContentEditor } from 'components/UIComponents'
import { DragIcon, CloseCircleFillIcon } from 'components/icons'

import { ArraySubQuestionRadioAnswers } from './ArraySubQuestionRadioAnswers'
import { QuestionTypeInfo } from '../../QuestionTypeInfo'
import { ArraySubQuestionTextAnswers } from './ArraySubQuestionTextAnswers'
import { ArraySubQuestionNumberAnswers } from './ArraySubQuestionNumberAnswers'
import { useAppState } from '../../../../hooks'
import {
  RemoveHTMLTagsInString,
  STATES,
  TOOLTIP_MESSAGES,
} from '../../../../helpers'
import { TooltipContainer } from 'components/TooltipContainer/TooltipContainer'

export const ArrayVerticalTitle = ({
  questionThemeName,
  isFocused,
  highestWidth,
  subQuestionsHeight,
  arrayAnswersWidth,
  dragIconSize,
  provided,
  index,
  handleUpdateL10ns,
  removeItem,
  showContentEditor,
  titleValue = '',
  numberOfHorizontalEntities,
  qid,
  placeHolder = 'Subquestion',
  itemsKey,
}) => {
  const [title, setTitle] = useState(titleValue)
  const [isSurveyActive] = useAppState(STATES.IS_SURVEY_ACTIVE)

  const handleTitleUpdate = (value) => {
    const valueWithoutHTML = RemoveHTMLTagsInString(value)
    setTitle(valueWithoutHTML)
    handleUpdateL10ns(valueWithoutHTML, index)
  }

  const isArrayByColumn =
    questionThemeName === QuestionTypeInfo.ARRAY_COLUMN.theme

  return (
    <div
      className={classNames(
        'position-relative d-flex align-items-center array-question-item text-secondary remove-option-button-parent',
        {
          'bg-grey': !(index % 2) && !isArrayByColumn,
        }
      )}
    >
      {!(isSurveyActive && itemsKey === 'subquestions') && (
        <div
          className="cursor-pointer remove-option-button"
          onClick={removeItem}
          style={{
            zIndex: 10,
            position: 'absolute',
            top: '50%',
            left: '-16px',
            transform: 'translate(-50%, -50%)',
          }}
          data-testid="remove-vertical-option-button"
        >
          <CloseCircleFillIcon
            className={classNames('text-danger fill-current', {
              'd-none disabled': !isFocused || !showContentEditor,
            })}
          />
        </div>
      )}
      <TooltipContainer
        showTip={isSurveyActive}
        tip={TOOLTIP_MESSAGES.ACTIVE_DISABLED}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '4px',
            transform: 'translate(-50%, -50%)',
          }}
          className={classNames({ 'cursor-not-allowed': isSurveyActive })}
        >
          <div
            {...provided.dragHandleProps}
            className={classNames({
              'd-none': !isFocused || !showContentEditor,
              'disabled': isSurveyActive,
            })}
          >
            <DragIcon className="text-secondary fill-current" />
          </div>
        </div>
      </TooltipContainer>
      <div
        style={{
          minWidth: showContentEditor ? highestWidth + dragIconSize : '100px',
          maxWidth: !showContentEditor && '100px',
          display: showContentEditor ? 'flex' : 'none',
        }}
        className="ps-3 flex-row justify-content-start"
      >
        <ContentEditor
          placeholder={`${placeHolder} ${index + 1}`}
          value={title}
          update={(value) => handleTitleUpdate(value)}
          className="array-subquestion-content-editor choice"
          style={{
            width: 'fit-content',
          }}
        />
      </div>

      <div className="d-flex">
        {Array(numberOfHorizontalEntities)
          .fill(1)
          .map((_, _index) => (
            <React.Fragment key={`array-verticalTitle${_index}${qid}`}>
              {questionThemeName === QuestionTypeInfo.ARRAY.theme && (
                <ArraySubQuestionRadioAnswers
                  subQuestionindex={index}
                  arrayAnswersWidth={arrayAnswersWidth}
                  dragIconSize={dragIconSize}
                  subQuestionsHeight={subQuestionsHeight}
                  index={_index}
                  showBackgroundUnderSubQuestion={
                    !(_index % 2) && isArrayByColumn
                  }
                  qid={qid}
                />
              )}
              {questionThemeName === QuestionTypeInfo.ARRAY_TEXT.theme && (
                <ArraySubQuestionTextAnswers
                  arrayAnswersWidth={arrayAnswersWidth}
                  dragIconSize={dragIconSize}
                  subQuestionindex={_index}
                  subQuestionsHeight={subQuestionsHeight}
                  index={_index}
                  showBackgroundUnderSubQuestion={
                    !(_index % 2) && isArrayByColumn
                  }
                />
              )}
              {questionThemeName === QuestionTypeInfo.ARRAY_NUMBERS.theme && (
                <ArraySubQuestionNumberAnswers
                  arrayAnswersWidth={arrayAnswersWidth}
                  dragIconSize={dragIconSize}
                  subQuestionindex={_index}
                  subQuestionsHeight={subQuestionsHeight}
                  index={_index}
                  showBackgroundUnderSubQuestion={
                    !(_index % 2) && isArrayByColumn
                  }
                />
              )}
              {questionThemeName === QuestionTypeInfo.ARRAY_COLUMN.theme && (
                <ArraySubQuestionRadioAnswers
                  qid={qid}
                  arrayAnswersWidth={arrayAnswersWidth}
                  dragIconSize={dragIconSize}
                  subQuestionindex={_index}
                  subQuestionsHeight={subQuestionsHeight}
                  index={_index}
                  subQuestionIndex={index}
                  showBackgroundUnderSubQuestion={
                    !(_index % 2) && isArrayByColumn
                  }
                />
              )}
              {questionThemeName ===
                QuestionTypeInfo.ARRAY_DUAL_SCALE.theme && (
                <ArraySubQuestionRadioAnswers
                  qid={qid}
                  arrayAnswersWidth={arrayAnswersWidth}
                  dragIconSize={dragIconSize}
                  subQuestionindex={_index}
                  subQuestionsHeight={subQuestionsHeight}
                  index={_index}
                  showBackgroundUnderSubQuestion={
                    !(_index % 2) && isArrayByColumn
                  }
                />
              )}
            </React.Fragment>
          ))}
      </div>
    </div>
  )
}
