export const ExclamationMark = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00016 13.6668C3.31816 13.6668 0.333496 10.6822 0.333496 7.00016C0.333496 3.31816 3.31816 0.333496 7.00016 0.333496C10.6822 0.333496 13.6668 3.31816 13.6668 7.00016C13.6668 10.6822 10.6822 13.6668 7.00016 13.6668ZM6.3335 9.00016V10.3335H7.66683V9.00016H6.3335ZM6.3335 3.66683V7.66683H7.66683V3.66683H6.3335Z"
        fill="#1E1E1E"
      />
    </svg>
  )
}
