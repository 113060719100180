import { SideBarRow } from 'components/SideBar/SideBarRow'
import { RowQuestionsList } from './RowQuestionsList'
import { MeatballMenu } from 'components/MeatballMenu/MeatballMenu'
import { ClipBoardIcon } from 'components/icons'
import { LANGUAGE_CODES } from 'helpers'
import { useFocused } from 'hooks'

export const RowQuestionGroup = ({
  questionGroup,
  language,
  update,
  provided,
  duplicateGroup,
  deleteGroup,
  groupIndex,
  showQuestionCode,
  onTitleClick = () => {},
}) => {
  const { focused } = useFocused()
  const handleQuestionsUpdate = (questions) => {
    update({ ...questionGroup, questions })
  }

  const handleDuplicate = () => {
    duplicateGroup()
  }

  const handleDelete = () => {
    deleteGroup()
  }

  const languageTitle = questionGroup.l10ns[language]?.groupName
  const englishLanguageTitle = questionGroup.l10ns[LANGUAGE_CODES.EN]?.groupName

  const title = languageTitle
    ? languageTitle
    : englishLanguageTitle
      ? englishLanguageTitle
      : ''

  return (
    <SideBarRow
      testId={`survey-structure-question-group`}
      title={title}
      titlePlaceholder={"What's your question group is about?"}
      provided={provided}
      icon={<ClipBoardIcon />}
      onTitleClick={onTitleClick}
      isQuestionGroup={true}
      meatballButton={
        <MeatballMenu
          deleteText={'Delete group'}
          duplicateText={'Duplicate group'}
          handleDelete={handleDelete}
          handleDuplicate={handleDuplicate}
        />
      }
      isOpen={focused?.gid === questionGroup.gid}
    >
      <RowQuestionsList
        questions={questionGroup.questions}
        language={language}
        handleUpdate={handleQuestionsUpdate}
        groupIndex={groupIndex}
        showQuestionCode={showQuestionCode}
      />
    </SideBarRow>
  )
}
