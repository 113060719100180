import { Form } from 'react-bootstrap'
import { useEffect } from 'react'

import { getAttributeValue, isTrue } from 'helpers'

import './TextQuestion.scss'

export const ShortTextAnswer = ({
  attributes = {},
  answer,
  setAnswer,
  language = 'en',
  isFocused,
}) => {
  const maxChars = getAttributeValue(attributes.maximum_chars)
  const numbersOnly = isTrue(
    getAttributeValue(attributes.numbers_only, language)
  )

  const onNumberKeyDown = (event) => {
    const inputValue = event.target.value
    if (
      (numbersOnly && ['e', 'E'].includes(event.key)) ||
      (maxChars && event.key !== 'Backspace' && inputValue.length >= maxChars)
    ) {
      event.preventDefault()
    }

    if (maxChars && inputValue.length > maxChars) {
      event.target.value = ''
    }
  }

  const handleOnChange = (value) => {
    if (maxChars && value.length <= maxChars) {
      setAnswer(value)
    } else if (!maxChars) {
      setAnswer(value)
    }
  }

  useEffect(() => {
    setAnswer('')
  }, [maxChars, isFocused])

  return (
    <div className={'question-body-content'}>
      <div className="d-flex gap-2 align-items-center justify-content-center">
        <Form.Group className="flex-grow-1">
          <Form.Control
            maxLength={maxChars ? maxChars : Infinity}
            type={numbersOnly ? 'number' : 'text'}
            placeholder={'Enter your answer here.'}
            onKeyDown={onNumberKeyDown}
            data-testid="text-question-answer-input"
            defaultValue={answer}
            onChange={({ target: { value } }) => handleOnChange(value)}
          />
        </Form.Group>
      </div>
    </div>
  )
}
