import { useEffect, useState } from 'react'

import { QuestionTypeInfo } from '../../QuestionTypes'

import './TextQuestion.scss'
import { LongTextAnswer } from './LongTextAnswer'
import { ShortTextAnswer } from './ShortTextAnswer'
import { BrowserDetectionTextAnswer } from './BrowserDetectionTextAnswer'

export const TextQuestion = ({
  question: {
    questionThemeName,
    attributes: { form_field_text = {} } = {},
    attributes,
    answerExample,
  },
  isFocused,
  handleUpdate,
  language,
}) => {
  const [, setAnswer] = useState(form_field_text?.value)

  useEffect(() => {
    if (form_field_text?.value !== undefined) {
      setAnswer(form_field_text?.value)
    }
  }, [form_field_text.value])

  const handleAnswerUpdate = (answerExample) => {
    handleUpdate({ answerExample })
  }

  return (
    <div className={'question-body-content'}>
      {questionThemeName === QuestionTypeInfo.LONG_TEXT.theme && (
        <LongTextAnswer
          answer={answerExample}
          setAnswer={handleAnswerUpdate}
          attributes={attributes}
          isFocused={isFocused}
        />
      )}
      {questionThemeName === QuestionTypeInfo.SHORT_TEXT.theme && (
        <ShortTextAnswer
          answer={answerExample}
          setAnswer={handleAnswerUpdate}
          attributes={attributes}
          language={language}
          isFocused={isFocused}
        />
      )}
      {questionThemeName === QuestionTypeInfo.BROWSER_DETECTION.theme && (
        <BrowserDetectionTextAnswer attributes={attributes} />
      )}
    </div>
  )
}
