import { useCallback } from 'react'
import { debounce } from 'lodash'
import { useQuery } from '@tanstack/react-query'

import { queryClient } from 'query'

import { useBuffer, useQueryRetry, useSurveyService } from './'

const PATCH_DEBOUNCE_TIME = 1000

export const useSurvey = (id) => {
  const { surveyService } = useSurveyService()
  const { refetchInterval, handleRetry } = useQueryRetry({})

  let { data } = useQuery({
    queryKey: ['survey'],
    queryFn: async () => {
      return fetchSurvey(id)
    },
    staleTime: Infinity,
    refetchOnWindowFocus: 'always',
    refetchInterval,
    retry: handleRetry,
  })

  const { operationsBuffer } = useBuffer()

  const fetchSurvey = async (id) => {
    const currentSurveyId = data?.survey?.sid?.toString()
    const surveyRefetch = id?.toString() === currentSurveyId
    const isDemoMode = process.env.REACT_APP_DEMO_MODE === 'true'

    if ((!id && !isDemoMode) || (isDemoMode && currentSurveyId)) {
      return { survey: data.survey || {} }
    }

    // If the survey is not refetching then set the survey to be empty to trigger the loading state.
    if (!surveyRefetch) {
      queryClient.setQueryData(['survey'], {
        survey: {},
      })
    }

    if (
      process.env.REACT_APP_DEMO_MODE === 'true' ||
      process.env.STORYBOOK_DEV === 'true'
    ) {
      const data = await surveyService.getSurveyDetailDemo(id)
      data.survey = {
        ...operationsBuffer.applyOperations(data.survey),
      }
      queryClient.setQueryData(['survey'], {
        survey: {
          ...data.survey,
        },
      })

      return data
    } else {
      const data = await surveyService.getSurveyDetail(id).catch((error) => {
        throw new Error(error)
      })
      data.survey = {
        ...operationsBuffer.applyOperations(data.survey),
      }

      queryClient.setQueryData(['survey'], {
        survey: {
          ...data.survey,
        },
      })
      return data
    }
  }

  let { data: surveyList } = useQuery({
    queryKey: ['surveyList'],
    queryFn: async () => {
      if (process.env.REACT_APP_DEMO_MODE !== 'true') {
        return surveyService.getSurveyList()
      }

      return []
    },
    staleTime: Infinity,
    refetchOnWindowFocus: 'always',
    refetchInterval,
    retry: handleRetry,
  })

  const clearSurvey = () => {
    if (
      process.env.REACT_APP_DEMO_MODE === 'true' ||
      process.env.STORYBOOK_DEV === 'true'
    ) {
      return
    }

    queryClient.setQueryData(['survey'], {})
  }

  const updateSurvey = (updateData) => {
    queryClient.setQueryData(['survey'], {
      survey: {
        ...data.survey,
        ...updateData,
      },
    })
  }

  const surveyPatch = useCallback(
    debounce(
      async (
        operations,
        beforeCallback = () => {},
        thenCallback = () => {},
        finallyCallback = () => {},
        catchCallBack = () => {}
      ) => {
        if (!operations.length) {
          return
        }

        beforeCallback()
        return surveyService
          .patchSurvey(operations)
          .then(thenCallback)
          .finally(finallyCallback)
          .catch(catchCallBack)
      },
      PATCH_DEBOUNCE_TIME
    ),
    [surveyService.surveyId, surveyService.auth?.restHeaders?.Authorization]
  )

  return {
    survey: data?.survey || {},
    surveyList: surveyList?.surveys || [],
    update: updateSurvey,
    language: data?.survey?.language,
    surveyPatch,
    clearSurvey,
    fetchSurvey,
  }
}
