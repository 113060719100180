import { Droppable } from 'react-beautiful-dnd'

import { DragIcon } from 'components/icons'
import { ContentEditor } from 'components/UIComponents'

export const RankingAdvancedQuestionAnswersPlaceholder = ({
  answers,
  answersHeight = [],
}) => {
  return (
    <div>
      {answers.map((answer, index) => {
        return (
          <Droppable
            key={`ranked-advanced-placeholder${answer.qid}-${index}`}
            droppableId={`ranked-advanced-placeholder${answer.qid}-${index}`}
            index={index}
          >
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.draggableProps}>
                <div className="position-relative answer-item-placeholder p-1 ms-3 d-flex align-items-center">
                  <ContentEditor
                    value={index + 1}
                    placeholder="Answer option"
                    className="choice placeholder-content-editor"
                    style={{
                      minHeight: answersHeight[index],
                      outline: snapshot.isDraggingOver && '1px solid #14ae5c',
                    }}
                  />
                  <div
                    {...provided.dragHandleProps}
                    style={{
                      position: 'absolute',
                      right: '0',
                      transform: 'translate(-50%, -50%)',
                      height: '14px',
                      width: '14px',
                    }}
                    className="d-none"
                  >
                    <DragIcon />
                  </div>
                </div>
                <div className="d-none">{provided.placeholder}</div>
              </div>
            )}
          </Droppable>
        )
      })}
    </div>
  )
}
