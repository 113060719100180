import { useRef } from 'react'
import classNames from 'classnames'
import { Draggable } from 'react-beautiful-dnd'
import { Button, Form } from 'react-bootstrap'
import { PlusLg } from 'react-bootstrap-icons'

import { useAppState, useBuffer } from 'hooks'
import {
  LANGUAGE_CODES,
  RandomNumber,
  createBufferOperation,
  getQuestionExample,
  STATES,
  RemoveHTMLTagsInString,
  TOOLTIP_MESSAGES,
} from 'helpers'
import { DragAndDrop } from 'components/UIComponents'
import { TooltipContainer } from 'components/TooltipContainer/TooltipContainer'

import { QuestionTypeInfo } from '../QuestionTypeInfo'
import { MultipleChoiceSubquestion } from './MultipleChoiceSubquestion'

export const MultipleChoice = ({
  question,
  question: { subquestions = [], questionThemeName, attributes = {} } = {},
  handleUpdate = () => {},
  showCommentsInputs = false,
  isFocused = false,
  language,
}) => {
  const { addToBuffer } = useBuffer()
  const [isSurveyActive] = useAppState(STATES.IS_SURVEY_ACTIVE)
  const containersRef = useRef(null)

  const isButtonsTheme =
    questionThemeName === QuestionTypeInfo.MULTIPLE_CHOICE_BUTTONS.theme

  const handleUpdateSubQuestion = (newValue, index) => {
    const updatedSubquestions = [...subquestions]

    updatedSubquestions[index]['l10ns'][language].question =
      RemoveHTMLTagsInString(newValue)

    const operation = createBufferOperation(question.qid)
      .subquestion()
      .update([...updatedSubquestions])

    addToBuffer(operation)
    handleUpdate({ question })
  }

  const handleAddingSubquestions = () => {
    const updatedSubQuestions = [...subquestions]

    const newSubquestion = getQuestionExample({
      gid: question.gid,
      language: language || LANGUAGE_CODES.EN,
      sortOrder: updatedSubQuestions.length + 1,
      scaleId: question.scaleId,
      parentQid: question.qid,
      title: `SQ${RandomNumber(1, 999)}`,
    })

    updatedSubQuestions.push(newSubquestion)

    const operation = createBufferOperation(question.qid)
      .subquestion()
      .create([...updatedSubQuestions])

    addToBuffer(operation)
    handleUpdate({ subquestions: updatedSubQuestions })
  }

  const handleRemovingSubquestion = (subquestionId) => {
    const updatedSubquestions = (subquestions || []).filter(
      (subquestion) => subquestion.qid !== subquestionId
    )

    const operation = createBufferOperation(subquestionId)
      .subquestion()
      .delete()

    addToBuffer(operation)

    handleUpdate({ subquestions: updatedSubquestions })
  }

  const getSubquestionStyle = (draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle,
  })

  const handleOnDragEnd = (dropResult) => {
    // dropped outside the list
    if (!dropResult.destination) {
      return
    }

    const updatedSubquestions = reorderSubquestions(
      subquestions,
      dropResult.source.index,
      dropResult.destination.index
    )

    const operation = createBufferOperation(question.qid)
      .subquestion()
      .update([...updatedSubquestions])

    addToBuffer(operation)

    handleUpdate({ subquestions: updatedSubquestions })
  }

  const reorderSubquestions = (subQuestionsList, startIndex, endIndex) => {
    const updatedList = [...subQuestionsList]
    const [removed] = updatedList.splice(startIndex, 1)
    updatedList.splice(endIndex, 0, removed)

    return updatedList?.map((subQuestion, index) => {
      return {
        ...subQuestion,
        sortOrder: index + 1,
      }
    })
  }

  return (
    <div className="multiple-choice-question">
      <Form ref={containersRef}>
        <DragAndDrop
          onDragEnd={handleOnDragEnd}
          droppableId={'droppable'}
          className={classNames('sub-questions-list ms-1', {
            'd-flex flex-wrap': !isFocused && isButtonsTheme,
            'flex-column': isButtonsTheme,
          })}
        >
          {subquestions?.map((subQuestion, index) => (
            <Draggable
              key={`${subQuestion.qid}-multiple-choice`}
              draggableId={`${subQuestion.qid}-multiple-choice`}
              index={index}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  style={getSubquestionStyle(provided.draggableProps.style)}
                  className={classNames(
                    {
                      'focus-element': snapshot.isDragging,
                      'mb-1': isButtonsTheme && !isFocused,
                    },
                    'question-body-content'
                  )}
                >
                  <MultipleChoiceSubquestion
                    index={index}
                    provided={provided}
                    isFocused={isFocused}
                    subQuestion={subQuestion}
                    handleUpdateSubquestion={handleUpdateSubQuestion}
                    handleRemovingSubquestions={handleRemovingSubquestion}
                    showCommentsInputs={showCommentsInputs}
                    questionThemeName={questionThemeName}
                    attributes={attributes}
                    isSurveyActive={isSurveyActive}
                    language={language}
                  />
                </div>
              )}
            </Draggable>
          ))}
        </DragAndDrop>
      </Form>
      <div className="add-sub-question-button">
        <TooltipContainer
          tip={TOOLTIP_MESSAGES.ACTIVE_DISABLED}
          showTip={isSurveyActive}
        >
          <Button
            onClick={handleAddingSubquestions}
            variant={'outline'}
            className={classNames(
              'text-primary d-flex add-choice-button align-items-center gap-2 p-0 border-none',
              {
                'd-none disabled': !isFocused,
              }
            )}
            data-testid="add-sub-question-button"
            disabled={isSurveyActive}
          >
            <PlusLg /> Add subquestion
          </Button>
        </TooltipContainer>
      </div>
    </div>
  )
}
