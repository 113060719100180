import { Form } from 'react-bootstrap'
import { useEffect } from 'react'

import { getAttributeValue } from 'helpers'

import './TextQuestion.scss'

export const LongTextAnswer = ({
  attributes = {},
  answer,
  setAnswer,
  isFocused,
}) => {
  const maxChars = getAttributeValue(attributes.maximum_chars)

  useEffect(() => {
    setAnswer('')
  }, [maxChars, isFocused])

  return (
    <Form.Control
      onClick={(e) => {
        e.stopPropagation()
      }}
      placeholder="Enter your answer here."
      as="textarea"
      type="textarea"
      role="textarea"
      rows={4}
      maxLength={maxChars ? maxChars : Infinity}
      data-testid="text-question-answer-input"
      className="long-text-answer"
      defaultValue={answer}
      onChange={({ target: { value } }) => setAnswer(value)}
    />
  )
}
