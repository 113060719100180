import React from 'react'
import { RouterProvider, createHashRouter } from 'react-router-dom'
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import * as Sentry from '@sentry/react'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'themes/index.scss'
import 'remixicon/fonts/remixicon.css'
import routes from 'routes'
import sentry from 'sentry'
import { queryClient, persistOptions } from 'query'

sentry.init()
const routerConstructor = sentry.wrapCreateBrowserRouter(createHashRouter)
const router = routerConstructor(routes)

// config options https://posthog.com/docs/libraries/js#config
if (process.env.REACT_APP_POSTHOG_ENABLE === 'true') {
  posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_POSTHOG_HOST,
    autocapture: false, // does not affect page view
  })
}

function tempPreProdAuth() {
  if (
    process.env.NODE_ENV !== 'development' &&
    process.env.REACT_APP_DEMO_MODE &&
    !localStorage.getItem('authorized')
  ) {
    const text = window.prompt('Enter your password to continue:', '')
    if (text === 'limesurvey@123') {
      localStorage.setItem('authorized', true)
    } else {
      window.location.reload()
    }
  }
}

function App() {
  tempPreProdAuth()
  return (
    <Sentry.ErrorBoundary showDialog>
      <PostHogProvider client={posthog}>
        <ThemeProvider breakpoints={['lg', 'xl']}>
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={persistOptions}
          >
            <RouterProvider router={router} />
            {process.env.NODE_ENV !== 'production' && (
              <div className="footer small container-fluid d-flex flex-grow-1 flex-column justify-content-end text-end p-1">
                {process.env.REACT_APP_RELEASE}
              </div>
            )}
            <ReactQueryDevtools initialIsOpen={false} />
          </PersistQueryClientProvider>
        </ThemeProvider>
      </PostHogProvider>
    </Sentry.ErrorBoundary>
  )
}

export default sentry.withProfiler(App)
