import {
  COLUMN_WIDTH_OPTIONS,
  LABEL_WRAPPER_WIDTH_OPTIONS,
  ORDER_OPTIONS,
  VISUALIZATION_OPTIONS,
  USE_SLIDER_LAYOUT_OPTIONS,
  DISPLAY_BUTTON_TYPE_OPTIONS,
} from 'helpers'
import {
  Input,
  NumericInput,
  Select,
  ToggleButtons,
} from 'components/UIComponents'

import { ImageAttribute } from './ImageAttribute'

export const DISPLAY_ATTRIBUTES = {
  IMAGE_SETTINGS: {
    component: ImageAttribute,
    attributePath: 'attributes.image',
    props: {},
    returnValues: ['image', 'imageAlign', 'imageBrightness'],
  },
  PAGE_BREAK_IN_PRINTABLE_VIEW: {
    component: ToggleButtons,
    attributePath: 'attributes.page_break',
    props: {
      id: 'insert-page-break-in-printable-view',
      labelText: 'Page break in printable view',
      toggleOptions: [
        { name: 'Yes', value: '1' },
        { name: 'No', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  INPUT_ON_DEMAND: {
    component: ToggleButtons,
    attributePath: 'attributes.input_on_demand',
    props: {
      labelText: 'Input on demand',
      id: 'input-on-demand',
      toggleOptions: [
        { name: 'On', value: '1' },
        { name: 'Off', value: '0' },
      ],
      defaultValue: '0',
    },
    hidden: !process.env.REACT_APP_DEV_MODE,
  },
  USE_SLIDER_LAYOUT: {
    component: Select,
    attributePath: 'attributes.slider_rating',
    props: {
      dataTestId: 'use_slider_layout',
      labelText: 'Use Slider Layout',
      options: USE_SLIDER_LAYOUT_OPTIONS,
    },
  },
  DISPLAY_TYPE: {
    component: ToggleButtons,
    attributePath: 'attributes.display_type',
    props: {
      labelText: 'Display type',
      id: 'display-type',
      toggleOptions: Object.values(DISPLAY_BUTTON_TYPE_OPTIONS),
      defaultValue: '0',
    },
  },
  MAXIMUM_DATE: {
    component: Input,
    attributePath: 'attributes.date_max',
    props: {
      type: 'date',
      labelText: 'Max date',
      id: 'maximum-date',
      inputClass: 'selected',
      showClassWhenValue: true,
    },
  },
  MINIMUM_DATE: {
    component: Input,
    attributePath: 'attributes.date_min',
    props: {
      type: 'date',
      labelText: 'Min date',
      id: 'minimum-date',
      inputClass: 'selected',
      showClassWhenValue: true,
    },
  },
  MONTH_DISPLAY_STYLE: {
    component: ToggleButtons,
    attributePath: 'attributes.dropdown_dates_month_style',
    props: {
      labelText: 'Month Display Style',
      id: 'month-display-style',
      toggleOptions: [
        { name: 'Short', value: '0' },
        { name: 'Full', value: '1' },
        { name: 'Numbers', value: '2' },
      ],
      defaultValue: '0',
    },
  },
  DISPLAY_DROPDOWN_BOXES: {
    component: ToggleButtons,
    attributePath: 'attributes.dropdown_dates',
    props: {
      labelText: 'Display dropdown boxes',
      id: 'display-dropdown-boxes',
      toggleOptions: [
        { name: 'On', value: '1' },
        { name: 'Off', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  SHOW_PLATFORM_INFORMATION: {
    component: ToggleButtons,
    attributePath: 'attributes.add_platform_info',
    props: {
      labelText: 'Show Platform Information',
      id: 'show-platform-information',
      toggleOptions: [
        { name: 'Yes', value: 'yes' },
        { name: 'No', value: 'no' },
      ],
      defaultValue: 'no',
    },
  },
  MINIMUM_VALUE: {
    component: Input,
    attributePath: 'attributes.multiflexible_min',
    props: {
      labelText: 'Minimum value',
      id: 'minimum-value',
    },
  },
  MAXIMUM_VALUE: {
    component: Input,
    attributePath: 'attributes.multiflexible_max',
    props: {
      labelText: 'Maximum value',
      id: 'maximum-value',
    },
  },
  ANSWER_OPTIONS_ORDER: {
    component: Select,
    attributePath: 'attributes.answer_order',
    props: {
      dataTestId: 'answer-options-order',
      labelText: 'Answer options order',
      options: ORDER_OPTIONS,
    },
  },
  VISUALIZATION: {
    component: Select,
    attributePath: 'attributes.visualize',
    props: {
      labelText: 'Visualization',
      dataTestId: 'visualization',
      options: VISUALIZATION_OPTIONS,
    },
    hidden: !process.env.REACT_APP_DEV_MODE,
  },
  SAME_HEIGHT_FOR_ALL_ANSWER_OPTIONS: {
    component: ToggleButtons,
    attributePath: 'attributes.samechoiceheight',
    props: {
      id: 'same-height-for-all-answer-options',
      labelText: 'Same height for all answer options',
      toggleOptions: [
        { name: 'Yes', value: '1' },
        { name: 'No', value: '0' },
      ],
      defaultValue: '1',
    },
  },
  SAME_HEIGHT_FOR_LIST: {
    component: ToggleButtons,
    attributePath: 'attributes.samelistheight',
    props: {
      id: 'same-height-for-lists',
      labelText: 'Same height for lists',
      toggleOptions: [
        { name: 'Yes', value: '1' },
        { name: 'No', value: '0' },
      ],
      defaultValue: '1',
    },
  },
  ANSWER_PREFIX: {
    component: Input,
    attributePath: 'attributes.prefix',
    props: {
      dataTestId: 'answer-prefix',
      labelText: 'Answer prefix',
    },
  },
  ANSWER_SUFFIX: {
    component: Input,
    attributePath: 'attributes.suffix',
    props: {
      dataTestId: 'answer-suffix',
      labelText: 'Answer suffix',
    },
  },
  CHOICE_COLUMN_WIDTH: {
    component: Select,
    attributePath: 'attributes.choice_input_columns',
    props: {
      dataTestId: 'choice-column-width',
      labelText: 'Choice column width',
      options: COLUMN_WIDTH_OPTIONS,
    },
  },
  REVERSE_ANSWER_ORDER: {
    component: ToggleButtons,
    attributePath: 'attributes.reverse',
    props: {
      id: 'reverse-answer-order',
      labelText: 'Reverse answer order',
      toggleOptions: [
        { name: 'On', value: '1' },
        { name: 'Off', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  SHOW_JAVASCRIPT_ALERT: {
    component: ToggleButtons,
    attributePath: 'attributes.showpopups',
    props: {
      id: 'show-javascript-alert',
      labelText: 'Show javascript alert',
      toggleOptions: [
        { name: 'On', value: '1' },
        { name: 'Off', value: '0' },
      ],
      defaultValue: '1',
    },
  },
  SHOW_HANDLE: {
    component: ToggleButtons,
    attributePath: 'attributes.show_handle',
    props: {
      id: 'show-handle',
      name: 'show-handle',
      labelText: 'Show handle',
      toggleOptions: [
        { name: 'No', value: 'no' },
        { name: 'Yes', value: 'yes' },
      ],
      defaultValue: 'no',
    },
  },
  SHOW_NUMBER: {
    component: ToggleButtons,
    attributePath: 'attributes.show_number',
    props: {
      id: 'show-number',
      name: 'show-number',
      labelText: 'Show number',
      toggleOptions: [
        { name: 'No', value: 'no' },
        { name: 'Yes', value: 'yes' },
      ],
      defaultValue: 'no',
    },
  },
  WITHOUT_REORDER: {
    component: ToggleButtons,
    attributePath: 'attributes.only_pull',
    props: {
      id: 'without-reorder',
      name: 'without-reorder',
      labelText: 'Without reorder',
      toggleOptions: [
        { name: 'No', value: 'no' },
        { name: 'Yes', value: 'yes' },
      ],
      defaultValue: 'no',
    },
  },

  // todo: check this
  FIX_WIDTH: {
    component: NumericInput,
    attributePath: 'attributes.fix_width',
    props: {
      dataTestId: 'fix-width',
      labelText: 'Fix width',
    },
  },
  // todo: check this
  FIX_HEIGHT: {
    component: NumericInput,
    attributePath: 'attributes.fix_height',
    props: {
      dataTestId: 'fix-height',
      labelText: 'Fix height',
    },
  },
  // todo: check this
  CROP_OR_RESIZE: {
    component: ToggleButtons,
    attributePath: 'attributes.crop_or_resize',
    props: {
      id: 'crop-or-resize',
      labelText: 'Crop or resize',
      toggleOptions: [
        { name: 'Crop', value: 'crop' },
        { name: 'Resize', value: 'resize' },
      ],
      defaultValue: 'resize',
    },
  },
  // todo: check this
  KEEP_ASPECT_RATIO: {
    component: ToggleButtons,
    attributePath: 'attributes.keep_aspect',
    props: {
      id: 'keep-aspect-ratio',
      labelText: 'Keep aspect ratio',
      toggleOptions: [
        { name: 'Yes', value: 'yes' },
        { name: 'No', value: 'no' },
      ],
      defaultValue: 'no',
    },
  },
  // todo: check this
  HORIZONTAL_SCROLL: {
    component: ToggleButtons,
    attributePath: 'attributes.horizontal_scroll',
    props: {
      id: 'horizontal-scroll',
      labelText: 'Horizontal scroll',
      toggleOptions: [
        { name: 'Yes', value: 'yes' },
        { name: 'No', value: 'no' },
      ],
      defaultValue: 'no',
    },
  },
  CATEGORY_SERARATOR: {
    component: Input,
    attributePath: 'attributes.category_separator',
    props: {
      id: 'category-separator',
      labelText: 'Category separator',
    },
  },
  PREFIX_FOR_LIST_ITEMS: {
    component: ToggleButtons,
    attributePath: 'attributes.dropdown_prefix',
    props: {
      id: 'prefix-for-list-items',
      labelText: 'Prefix for list items',
      toggleOptions: [
        { name: 'None', value: '0' },
        { name: 'Order - like 3)', value: '1' },
      ],
      defaultValue: '0',
    },
  },
  HEIGHT_OF_DROPDOWN: {
    component: Input,
    attributePath: 'attributes.dropdown_size',
    props: {
      id: 'category-separator',
      labelText: 'Height of dropdown',
      dataTestId: 'height-of-dropdown',
    },
  },
  LABEL_FOR_OTHER_OPTIONS: {
    component: Input,
    attributePath: 'attributes.other_replace_text',
    languageBased: true,
    props: {
      id: 'category-separator',
      labelText: "Label for 'Other:' option",
      dataTestId: 'label-for-other-option',
    },
  },
  POSITION_FOR_OTHER_OPTION: {
    component: Select,
    attributePath: 'attributes.other_position',
    props: {
      labelText: "Position for 'Other:' option",
      dataTestId: 'position-for-other-option',
      options: [
        {
          label: 'At beginning',
          value: 'beginning',
        },

        {
          label: 'At end',
          value: 'end',
        },
        {
          label: 'After specific answer option',
          value: 'specific',
        },
      ],
    },
  },
  SUBQUESTION_TITLE: {
    component: Input,
    attributePath: 'attributes.other_position_code',
    props: {
      dataTestId: 'sub-question-title',
      labelText: "Subquestion title for 'After specific subquestion'",
    },
  },
  RANDOM_ORDER: {
    component: Select,
    attributePath: 'attributes.random_order',
    props: {
      id: 'random-order',
      labelText: 'Answer order',
      options: [
        { label: 'Normal', value: '0' },
        { label: 'Random', value: '1' },
      ],
      defaultValue: '0',
    },
  },
  ANSWER_CODE: {
    component: Input,
    attributePath: 'attributes.other_position_code',
    props: {
      dataTestId: 'answer-code',
      labelText: "Answer code for 'After specific answer option'",
    },
  },
  SUBQUESTION_WIDTH: {
    component: NumericInput,
    attributePath: 'attributes.answer_width',
    props: {
      dataTestId: 'sub-question-width',
      labelText: '(Sub-)question width',
      max: 100,
      min: 0,
    },
  },
  REPEAT_HEADERS: {
    component: NumericInput,
    attributePath: 'attributes.repeat_headings',
    props: {
      dataTestId: 'repeat-headers',
      labelText: 'Repeat headers',
    },
  },
  GET_ORDER_FROM_PREVIEW_QUESTION: {
    component: Input,
    attributePath: 'attributes.parent_order',
    props: {
      dataTestId: 'get-order-from-preview-question',
      labelText: 'Get order from previous question',
    },
  },
  HIDE_TIP: {
    component: ToggleButtons,
    attributePath: 'attributes.hide_tip',
    props: {
      id: 'hide-tip',
      labelText: 'Hide tip',
      toggleOptions: [
        { name: 'Yes', value: '1' },
        { name: 'No', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  SCALE_HEADER_A: {
    component: Input,
    attributeName: 'dualscale_headerA',
    attributePath: 'attributes.dualscale_headerA',
    props: {
      dataTestId: 'header-a-attribute-input',
      labelText: 'Header A',
    },
  },
  SCALE_HEADER_B: {
    component: Input,
    attributeName: 'dualscale_headerB',
    attributePath: 'attributes.dualscale_headerB',
    props: {
      dataTestId: 'header-B-attribute-input',
      labelText: 'Header B',
    },
  },
  ALWAYS_HIDE_THIS_QUESTION: {
    component: ToggleButtons,
    attributePath: 'attributes.hidden',
    props: {
      id: 'always-hide-this-question',
      labelText: 'Hide this question',
      toggleOptions: [
        { name: 'Yes', value: '1' },
        { name: 'No', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  CSS_CLASSES: {
    component: Input,
    attributePath: 'attributes.cssclass',
    props: {
      dataTestId: 'css-classes-attribute-input',
      labelText: 'CSS classes',
    },
  },
  CONDITION_HELP_FOR_PRINTABLE_SURVEY: {
    component: Input,
    attributePath: 'attributes.printable_help',
    languageBased: true,
    props: {
      dataTestId: 'condition-help-for-printable-survey',
      labelText: 'Condition help for printable survey',
    },
  },
  // todo: check this
  TEXT_INPUT_WIDTH: {
    component: Select,
    attributePath: 'attributes.text_input_columns',
    props: {
      dataTestId: 'text-input-width',
      labelText: 'Text input box width',
      options: COLUMN_WIDTH_OPTIONS,
    },
  },
  TEXT_INPUT_BOX_SIZE: {
    component: NumericInput,
    attributePath: 'attributes.input_size',
    props: {
      dataTestId: 'text-input-box-size',
      labelText: 'Text input box size',
    },
  },
  DISPLAY_ROWS: {
    component: NumericInput,
    attributePath: 'attributes.display_rows',
    props: {
      dataTestId: 'display-rows',
      labelText: 'Display rows',
    },
  },
  LABEL_WRAPPER_WIDTH: {
    component: Select,
    attributePath: 'attributes.label_input_columns',
    props: {
      labelText: 'Label wrapper width',
      options: LABEL_WRAPPER_WIDTH_OPTIONS,
    },
  },
  ANSWER_COLUMN_WIDTH: {
    component: Input,
    attributePath: 'attributes.answer_column_width',
    props: {
      dataTestId: 'answer-column-width',
      labelText: 'Answer column width',
    },
  },
  USE_DROPDOWN_PRESENTATION: {
    component: ToggleButtons,
    attributePath: 'attributes.use_dropdown',
    props: {
      id: 'use-dropdown-presentation',
      labelText: 'Use dropdown presentation',
      toggleOptions: [
        { name: 'On', value: '1' },
        { name: 'Off', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  DISPLAY_COLUMNS: {
    component: NumericInput,
    attributePath: 'attributes.display_columns',
    props: {
      dataTestId: 'display-columns',
      labelText: 'Display columns',
    },
  },
  // todo: check this
  TEXT_INPUTS: {
    component: ToggleButtons,
    attributePath: 'attributes.input_boxes',
    props: {
      id: 'text-inputs',
      labelText: 'Text inputs',
      toggleOptions: [
        { name: 'On', value: '1' },
        { name: 'Off', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  // todo: check this
  STEP_VALUE: {
    component: NumericInput,
    attributePath: 'attributes.multiflexible_step',
    props: {
      dataTestId: 'step-value',
      labelText: 'Step value',
    },
  },
  // todo: check this
  PLACEHOLDER_ANSWER: {
    component: Input,
    attributePath: 'attributes.placeholder',
    props: {
      dataTestId: 'placeholder-answer',
      labelText: 'Placeholder answer',
    },
  },
  CHECKBOX_LAYOUT: {
    component: ToggleButtons,
    attributePath: 'attributes.multiflexible_checkbox',
    props: {
      id: 'checkbox-layout',
      labelText: 'Checkbox layout',
      name: 'checkbox-layout',
      toggleOptions: [
        { name: 'On', value: '1' },
        { name: 'Off', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  DROPDOWN_PREFIX_SUFFIX: {
    component: Input,
    attributePath: 'attributes.dropdown_prepostfix',
    props: {
      dataTestId: 'dropdown-prefix-suffix',
      labelText: 'Dropdown prefix/suffix',
    },
  },
  DROPDOWN_SEPARATOR: {
    component: Input,
    attributePath: 'attributes.dropdown_separators',
    props: {
      dataTestId: 'dropdown-separator',
      labelText: 'Dropdown separator',
    },
  },
}
