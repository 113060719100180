import axios from 'axios'

export class RestClient {
  constructor(baseUrl, restHeaders) {
    this.restHeaders = restHeaders
    this.client = axios.create({
      baseURL: baseUrl,
      headers: restHeaders,
    })
  }

  async get(endpoint, additionalHeaders = {}) {
    try {
      const response = await this.client.get(endpoint, {
        headers: { ...this.restHeaders, ...additionalHeaders },
      })
      return response.data
    } catch (error) {
      this.handleError(error)
    }
  }

  async post(endpoint, data, additionalHeaders = {}) {
    try {
      const response = await this.client.post(endpoint, data, {
        headers: { ...this.restHeaders, ...additionalHeaders },
      })
      return response.data
    } catch (error) {
      this.handleError(error)
    }
  }

  async patch(endpoint, data, additionalHeaders = {}) {
    try {
      const response = await this.client.patch(endpoint, data, {
        headers: { ...this.restHeaders, ...additionalHeaders },
      })
      return response.data
    } catch (error) {
      this.handleError(error)
    }
  }

  async put(endpoint, data, additionalHeaders = {}) {
    try {
      const response = await this.client.put(endpoint, data, {
        headers: { ...this.restHeaders, ...additionalHeaders },
      })
      return response.data
    } catch (error) {
      this.handleError(error)
    }
  }

  async delete(endpoint, additionalHeaders = {}) {
    try {
      const response = await this.client.delete(endpoint, {
        headers: { ...this.restHeaders, ...additionalHeaders },
      })
      return response.data
    } catch (error) {
      this.handleError(error)
    }
  }

  handleError(error) {
    throw error
  }
}
