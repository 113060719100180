import React from 'react'
export const LanguageIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.15665 3.30192C2.30667 4.18411 1.78631 5.33202 1.68303 6.5527C1.57974 7.77337 1.89983 8.99239 2.58948 10.0049C3.27914 11.0173 4.29632 11.7615 5.47005 12.1123C6.64379 12.4631 7.90251 12.3992 9.03465 11.9313C9.11131 11.4313 8.94331 10.9513 8.87465 10.7833C8.72131 10.4099 8.21598 9.77192 7.37265 8.88725C7.14731 8.65059 7.16198 8.46859 7.24265 7.92925L7.25131 7.86859C7.30598 7.49925 7.39798 7.28059 8.64131 7.08325C9.27331 6.98325 9.43931 7.23525 9.66931 7.58525L9.74665 7.69992C9.96531 8.01992 10.1273 8.09325 10.372 8.20392C10.482 8.25392 10.6186 8.31725 10.802 8.42059C11.2366 8.66925 11.2366 8.94992 11.2366 9.56459V9.63459C11.2366 9.89525 11.2113 10.1246 11.1713 10.3239C11.6829 9.68187 12.0394 8.93041 12.213 8.12805C12.3867 7.32568 12.3728 6.49407 12.1725 5.69794C11.9722 4.90181 11.5909 4.16266 11.0582 3.53801C10.5255 2.91337 9.85582 2.42009 9.10131 2.09659C8.73265 2.34525 8.22665 2.69792 8.04998 2.93992C7.95998 3.06325 7.83198 3.69459 7.41665 3.74659C7.30865 3.75992 7.16265 3.75059 7.00798 3.74059C6.59331 3.71392 6.02665 3.67725 5.84531 4.16992C5.72998 4.48192 5.70998 5.32992 6.08265 5.76992C6.14265 5.83992 6.15398 5.96992 6.11331 6.11592C6.05998 6.30725 5.95265 6.42392 5.91865 6.44792C5.85465 6.41059 5.72665 6.26192 5.63931 6.16125C5.43065 5.91792 5.16931 5.61459 4.83198 5.52125C4.70931 5.48725 4.57465 5.45925 4.44331 5.43125C4.07731 5.35459 3.66331 5.26725 3.56665 5.06192C3.49598 4.91125 3.49665 4.70392 3.49665 4.48525C3.49665 4.20725 3.49665 3.89325 3.36065 3.58859C3.31337 3.47986 3.24388 3.38221 3.15665 3.30192ZM6.99998 13.6666C3.31798 13.6666 0.333313 10.6819 0.333313 6.99992C0.333313 3.31792 3.31798 0.333252 6.99998 0.333252C10.682 0.333252 13.6666 3.31792 13.6666 6.99992C13.6666 10.6819 10.682 13.6666 6.99998 13.6666Z"
        fill="#6E748C"
      />
    </svg>
  )
}
