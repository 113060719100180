import classNames from 'classnames'

import { CloseCircleFillIcon, DragIcon } from 'components/icons'
import { L10ns } from 'helpers'

import { SingleChoiceRadioAnswer } from './SingleChoiceRadioAnswer'
import { QuestionTypeInfo } from '../QuestionTypeInfo'
import { SingleChoiceImageAnswer } from './SingleChoiceImageAnswer'
import { SingleChoiceDropdownAnswer } from './SingleChoiceDropdownAnswer'
import { SingleChoiceButtonAnswer } from './SingleChoiceButtonAnswer'
import { ContentEditor } from 'components/UIComponents'

export const SingleChoiceAnswer = ({
  answer,
  question: { qid, questionThemeName, answers = [] } = {},
  index,
  handleUpdateAnswer,
  handleFocus = () => {},
  handleBlur = () => {},
  handleRemovingAnswers,
  provided = {},
  isFocused = false,
  isNoAnswer = false,
  showDeleteIcon = true,
  surveyLanguage,
}) => {
  const onFocus = () => {
    handleFocus()
  }

  const onBlur = () => {
    handleBlur()
  }

  const showImageAnswer =
    questionThemeName === QuestionTypeInfo.SINGLE_CHOICE_LIST_IMAGE_SELECT.theme
  const showButtonAnswer =
    !isFocused &&
    questionThemeName === QuestionTypeInfo.SINGLE_CHOICE_BUTTONS.theme
  const showDropDownAnswer =
    !isFocused &&
    questionThemeName === QuestionTypeInfo.SINGLE_CHOICE_DROPDOWN.theme
  const showRadioAnswer =
    !isFocused && !showImageAnswer && !showDropDownAnswer && !showButtonAnswer

  const showContentEditable = isFocused && !showImageAnswer

  const value = L10ns({
    l10ns: answer.l10ns,
    prop: 'answer',
    language: surveyLanguage,
  })

  return (
    <div
      className={classNames(
        'answer-item d-flex align-items-center position-relative remove-option-button-parent',
        {
          'py-2 mb-2 ': !showButtonAnswer,
          'mb-1': showButtonAnswer && !isFocused,
        }
      )}
    >
      {isFocused ? (
        <div
          {...provided.dragHandleProps}
          className={classNames({
            'disabled opacity-0': !provided.dragHandleProps,
          })}
          style={{ height: '26px' }}
        >
          <DragIcon className="text-secondary fill-current ms-2 me-2" />
        </div>
      ) : (
        <span {...provided.dragHandleProps}></span>
      )}
      <div className="d-flex gap-2 align-items-center w-100">
        <>
          {showRadioAnswer && (
            <SingleChoiceRadioAnswer
              answer={answer}
              qid={qid}
              index={index}
              handleBlur={handleBlur}
              handleFocus={handleFocus}
              surveyLanguage={surveyLanguage}
              value={value}
              isNoAnswer
            />
          )}
          {showButtonAnswer && <SingleChoiceButtonAnswer value={value} />}
          {showImageAnswer && (
            <SingleChoiceImageAnswer
              answer={answer}
              qid={qid}
              isFocused={isFocused}
              isNoAnswer={isNoAnswer}
              value={value}
            />
          )}
          {showDropDownAnswer && (
            <SingleChoiceDropdownAnswer
              answers={answers}
              value={value}
              surveyLanguage={surveyLanguage}
            />
          )}
          {showContentEditable && (
            <ContentEditor
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder="Answer option"
              className="text-secondary choice"
              value={value}
              disabled={isNoAnswer}
              update={handleUpdateAnswer}
            />
          )}
        </>
      </div>
      {showDeleteIcon && (
        <div
          style={{ left: '-20px' }}
          className="cursor-pointer remove-option-button position-absolute"
          onClick={() => handleRemovingAnswers(answer.aid)}
        >
          <CloseCircleFillIcon
            className={classNames('text-danger fill-current', {
              'd-none disabled': !isFocused,
            })}
          />
        </div>
      )}
    </div>
  )
}
