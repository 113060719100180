import { useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'

import { queryClient } from 'query'
import { OperationsBuffer, STATES } from 'helpers'
import { useErrors } from './useErrors'

export const useBuffer = () => {
  let { data } = useQuery({
    queryKey: [STATES.BUFFER],
    queryFn: () => [],
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  let { data: hash = '' } = useQuery({
    queryKey: [STATES.BUFFER_HASH],
    queryFn: () => '',
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  const { removeError } = useErrors()

  const setBuffer = useCallback((data, hash) => {
    queryClient.setQueryData([STATES.BUFFER], data)
    queryClient.setQueryData([STATES.BUFFER_HASH], hash)
  }, [])

  const clearBuffer = useCallback(() => {
    queryClient.setQueryData([STATES.BUFFER], [])
  }, [])

  const addToBuffer = (operation, updateCurrentOperation = true) => {
    if (
      // process.env.REACT_APP_DEMO_MODE === 'true' ||
      // Storybook dev context
      process.env.STORYBOOK_DEV
    ) {
      return
    }

    const operationBuffer = new OperationsBuffer(
      queryClient.getQueryData([STATES.BUFFER])
    )
    const survey = queryClient.getQueryData([STATES.SURVEY])?.survey

    if (!survey) {
      return
    }

    operationBuffer.addOperation(operation, updateCurrentOperation)
    operationBuffer.setBufferHash(Math.random())

    setBuffer(operationBuffer.getOperations(), operationBuffer.bufferHash)
    removeError(operation.id, operation.entity)
  }

  return {
    operationsBuffer: new OperationsBuffer(data, hash),
    addToBuffer,
    clearBuffer,
    setBuffer,
  }
}
