import { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

import { useAppState } from 'hooks'
import { dayJs, STATES, TOOLTIP_MESSAGES } from 'helpers'

import { TooltipContainer } from '../../TooltipContainer/TooltipContainer'

export const DateTimePickerComponent = ({
  labelText,
  value,
  update = () => {},
  minDate = dayJs('2000-04-17T15:30'),
  maxDate = dayJs('2050-04-17T15:30'),
  noPermissionDisabled = false,
}) => {
  const [didMount, setDidMount] = useState(false)
  const [error, setError] = useState(null)
  const [dateTime, setDateTime] = useState(dayJs(value))
  const [hasSurveyUpdatePermission] = useAppState(
    STATES.HAS_SURVEY_UPDATE_PERMISSION
  )
  const disabled = noPermissionDisabled && !hasSurveyUpdatePermission
  const [siteSettings] = useAppState(STATES.SITE_SETTINGS)
  const timezone = siteSettings?.timezone ? siteSettings.timezone : null

  const errorMessage = useMemo(() => {
    switch (error) {
      case 'maxDate': {
        return `Please select a date before ${maxDate ? maxDate : 2050} year`
      }
      case 'minDate': {
        return `Please select a date after ${minDate ? minDate : 2000} year`
      }
      case 'invalidDate': {
        return 'Your date is not valid'
      }
      default: {
        return ''
      }
    }
  }, [error, maxDate, minDate])

  useEffect(() => {
    setDidMount(true)
  }, [])

  return (
    <div data-testid="data-time-picker">
      {labelText && <Form.Label>{labelText}</Form.Label>}
      <div>
        <TooltipContainer
          tip={TOOLTIP_MESSAGES.NO_PERMISSION}
          showTip={disabled}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
              <DateTimePicker
                ampm={false}
                minDate={dayJs(minDate)}
                maxDate={dayJs(maxDate)}
                value={dateTime}
                timezone={timezone}
                slotProps={{
                  textField: {
                    helperText: errorMessage,
                  },
                }}
                onChange={(value) => setDateTime(value)}
                onClose={() => update(dateTime.toJSON())}
                onError={(newError) => {
                  if (!didMount) {
                    return
                  }

                  setError(newError)
                }}
                disabled={disabled}
              />
            </DemoContainer>
          </LocalizationProvider>
        </TooltipContainer>
      </div>
    </div>
  )
}
DateTimePickerComponent.propTypes = {
  labelText: PropTypes.string,
}
