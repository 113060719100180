import { Select, ToggleButtons } from 'components/UIComponents'

export const THEME_ATTRIBUTES = {
  BUTTON_SIZE: {
    component: Select,
    attributePath: 'attributes.button_size',
    props: {
      labelText: 'Button size',
      id: 'button-size',
      options: [
        { label: 'Default', value: 'default' },
        { label: 'Large', value: 'lg' },
        { label: 'Small', value: 'sm' },
        { label: 'Extra small', value: 'xs' },
      ],
    },
  },
  MAXIMUM_NUMBER_OF_BUTTONS_IN_A_ROW: {
    component: Select,
    attributePath: 'attributes.max_buttons_row',
    props: {
      id: 'maximum-number-of-buttons-in-a-row',
      labelText: 'Maximum number of buttons in a row',
      options: [
        { label: 'Default', value: 'default' },
        { label: '1', value: 'col-md-12' },
        { label: '2', value: 'col-md-6' },
        { label: '3', value: 'col-md-4' },
        { label: '4', value: 'col-md-3' },
        { label: '6', value: 'col-md-2' },
        { label: '12', value: 'col-md-1' },
      ],
    },
  },
  ALLOW_SEARCHING_DROPDOWN: {
    component: ToggleButtons,
    attributePath: 'attributes.show_search',
    props: {
      labelText: 'Allow searching of dropdown',
      id: 'search-of-dropdown',
      toggleOptions: [
        { name: 'Yes', value: true },
        { name: 'No', value: false },
      ],
      defaultValue: false,
    },
  },
  DISPLAY_TICK_SELECTED_ITEM: {
    component: ToggleButtons,
    attributePath: 'attributes.show_tick',
    props: {
      labelText: 'Display tick on selected item',
      id: 'display-tick',
      toggleOptions: [
        { name: 'Yes', value: true },
        { name: 'No', value: false },
      ],
      defaultValue: false,
    },
  },
  WIDTH_DROPDOWN_SELECTED_ENTRY: {
    component: Select,
    attributePath: 'attributes.width_entry',
    props: {
      labelText: 'Width of dropdown',
      id: 'width-dropdown-selected-entry',
      options: [
        { label: 'Current selected entry', value: false },
        { label: 'Longest entry', value: true },
      ],
      value: false,
    },
  },
}
