import { LANGUAGE_CODES, NEW_OBJECT_ID_PREFIX } from 'helpers'
import { RandomNumber } from './RandomNumber'

export const getQuestionExample = ({
  gid,
  questionThemeName = 'longfreetext',
  type = 'T',
  sortOrder = 0,
  scaleId = 0,
  language = LANGUAGE_CODES.EN,
  parentQid,
  title,
}) => {
  const qid = `${NEW_OBJECT_ID_PREFIX}${RandomNumber()}`

  return {
    qid,
    gid,
    type,
    scaleId,
    sortOrder,
    questionThemeName,
    parentQid,
    tempId: qid,
    sid: 596477,
    title: title ? title : `SQ${RandomNumber()}`,
    preg: null,
    other: false,
    mandatory: null,
    encrypted: false,
    moduleName: null,
    sameDefault: null,
    relevance: '1',
    sameScript: null,
    l10ns: {
      [language]: {
        qid,
        question: '',
        language: [language],
      },
    },
    attributes: [],
    answers: [],
  }
}
