import { useParams } from 'react-router-dom'

import { SurveyService } from 'services'
import { apiUrl } from 'helpers'

import useAuth from './useAuth'

export const useSurveyService = () => {
  const auth = useAuth()
  const params = useParams()

  return {
    surveyService: new SurveyService(auth, params.id, apiUrl()),
  }
}
