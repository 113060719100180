import { useAppState, useSurvey, useBuffer } from 'hooks'

import {
  LanguageOptions,
  themeOptions,
  STATES,
  createBufferOperation,
  formatOptions,
  Entities,
} from 'helpers'

import {
  surveyGeneralAttributes,
  surveyPresentationAttributes,
  surveyPrivacyPolicyAttributes,
  surveyPublicationAccessAttributes,
  surveyParticipantsAttributes,
  surveyNotificationsDataAttributes,
} from './attributes'
import { AdvancedOptionsSettings } from './AdvancedOptions'

const surveySettingsAttributes = {
  general: surveyGeneralAttributes,
  presentation: surveyPresentationAttributes,
  privacyPolicy: surveyPrivacyPolicyAttributes,
  publicationAccess: surveyPublicationAccessAttributes,
  notificationsData: surveyNotificationsDataAttributes,
  participants: surveyParticipantsAttributes,
}

export const SurveySettings = ({ id }) => {
  const [userSettings, setUserSettings] = useAppState(STATES.USER_SETTINGS, {})
  const { survey, update, language } = useSurvey(id)

  const { addToBuffer } = useBuffer()
  const languageOptionsAsArray = survey.languages?.map((language) => ({
    value: language,
    label: LanguageOptions?.[language]?.label,
  }))

  const themeOptionsArray = process.env.REACT_APP_DEV_MODE
    ? [
        {
          label: `Inherit [ ${survey.templateInherited} ]`,
          value: 'inherit',
        },
        ...themeOptions,
      ]
    : themeOptions

  const formatOptionsArray = process.env.REACT_APP_DEV_MODE
    ? [
        {
          label: `Inherit [ ${
            formatOptions.find(
              (option) => option.value === survey.formatInherited
            )?.label
          } ]`,
          value: 'I',
        },
        ...formatOptions,
      ]
    : formatOptions

  const [clickedQuestionSettings] = useAppState(
    STATES.CLICKED_QUESTION_SETTINGS,
    {
      label: 'General',
      value: 'general',
    }
  )

  // this is used for getting the value of object property of survey
  // for example) survey: { defaultLanguage: {policyMessage:"..."} }
  const reduceValue = (obj, keys) => {
    return keys.reduce(
      (acc, key) => (acc && acc[key] !== 'undefined' ? acc[key] : undefined),
      obj
    )
  }

  const getValue = (survey, setting) => {
    if (setting?.entity == STATES.USER_SETTINGS) {
      return userSettings?.[setting?.keyPath]
    } else {
      return setting?.keyPath.split('.')?.length > 1
        ? (reduceValue(survey, [
            setting?.keyPath.split('.')[0],
            language,
            setting?.keyPath.split('.')[1],
          ]) ?? '')
        : survey?.[setting?.keyPath]
    }
  }

  const updateSurveySettings = (setting, value, entity) => {
    const key = setting?.keyPath
    if (entity === Entities.languageSetting) {
      const updateValue = {
        [key.split('.')[0]]: {
          [language]: {
            ...survey?.[key.split('.')[0]]?.[language],
            [key.split('.')[1]]: value,
          },
        },
      }
      update(updateValue)
      const operation = createBufferOperation({ sid: id, language })
        .languageSetting()
        .update({
          ...survey?.[key.split('.')[0]]?.[language],
          [key.split('.')[1]]: value,
        })

      addToBuffer(operation)
    } else {
      update({ [key]: value })

      const updateProps = {
        [key]: value,
      }

      if (key === 'language') {
        updateProps['additionalLanguages'] = survey.languages
      }
      if (setting?.ignore) {
        return
      }
      const operation = createBufferOperation(id).survey().update(updateProps)
      addToBuffer(operation)
    }
  }

  const updateValue = (value, setting) => {
    if (setting?.entity === STATES.USER_SETTINGS) {
      setUserSettings({
        ...userSettings,
        [setting?.keyPath]: value,
      })
    } else {
      setting?.entity === Entities.languageSetting
        ? updateSurveySettings(setting, value, Entities.languageSetting)
        : updateSurveySettings(setting, value)
    }
  }

  return (
    <div className="mt-5 p-4 bg-white">
      {surveySettingsAttributes?.[clickedQuestionSettings?.value]?.map(
        (setting, idx) => {
          if (
            (setting.renderCondition &&
              survey?.[setting?.renderCondition] !== true) ||
            setting.hidden
          ) {
            return <div key={`${idx}-settings-${setting.keyPath}`}></div>
          }
          const selectOptions =
            setting.keyPath === 'language'
              ? languageOptionsAsArray
              : setting.keyPath === 'template'
                ? themeOptionsArray
                : setting.keyPath === 'format'
                  ? formatOptionsArray
                  : []

          return (
            <div
              className="mb-2 survey-setting"
              key={`${idx}-settings-${setting.keyPath}`}
            >
              <setting.component
                options={selectOptions}
                {...setting.props}
                value={getValue(survey, setting)}
                update={(value, _setting = setting) =>
                  updateValue(value, _setting)
                }
                setting={setting}
                surveyId={id}
              />
            </div>
          )
        }
      )}
      {clickedQuestionSettings?.value === 'advancedOptions' && (
        <AdvancedOptionsSettings surveyId={id} />
      )}
    </div>
  )
}
