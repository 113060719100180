import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import classNames from 'classnames'
import { PlusLg } from 'react-bootstrap-icons'
import { Button } from 'react-bootstrap'
import { useState } from 'react'

import { useBuffer } from 'hooks'
import { createBufferOperation, getAnswerExample } from 'helpers'

import { RankingAdvancedQuestionAnswersPlaceholder } from './RankingAdvancedQuestionAnswersPlaceholder'
import { RankingAdvancedQuestionAnswers } from './RankingAdvancedQuestionAnswers'

const ANSWERS_DROPPALE_ID = 'ranking-advanced-answers'

export const RankingAdvancedQuestion = ({
  question: { answers = [], qid } = {},
  question,
  handleUpdate = () => {},
  isFocused,
  language,
}) => {
  const [answersHeight, setAnswersHeight] = useState([])
  const { addToBuffer } = useBuffer()

  const handleAddingAnswers = () => {
    const updatedAnswers = [...answers]

    const newAnswer = getAnswerExample({
      qid: qid,
      sortOrder: updatedAnswers.length + 1,
    })

    updatedAnswers.push(newAnswer)

    const operation = createBufferOperation(qid)
      .answer()
      .create([...updatedAnswers])

    addToBuffer(operation)
    handleUpdate({ answers: updatedAnswers })
  }

  const handleOnDragEnd = (dropResult) => {
    // dropped outside the list
    if (
      !dropResult.destination ||
      dropResult.destination.droppableId !== ANSWERS_DROPPALE_ID
    ) {
      return
    }

    const updatedQuestionAnswers = reorderQuestionAnswers(
      answers,
      dropResult.source.index,
      dropResult.destination.index
    )

    const operation = createBufferOperation(qid)
      .answer()
      .update({ ...updatedQuestionAnswers })

    question.answers = updatedQuestionAnswers
    addToBuffer(operation)
    handleUpdate({ question })
  }

  const handleAnswerUpdate = (value, index) => {
    const answer = answers[index]

    answer.l10ns = answer.l10ns ? answer.l10ns : {}
    answer.l10ns = {
      ...answer.l10ns,
      [language]: { ...answer.l10ns[language], answer: value },
    }

    const operation = createBufferOperation(qid)
      .answer()
      .update([...answers])

    addToBuffer(operation)
    handleUpdate({ question })
  }

  const handleRemoveAnswer = (index) => {
    const updatedAnswers = [...answers]
    updatedAnswers.splice(index, 1)

    const operation = createBufferOperation(qid)
      .answer()
      .create([...updatedAnswers])

    addToBuffer(operation)
    handleUpdate({ answers: updatedAnswers })
  }

  const reorderQuestionAnswers = (listRadioAnswers, startIndex, endIndex) => {
    const updatedList = [...listRadioAnswers]
    const [removed] = updatedList.splice(startIndex, 1)
    updatedList.splice(endIndex, 0, removed)

    return updatedList.map((answer, index) => {
      answer.sortOrder = index + 1
      return answer
    })
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div
        data-testid="ranking-advanced-question"
        className={'ranking-advanced-question d-flex flex-row'}
      >
        <Droppable droppableId={ANSWERS_DROPPALE_ID}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <RankingAdvancedQuestionAnswers
                droppableProvider={provided}
                isFocused={isFocused}
                handleAddingAnswers={handleAddingAnswers}
                handleAnswerUpdate={handleAnswerUpdate}
                handleRemoveAnswer={handleRemoveAnswer}
                handleOnDragEnd={handleOnDragEnd}
                answers={answers}
                qid={qid}
                setAnswersHeight={setAnswersHeight}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {!isFocused && (
          <RankingAdvancedQuestionAnswersPlaceholder
            isFocused={isFocused}
            answers={answers}
            answersHeight={answersHeight}
          />
        )}
      </div>
      <div>
        <Button
          onClick={handleAddingAnswers}
          variant={'outline'}
          className={classNames('text-primary add-choice-button px-0 mt-2', {
            'd-none disabled': !isFocused,
          })}
          data-testid="single-choice-add-answer-button"
        >
          <PlusLg /> Add answer option
        </Button>
      </div>
    </DragDropContext>
  )
}
