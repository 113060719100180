import { Direction } from 'react-range'
import { Form } from 'react-bootstrap'

import {
  ContentEditor,
  InputRange,
  NumericInput,
} from 'components/UIComponents'
import { isTrue } from 'helpers'

export const MultipleChoiceNumericalSubquestion = ({
  useSlider,
  orientation,
  value,
  isFocused,
}) => {
  return (
    <Form.Group className="d-flex w-100 gap-3 align-items-top col-4">
      <Form.Label className="m-0 col-4">
        <ContentEditor
          disabled={true}
          value={value}
          className="multi-choice-content-editor choice"
        />
      </Form.Label>
      {isTrue(useSlider) ? (
        <div
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <InputRange
            showInput={false}
            direction={orientation ? orientation : Direction.Right}
          />
        </div>
      ) : (
        <div
          className="col-8"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <NumericInput
            placeholder="Enter your answer here."
            dataTestId={'multiple-choice-comment-input'}
            disabled={isFocused}
          />
        </div>
      )}
    </Form.Group>
  )
}
