export const NotFound = () => {
  return (
    <div
      style={{ height: '100vh' }}
      className="d-flex gap-4 flex-column justify-content-center align-items-center"
    >
      <h1>404</h1> page doesn't exist
    </div>
  )
}
