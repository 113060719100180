import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components'
import { useAuth, useSurveyService } from 'hooks'
import { L10ns, RemoveHTMLTagsInString } from 'helpers'

export const Home = () => {
  const auth = useAuth()
  const [surveysList, setSurveysList] = useState([])
  const { surveyService } = useSurveyService()
  const navigate = useNavigate()

  useEffect(() => {
    if (!auth.isLoggedIn || process.env.REACT_APP_DEMO_MODE === 'true') {
      return
    }

    surveyService.getSurveyList().then((data) => {
      setSurveysList(data.surveys)
    })
  }, [auth.isLoggedIn])

  const login = () => {
    const loginPage = window.open(
      `${process.env.REACT_APP_SITE_URL}/EditorLink`
    )
    setTimeout(() => {
      loginPage.close()
    }, 500)
  }

  const logout = () => {
    auth.logout()
  }

  return (
    <div className="p-5">
      <h1 className="mb-5">{'LimeSurvey Editor'}</h1>
      {auth.isLoggedIn && (
        <>
          <div className="d-flex gap-3">
            {surveysList.map((survey) => {
              let title = L10ns({
                prop: 'title',
                language: survey.language,
                l10ns: survey.languageSettings,
              })
              return (
                <Button
                  onClick={() => navigate(`/survey/${survey.sid}`)}
                  key={survey.sid}
                  text={RemoveHTMLTagsInString(title)}
                  variant="outline-success"
                />
              )
            })}
          </div>
          <div className="mt-4">
            <h3 className="mb-2">
              If you don't see surveys. then it means you are not logged in to
              the core app. login to the core app and then logout and login
              again.
            </h3>
            <Button
              variant="outline-success"
              onClick={logout}
              text={'Click to logout'}
            />
          </div>
        </>
      )}
      {!auth.isLoggedIn && (
        <div>
          <h6>
            Make sure you are logged in to the core app and then click the
            button below
          </h6>
          <Button
            variant="outline-success"
            onClick={login}
            text={'Click to login'}
          />
        </div>
      )}
    </div>
  )
}
