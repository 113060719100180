export const getQuestionById = (id, survey) => {
  // for loop with index to find the question
  for (let i = 0; i < survey.questionGroups.length; i++) {
    const group = survey.questionGroups[i]
    for (let j = 0; j < group.questions.length; j++) {
      const question = group.questions[j]
      if (question.qid === id) {
        return { question, groupIndex: i, questionIndex: j }
      }
    }
  }

  return {}
}
