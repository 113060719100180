import React from 'react'
export const QuestionInserterMultipleChoiceIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="none"
      className={props.className}
    >
      <rect
        width="15"
        height="2.456"
        y="3.947"
        fill="#333641"
        opacity=".2"
        rx="1"
      />
      <rect width="15" height="2.456" y="8.246" fill="#333641" rx="1" />
      <rect width="15" height="2.456" fill="#333641" rx="1" />
      <rect width="15" height="2.456" y="12.544" fill="#333641" rx="1" />
    </svg>
  )
}
