import React from 'react'
import { STATES, TOOLTIP_MESSAGES } from 'helpers'

import { useAppState } from 'hooks'
import { SettingsWrapper } from 'components/UIComponents'

import { TooltipContainer } from '../TooltipContainer/TooltipContainer'

export const Setting = ({
  question,
  handleUpdate,
  isAdvanced = false,
  language = 'en',
  title = '',
  attributes = [],
  simpleSettings = false,
}) => {
  const [isSurveyActive] = useAppState(STATES.IS_SURVEY_ACTIVE)
  const getAttributeValueFromPath = (attributePath, languageBased) => {
    const path = attributePath.split('.')
    const attribute = path.reduce((acc, key) => acc[key], question)

    if (!attribute) {
      return ''
    }

    if (['string', 'number', 'boolean'].includes(typeof attribute)) {
      return attribute
    } else if (typeof attribute === 'object') {
      return attribute[languageBased ? language : '']
    }

    return undefined
  }

  const getUpdateValueFromPath = (value, attribute) => {
    const attributePath = attribute?.attributePath ?? ''
    const attributeName = attributePath.toString().includes('attributes.')
      ? attributePath.replace('attributes.', '')
      : ''

    const isAttribute = attributePath.includes('attributes.')

    const updateValue = {}

    if (isAttribute) {
      updateValue[attributeName] = {
        [attribute.languageBased ? language : '']: value,
      }
    } else {
      attribute.returnValues.map((returnValue) => {
        updateValue[returnValue] = value[returnValue]
          ? value[returnValue]
          : value
      })
    }

    return { ...updateValue }
  }

  if (!attributes.length) {
    return <></>
  }

  return (
    <SettingsWrapper
      simpleSettings={simpleSettings}
      isAdvanced={isAdvanced}
      title={title}
    >
      {attributes.map((attribute) => {
        if (
          ((attribute.attributePath === 'relevance' ||
            attribute.attributePath === 'attributes.image') &&
            process.env.REACT_APP_DEV_MODE) ||
          attribute.hidden
        ) {
          return (
            <React.Fragment
              key={`${title}-settings-${attribute.attributePath}`}
            ></React.Fragment>
          )
        }

        // Child level means the question is inside the attributes section
        // like attributes.numbers_only => thats a child level attribute
        // but mandatory for instance is not a child level attribute
        const isChildLevelAttribute =
          attribute.attributePath.includes('attributes.')

        return (
          <div
            className="mb-3 question-setting"
            key={`${title}-settings-${attribute.attributePath}${attribute.props.labelText}`}
          >
            {/*Globally disabled when survey is active for beta */}
            <TooltipContainer
              tip={TOOLTIP_MESSAGES.ACTIVE_DISABLED}
              showTip={isSurveyActive}
            >
              <attribute.component
                {...attribute.props}
                activeDisabled={isSurveyActive}
                noPermissionDisabled={true}
                value={getAttributeValueFromPath(attribute.attributePath)}
                name={attribute.attributePath}
                update={(value) => {
                  handleUpdate(
                    getUpdateValueFromPath(value, attribute),
                    isChildLevelAttribute
                  )
                }}
              />
            </TooltipContainer>
          </div>
        )
      })}
    </SettingsWrapper>
  )
}
