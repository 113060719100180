import React from 'react'
import classNames from 'classnames'
import { StructureIcon, SurveySettingsIcon } from 'components/icons'
import { SurveySettingsComponent } from 'components/SurveySettings/SurveySettingsComponent'

import { SideBar } from 'components/SideBar'
import { SurveyStructure } from 'components/SurveyStructure'
import { TooltipContainer } from 'components/TooltipContainer/TooltipContainer'
import { useSurvey, useAppState } from 'hooks'
import { STATES } from 'helpers'

import { FeedbackButton } from './FeedbackButton'

export const LeftSideBar = ({ surveyId }) => {
  const { survey = {}, update } = useSurvey(surveyId)
  const [userSettings] = useAppState(STATES.USER_SETTINGS, {})

  // this is not just for structure, but for the whole panel
  const [editorStructurePanelOpen, setEditorStructurePanelOpen] = useAppState(
    STATES.IS_EDITOR_STRUCTURE_PANEL_OPEN,
    true
  )

  // this is only for settings content
  const [surveySettingsPanelOpen, setSurveySettingsPanelOpen] = useAppState(
    STATES.IS_SURVEY_SETTINGS_OPEN,
    false
  )

  return (
    <SideBar
      testId="left-sidebar"
      visible={true}
      className={classNames('sidebar', 'sidebar-left')}
    >
      <div className="d-flex" style={{ height: '100%' }}>
        <div
          className="d-flex flex-wrap align-content-between"
          style={{ height: '100%', width: '52px' }}
        >
          <div>
            <div
              onClick={() => {
                setEditorStructurePanelOpen(true)
                setSurveySettingsPanelOpen(false)
              }}
              className="cursor-pointer"
              data-testid="btn-structure-open"
            >
              <TooltipContainer
                offset={[0, 20]}
                placement="right"
                tip={'Survey structure'}
              >
                <StructureIcon
                  className={`${
                    editorStructurePanelOpen && !surveySettingsPanelOpen
                      ? 'text-black'
                      : 'text-white'
                  } fill-current`}
                  bgColor={`${
                    editorStructurePanelOpen && !surveySettingsPanelOpen
                      ? '#EEEFF7'
                      : '#333641'
                  }`}
                />
              </TooltipContainer>
            </div>
            <div
              onClick={() => {
                setEditorStructurePanelOpen(true)
                setSurveySettingsPanelOpen(true)
              }}
              className="mt-3 cursor-pointer"
            >
              <TooltipContainer
                offset={[0, 20]}
                placement="right"
                tip={'Survey settings'}
              >
                <SurveySettingsIcon
                  data-testid="btn-survey-settings"
                  className={`${
                    editorStructurePanelOpen && surveySettingsPanelOpen
                      ? 'text-black'
                      : 'text-white'
                  } fill-current`}
                  bgcolor={`${
                    editorStructurePanelOpen && surveySettingsPanelOpen
                      ? '#EEEFF7'
                      : '#333641'
                  }`}
                />
              </TooltipContainer>
            </div>
          </div>
          <FeedbackButton />
        </div>
        {editorStructurePanelOpen && (
          <div>
            {surveySettingsPanelOpen ? (
              <SurveySettingsComponent surveyId={surveyId} />
            ) : (
              <SurveyStructure
                survey={survey}
                userSettings={userSettings}
                surveyId={surveyId}
                update={(questionGroups) => {
                  survey.questionGroups = questionGroups
                  update({ ...survey })
                }}
              />
            )}
          </div>
        )}
      </div>
    </SideBar>
  )
}
