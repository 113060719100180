import { useEffect, useState } from 'react'

import { Input } from 'components/UIComponents'
import { useAppState, useFocused } from 'hooks'
import { TestValidation } from '../../Survey/Questions/QuestionCodeSchema'
import { STATES } from '../../../helpers'

// note: make it faster using debounce ... read about it.
export const QuestionCodeAttribute = ({ value, update }) => {
  const [codeToQuestion] = useAppState(STATES.CODE_TO_QUESTION, {})
  const [errorMessage, setErrorMessage] = useState('')
  const [inputValue, setInputValue] = useState(value)
  const { focused } = useFocused()

  useEffect(() => {
    setInputValue(value)
    validateCode(value)
  }, [focused?.qid])

  const validateCode = (value) => {
    const codeQuestion =
      codeToQuestion && codeToQuestion[value] ? codeToQuestion[value] : ''
    const codeQuestionIsNotFocused =
      codeQuestion?.question?.qid !== focused?.qid
    const codeExist = codeQuestion && codeQuestionIsNotFocused
    if (value) {
      setErrorMessage(
        TestValidation(value.toUpperCase()).error
          ? 'Only letters and numbers are allowed.'
          : ''
      )
    }

    if (codeExist) {
      setErrorMessage('Question codes must be unique.')
    }
  }
  const handleOnChange = ({ target: { value } }) => {
    setInputValue(value.toUpperCase())
    validateCode(value)
    if (errorMessage === '') {
      update(inputValue)
    }
  }

  const handleOnBlur = () => {
    if (errorMessage === '') {
      update(inputValue)
    }
  }

  return (
    <Input
      onChange={handleOnChange}
      value={inputValue}
      errorMessage={errorMessage}
      onBlur={handleOnBlur}
      dataTestId="question-code"
      labelText="Question Code"
      labelClass="text-nowrap d-block m-1"
      inputClass="d-block w-100"
      toolTipClassName={'w-50 d-block'}
      className="d-flex justify-content-between align-items-center w-100"
      noPermissionDisabled={true}
    />
  )
}
