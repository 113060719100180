import { ToggleButtons } from 'components/UIComponents'

import { SurveySettingComponent } from '../SurveySettingComponent'

export const surveyParticipantsAttributes = [
  {
    component: SurveySettingComponent,
    keyPath: 'anonymized',
    props: {
      id: 'anonymize-responses',
      mainText: 'Anonymized responses',
      subText: 'optional multiline text description',
      childComponent: ToggleButtons,
      toggleOptions: [
        { name: 'On', value: true },
        { name: 'Off', value: false },
      ],
      activeDisabled: true,
      noPermissionDisabled: true,
    },
  },
  {
    component: SurveySettingComponent,
    keyPath: 'allowedItAfterCompletion',
    props: {
      id: 'allow-multiple-responses',
      mainText:
        'Allow multiple responses or update responses with one access code',
      subText: 'optional multiline text description',
      childComponent: ToggleButtons,
      toggleOptions: [
        { name: 'On', value: true },
        { name: 'Off', value: false },
      ],
      noPermissionDisabled: true,
    },
  },
]
