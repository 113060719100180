import { ToggleButtons, Select, ContentEditor } from 'components/UIComponents'
import { Entities } from 'helpers'

import { SurveySettingComponent } from '../SurveySettingComponent'

export const surveyPresentationAttributes = [
  {
    component: SurveySettingComponent,
    keyPath: 'allowPrev',
    props: {
      id: 'allow-backward-navigation',
      mainText: 'Allow backward navigation',
      subText: 'optional multiline text description',
      childComponent: ToggleButtons,
      toggleOptions: [
        { name: 'On', value: true },
        { name: 'Off', value: false },
      ],
      noPermissionDisabled: true,
    },
  },
  {
    component: SurveySettingComponent,
    keyPath: 'showProgress',
    props: {
      id: 'show-progress-bar',
      mainText: 'Show progress bar',
      subText: 'optional multiline text description',
      childComponent: ToggleButtons,
      toggleOptions: [
        { name: 'On', value: true },
        { name: 'Off', value: false },
      ],
      noPermissionDisabled: true,
    },
  },
  {
    component: SurveySettingComponent,
    keyPath: 'autoRedirect',
    props: {
      id: 'auto-load-end-url',
      mainText: 'Automatically load end URL when survey complete',
      subText: 'optional multiline text description',
      childComponent: ToggleButtons,
      toggleOptions: [
        { name: 'On', value: true },
        { name: 'Off', value: false },
      ],
      noPermissionDisabled: true,
    },
  },
  {
    component: SurveySettingComponent,
    keyPath: 'languageSettings.surveyAlias',
    entity: Entities.languageSetting,
    props: {
      childComponent: ContentEditor,
      extraClass: 'editable-content-editor',
      id: 'survey-alias',
      mainText: 'Survey Alias',
      placeholder: 'Survey Alias',
      noPermissionDisabled: true,
    },
  },
  {
    component: Select,
    keyPath: 'format',
    props: {
      labelText: 'Format',
      dataTestId: '',
      noPermissionDisabled: true,
    },
  },
]
