import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { useAppState } from '../../../hooks'
import { STATES } from '../../../helpers'

export const NumericInput = ({
  value = '',
  dataTestId = '',
  onChange = () => {},
  update = () => {},
  inputRef = { current: {} },
  placeholder = '',
  id = '',
  max = 100,
  min = 0,
  allowEmpty = false,
  labelText,
  leftIcons,
  disabled,
  activeDisabled = false,
  noPermissionDisabled = false,
  rightInputText = '',
}) => {
  const [isSurveyActive] = useAppState(STATES.IS_SURVEY_ACTIVE)
  const [hasSurveyUpdatePermission] = useAppState(
    STATES.HAS_SURVEY_UPDATE_PERMISSION
  )
  const surveyActiveDisabled = isSurveyActive && activeDisabled
  const permissionDisabled = !hasSurveyUpdatePermission && noPermissionDisabled

  const handleOnChange = (event) => {
    const value = event.target.value

    if (+value > max) {
      event.target.value = max
    } else if (+value < min) {
      event.target.value = min
    } else {
      event.target.value = value
    }

    onChange(event)
    update(event.target.value)
  }

  const handleOnBlur = (event) => {
    const value = event.target.value

    if (!+value && !allowEmpty) {
      inputRef.current.value = 0
      event.target.value = 0
    } else if (+value < min) {
      inputRef.current.value = min
      event.target.value = min
    } else if (+value > max) {
      inputRef.current.value = max
      event.target.value = max
    }

    onChange(event)
    update(event.target.value)
  }

  useEffect(() => {
    if (!inputRef?.current) {
      return
    }

    inputRef.current.value = value
  }, [inputRef, value])

  return (
    <Form.Group className="numeric">
      {leftIcons && typeof leftIcons === 'string' && (
        <img
          style={{ left: 0, right: '100%' }}
          src={leftIcons}
          className="qe-input-icon-left"
          alt="input icon"
        />
      )}
      {leftIcons && typeof leftIcons !== 'string' && (
        <span className="qe-input-icon-left">{leftIcons}</span>
      )}
      {labelText && <Form.Label>{labelText}</Form.Label>}
      <span className="position-relative">
        <Form.Control
          type="number"
          ref={inputRef}
          id={id}
          data-testid={dataTestId}
          placeholder={placeholder}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          defaultValue={value}
          onKeyDown={(event) => {
            if (['e', 'E'].includes(event.key)) {
              event.preventDefault()
            }
          }}
          className="form-control"
          disabled={disabled || surveyActiveDisabled || permissionDisabled}
        />
        <span className="position-absolute fs-6 top-50 form-control-right-input-text">
          {rightInputText}
        </span>
      </span>
    </Form.Group>
  )
}
export default NumericInput
