import { EntitiesType } from './EntitiesType'

export const EntitiesPath = {
  survey: `${EntitiesType.survey}.$property`,
  languageSetting: `${EntitiesType.survey}.languageSettings.$language`,
  question: `${EntitiesType.question}.$attributeName`,
  questionAttribute: `${EntitiesType.question}.attributes.$attributeName`,
  questionGroupL10n: `${EntitiesType.groupName}.l10ns.$language`,
  questionL10n: `${EntitiesType.question}.l10ns.$language`,
  answer: `${EntitiesType.question}.answers.$language`, // todo: update it.
  subquestion: `${EntitiesType.question}.subquestions.$language`, // todo: update it.
  questionGroup: 'questionGroup', // todo: update it.
  questionGroupReorder: 'questionGroupReorder', // todo: update it.
}
