import React from 'react'
export const QuestionInserterNumberIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
    >
      <path
        d="M4.66833 14.7634V3.22833H2.76011L0 5.22267V7.05645L2.6664 5.15506H2.76011V14.7634H4.66833Z"
        fill="#333641"
      />
      <path
        d="M9.89039 6.55786C9.89039 6.54096 9.89039 6.48181 9.89039 6.45645C9.89039 5.45929 10.606 4.57197 11.8327 4.57197C12.9316 4.57197 13.7409 5.28182 13.7409 6.35505C13.7409 7.36067 13.0594 8.12967 12.429 8.81417L8.10995 13.5634V14.7634H15.8791V13.1662H10.7934V13.0564L13.6642 9.85359C14.7206 8.68741 15.6747 7.70714 15.6747 6.19449C15.6747 4.36916 14.1754 3.00016 11.8753 3.00016C9.31963 3.00016 8.0418 4.71563 8.0418 6.46491V6.55786H9.89039Z"
        fill="#333641"
      />
      <path
        d="M20.5475 9.59162H21.8253C23.1628 9.59162 24.0572 10.3691 24.0658 11.493C24.0828 12.6338 23.1713 13.4535 21.7572 13.4451C20.5049 13.4366 19.6019 12.769 19.5167 11.9071H17.7363C17.8044 13.6057 19.2611 15 21.7401 15C24.1424 15 26.0336 13.6479 25.9995 11.5437C25.9655 9.70148 24.5002 8.88177 23.478 8.78037V8.67896C24.3469 8.5353 25.6673 7.61419 25.6332 5.97477C25.5906 4.2762 24.1169 2.98326 21.7998 3.00016C19.3634 3.00861 18.0344 4.41986 17.9833 6.05928H19.7978C19.8489 5.25647 20.6071 4.53817 21.7572 4.53817C22.8987 4.53817 23.7165 5.23957 23.7165 6.26209C23.725 7.29306 22.8987 8.04517 21.7657 8.04517H20.5475V9.59162Z"
        fill="#333641"
      />
    </svg>
  )
}
