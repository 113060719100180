export const getAnswerById = (id, survey) => {
  // Iterate through each question group
  for (let i = 0; i < survey.questionGroups.length; i++) {
    const group = survey.questionGroups[i]
    // Iterate through each question in the group
    for (let j = 0; j < group.questions.length; j++) {
      const question = group.questions[j]
      // Check if answers exist and iterate through them if they do
      if (question.answers && question.answers.length) {
        for (let k = 0; k < question.answers.length; k++) {
          const answer = question.answers[k]
          // Compare the answer ID
          if (answer.aid === id) {
            // Return the answer along with its location
            return {
              answer,
              groupIndex: i,
              questionIndex: j,
              answerIndex: k,
            }
          }
        }
      }
    }
  }

  // Return empty object if no answer is found
  return {}
}
