import React, { useState } from 'react'

import { useDropzone } from 'react-dropzone'
import { Button, Form } from 'react-bootstrap'
import categorizeFileTypes from 'helpers/categorizeFileTypes'
import { DeleteIcon, UploadIcon, EditIcon } from 'components/icons'
import { ImageEditor } from 'components/UIComponents/ImageEditor/ImageEditor'
import { FileRejectionItems } from './FileRejectionItems'

export const FileUpload = ({ question }) => {
  const [uploadedImages, setUploadedImages] = useState([])
  const [show, setShow] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [selectedFile, setSelectedFile] = useState(null)

  const allowedFileTypes = question?.attributes?.allowedFileTypes?.value
  const accept = categorizeFileTypes(allowedFileTypes)
  const maxFileSize = question?.attributes?.maximumFileSizeAllowed?.value

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleChange = (file) => {
    uploadedImages[selectedIndex] = { ...file }
    setUploadedImages([...uploadedImages])
  }

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: (acceptedFiles) => {
      setUploadedImages((prev) => [
        ...prev,
        ...acceptedFiles.map((file) => ({
          ...file,
          origin: URL.createObjectURL(file),
          preview: URL.createObjectURL(file),
          zoom: 1,
          rotate: 0,
          radius: 0,
        })),
      ])
    },
    disabled: true,
    accept,
    maxFiles: 1,
    maxSize: maxFileSize ? maxFileSize * 1024 : 1024 * 1000000,
  })

  const handleDeleteImage = (index) => {
    uploadedImages.splice(index, 1)
    setUploadedImages([...uploadedImages])
  }
  const handleEditImage = (index) => {
    handleShow(true)
    setSelectedFile(uploadedImages[index])
    setSelectedIndex(index)
  }

  return (
    <div className="disable-select" data-testid="file-upload">
      {uploadedImages.length > 0 &&
        uploadedImages?.map((file, index) => {
          return (
            <div className="mb-3 " key={index}>
              <div
                className="position-relative image-wrapper"
                style={{
                  maxWidth: '300px',
                  background: 'rgba(0, 0, 0, 0.05)',
                }}
              >
                <img
                  key={index}
                  src={file.preview}
                  alt={file.name}
                  className="bg-light"
                  style={{
                    width: '100%',
                    minHeight: '100px',
                    borderRadius: `${file.radius * 1.5}px`,
                  }}
                />
                <div className="position-absolute image-handle-btn-wrapper">
                  <Button
                    variant="outline-light"
                    className="image-handle-btn ms-1"
                    size="sm"
                    onClick={() => handleEditImage(index)}
                  >
                    <EditIcon className="text-primary fill-current" />
                  </Button>
                  <Button
                    variant="outline-light"
                    className="image-handle-btn ms-1"
                    size="sm"
                    onClick={() => handleDeleteImage(index)}
                  >
                    <DeleteIcon className="text-primary fill-current" />
                  </Button>
                </div>
              </div>

              {question.attributes.showTitle?.value && (
                <div>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    placeholder="Enter your answer here."
                    data-testid="text-question-answer-input"
                  />
                </div>
              )}
              {question.attributes.showComment?.value && (
                <div className="mt-1">
                  <Form.Label>Comment</Form.Label>
                  <Form.Control
                    placeholder="Enter your answer here."
                    as="textarea"
                    rows={4}
                    maxLength={Infinity}
                    data-testid="text-question-answer-input"
                  />
                </div>
              )}
            </div>
          )
        })}
      <FileRejectionItems fileRejections={fileRejections} />

      <div
        style={{ cursor: 'pointer' }}
        {...getRootProps({ className: 'dropzone' })}
      >
        <input {...getInputProps()} />
        <div className="">
          <UploadIcon className="icon" />
        </div>
        <p className="label">Choose file or drop image here</p>
      </div>
      {selectedFile && (
        <ImageEditor
          showModal={show}
          onClose={handleClose}
          onChange={handleChange}
          file={selectedFile}
        />
      )}
    </div>
  )
}
