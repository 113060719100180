import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import ReactSelect from 'react-select'
import classNames from 'classnames'

import { useAppState } from '../../../hooks'
import { STATES, TOOLTIP_MESSAGES } from '../../../helpers'
import { TooltipContainer } from '../../TooltipContainer/TooltipContainer'

export const Select = ({
  labelText,
  options = [],
  value,
  className = '',
  dataTestId = '',
  onChange = () => {},
  update = () => {},
  activeDisabled = false,
  noPermissionDisabled = false,
  onMenuClose = () => {},
  onMenuOpen = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState({})
  const [isSurveyActive] = useAppState(STATES.IS_SURVEY_ACTIVE)
  const [hasSurveyUpdatePermission] = useAppState(
    STATES.HAS_SURVEY_UPDATE_PERMISSION
  )
  const disabled =
    (isSurveyActive && activeDisabled) ||
    (!hasSurveyUpdatePermission && noPermissionDisabled)
  const toolTip =
    isSurveyActive && activeDisabled
      ? TOOLTIP_MESSAGES.ACTIVE_DISABLED
      : TOOLTIP_MESSAGES.NO_PERMISSION
  useEffect(() => {
    if (value && options?.length) {
      setSelectedOption(options.find((option) => option.value === value))
    } else if (!value && options?.length) {
      setSelectedOption(options[0])
    }
  }, [value, options])

  const handleOnChange = (selectedOption) => {
    onChange(selectedOption)
    update(selectedOption.value)
  }

  const handleOnMenuOpen = () => {
    setIsOpen(true)
    onMenuOpen()
  }

  const handleOnMenuClose = () => {
    setIsOpen(false)
    onMenuClose()
  }

  return (
    <div
      className={classNames(`select-component ${className}`, {
        'select-component-open': isOpen,
      })}
      data-testid={dataTestId}
    >
      {labelText && <Form.Label htmlFor="select">{labelText}</Form.Label>}
      <TooltipContainer tip={toolTip} showTip={disabled}>
        <ReactSelect
          classNames={{
            control: () => 'select',
          }}
          defaultValue={options[0]}
          value={selectedOption}
          onChange={handleOnChange}
          options={options}
          placeholder=""
          components={{
            IndicatorSeparator: () => null,
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#8146F6',
            },
          })}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
            }),
            dropdownIndicator: (base) => ({
              ...base,
              'color': '#6E748C',
              'min-width': 'fit-content',
            }),
            control: (baseStyles) => ({
              ...baseStyles,
              'borderRadius': '4px',
              'borderWidth': '2px',
              'borderColor': ' #6E748C',
              'boxShadow': 'none',
              'fontWeight': 400,
              'fontSize': '0.9975rem',
              '&:hover': {
                borderColor: ' #6E748C',
              },
            }),
            option: (baseStyles) => ({
              ...baseStyles,
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              width: '100%',
              minWidth: '100px',
            }),
          }}
          isDisabled={disabled}
          onMenuOpen={handleOnMenuOpen}
          onMenuClose={handleOnMenuClose}
        />
      </TooltipContainer>
    </div>
  )
}
