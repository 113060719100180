import { QuestionTypeInfo } from 'components/QuestionTypes'

import {
  SHORT_TEXT_SETTINGS,
  LONG_TEXT_SETTINGS,
  MULTIPLE_SHORT_TEXT_SETTINGS,
} from './textQuestion'

import {
  BROWSER_DETECTION_SETTINGS,
  DATE_TIME_SETTINGS,
  FILE_UPLOAD_SETTINGS,
} from './datesAndData'
import {
  EQUATION_SETTINGS,
  MULTIPLE_NUMERICAL_INPUTS_SETTINGS,
  TEXT_DISPLAY_SETTINGS,
} from './number'
import {
  LIST_IMAGE_SELECT_SETTINGS,
  LIST_RADIO_SETTINGS,
  LIST_RADIO_WITH_COMMENT_SETTINGS,
  FIVE_POINT_CHOICE_SETTINGS,
  DROPDOWN_SETTINGS,
} from './singleChoice'
import {
  MULTIPLE_CHOICE_BUTTONS_SETTINGS,
  MULTIPLE_CHOICE_IMAGE_SETTINGS,
  MULTIPLE_CHOICE_SETTINGS,
  MULTIPLE_CHOICE_WITH_COMMENTS_SETTINGS,
} from './multipleChoice'
import { SINGLE_CHOICE_BUTTONS_SETTINGS } from './singleChoice/singleChoiceButtons'
import {
  ARRAY_BY_COLUMN_SETTINGS,
  ARRAY_DUAL_SCALE_SETTINGS,
  ARRAY_NUMBERS_SETTINGS,
  ARRAY_POINT_CHOICE_SETTINGS,
  ARRAY_TEXTS_SETTINGS,
} from './array'
import {
  GENDER_SETTINGS,
  RANKING_ADVANCED_SETTINGS,
  RANKING_SETTINGS,
  RATING_SETTINGS,
  YES_NO_SETTINGS,
} from './rankingAndRating'

export const SETTINGS = {
  [QuestionTypeInfo.ARRAY.theme]: ARRAY_POINT_CHOICE_SETTINGS,
  [QuestionTypeInfo.ARRAY_COLUMN.theme]: ARRAY_BY_COLUMN_SETTINGS,
  [QuestionTypeInfo.ARRAY_DUAL_SCALE.theme]: ARRAY_DUAL_SCALE_SETTINGS,
  [QuestionTypeInfo.ARRAY_NUMBERS.theme]: ARRAY_NUMBERS_SETTINGS,
  [QuestionTypeInfo.ARRAY_TEXT.theme]: ARRAY_TEXTS_SETTINGS,
  [QuestionTypeInfo.BROWSER_DETECTION.theme]: BROWSER_DETECTION_SETTINGS,
  [QuestionTypeInfo.DATE_TIME.theme]: DATE_TIME_SETTINGS,
  [QuestionTypeInfo.END_SCREEN.theme]: SHORT_TEXT_SETTINGS,
  [QuestionTypeInfo.EQUATION.theme]: EQUATION_SETTINGS,
  [QuestionTypeInfo.FILE_UPLOAD.theme]: FILE_UPLOAD_SETTINGS,
  [QuestionTypeInfo.FIVE_POINT_CHOICE.theme]: FIVE_POINT_CHOICE_SETTINGS,
  [QuestionTypeInfo.GENDER.theme]: GENDER_SETTINGS,
  [QuestionTypeInfo.LIST_RADIO.theme]: LIST_RADIO_SETTINGS,
  [QuestionTypeInfo.SINGLE_CHOICE_LIST_IMAGE_SELECT.theme]:
    LIST_IMAGE_SELECT_SETTINGS,
  [QuestionTypeInfo.LIST_RADIO_WITH_COMMENT.theme]:
    LIST_RADIO_WITH_COMMENT_SETTINGS,
  [QuestionTypeInfo.SHORT_TEXT.theme]: SHORT_TEXT_SETTINGS,
  [QuestionTypeInfo.LONG_TEXT.theme]: LONG_TEXT_SETTINGS,
  [QuestionTypeInfo.MULTIPLE_SHORT_TEXTS.theme]: MULTIPLE_SHORT_TEXT_SETTINGS,
  [QuestionTypeInfo.MULTIPLE_CHOICE.theme]: MULTIPLE_CHOICE_SETTINGS,
  [QuestionTypeInfo.MULTIPLE_CHOICE_BUTTONS.theme]:
    MULTIPLE_CHOICE_BUTTONS_SETTINGS,
  [QuestionTypeInfo.MULTIPLE_CHOICE_IMAGE_SELECT.theme]:
    MULTIPLE_CHOICE_IMAGE_SETTINGS,
  [QuestionTypeInfo.MULTIPLE_CHOICE_WITH_COMMENTS.theme]:
    MULTIPLE_CHOICE_WITH_COMMENTS_SETTINGS,
  [QuestionTypeInfo.MULTIPLE_NUMERICAL_INPUTS.theme]:
    MULTIPLE_NUMERICAL_INPUTS_SETTINGS,
  [QuestionTypeInfo.QUESTION_GROUP.theme]: SHORT_TEXT_SETTINGS,
  [QuestionTypeInfo.RANKING.theme]: RANKING_SETTINGS,
  [QuestionTypeInfo.RANKING_ADVANCED.theme]: RANKING_ADVANCED_SETTINGS,
  [QuestionTypeInfo.RATING.theme]: RATING_SETTINGS,
  [QuestionTypeInfo.SINGLE_CHOICE_BUTTONS.theme]:
    SINGLE_CHOICE_BUTTONS_SETTINGS,
  [QuestionTypeInfo.SINGLE_CHOICE_DROPDOWN.theme]: DROPDOWN_SETTINGS,
  [QuestionTypeInfo.TEXT_DISPLAY.theme]: TEXT_DISPLAY_SETTINGS,
  [QuestionTypeInfo.WELCOME_SCREEN.theme]: SHORT_TEXT_SETTINGS,
  [QuestionTypeInfo.YES_NO.theme]: YES_NO_SETTINGS,
}
