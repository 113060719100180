import isString from 'lodash/isString'

import { LANGUAGE_CODES } from 'helpers'

export const L10ns = ({ prop, language, l10ns }) => {
  const defaultLanguage = LANGUAGE_CODES.EN

  if (l10ns?.[language]?.[prop] && isString(l10ns[language][prop])) {
    return l10ns[language][prop]
  } else if (
    l10ns?.[defaultLanguage]?.[prop] &&
    isString(l10ns[defaultLanguage][prop])
  ) {
    return l10ns[defaultLanguage][prop]
  }

  return ''
}
