import {
  Input,
  NumericInput,
  Select,
  ToggleButtons,
} from 'components/UIComponents'

export const OTHER_ATTRIBUTES = {
  MIN_NUMBER_FILES: {
    component: NumericInput,
    attributePath: 'attributes.min_num_of_files',
    props: {
      labelText: 'Min number of files',
      dataTestId: 'min-number-of-files',
      defaultValue: '1',
    },
  },
  ALLOWED_FILE_TYPES: {
    component: Input,
    attributePath: 'attributes.allowed_filetypes',
    props: {
      labelText: 'Allowed file types',
      dataTestId: 'allowed-file-types',
      defaultValue: 'png, gif, doc, odt, jpg, jpeg',
    },
  },
  MAXIMUM_FILE_SIZE_ALLOWED: {
    component: NumericInput,
    attributePath: 'attributes.max_filesize',
    props: {
      labelText: 'Max file size (KB)',
      dataTestId: 'maximum-file-size-allowed',
      min: 1,
      defaultValue: '1024',
    },
  },
  MAX_NUMBER_FILES: {
    component: NumericInput,
    attributePath: 'attributes.max_num_of_files',
    props: {
      labelText: 'Max number of files',
      dataTestId: 'max-number-of-files',
      min: 1,
      defaultValue: '1',
    },
  },
  CHOICE_HEADER: {
    component: Input,
    attributePath: 'attributes.choice_title',
    props: {
      labelText: 'Choice header',
      dataTestId: 'choice-header',
    },
  },
  RANK_HEADER: {
    component: Input,
    attributePath: 'attributes.rank_title',
    props: {
      labelText: 'Rank header',
      dataTestId: 'rank-header',
    },
  },
  SHOW_GRAND_TOTAL: {
    component: ToggleButtons,
    attributePath: 'attributes.show_grand_total',
    props: {
      labelText: 'Show grand total',
      dataTestId: 'show-grand-total',
      toggleOptions: [
        { name: 'On', value: '1' },
        { name: 'Off', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  SHOW_TOTALS_FOR: {
    component: Select,
    attributePath: 'attributes.show_totals',
    props: {
      id: 'use-slider-layout',
      labelText: 'Show totals for',
      dataTestId: 'show-totals-for',
      options: [
        { label: 'Off', value: 'X' },
        { label: 'Rows', value: 'R' },
        { label: 'Columns ', value: 'C' },
        { label: 'Rows & Columns ', value: 'B' },
      ],
    },
  },
  INSERT_PAGE_BREAK_IN_PRINTABLE_VIEW: {
    component: ToggleButtons,
    attributePath: 'attributes.page_break',
    props: {
      id: 'insert-page-break-in-printable-view',
      labelText: 'Insert page break in printable view',
      toggleOptions: [
        { name: 'Yes', value: '1' },
        { name: 'No', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  SPSS_EXPORT_SCALE_TYPE: {
    component: Select,
    attributePath: 'attributes.scale_export',
    props: {
      labelText: 'SPSS export scale type',
      options: [
        {
          label: 'Default',
          value: '0',
        },
        {
          label: 'Nominal',
          value: '1',
        },
        {
          label: 'Ordinal',
          value: '2',
        },
        {
          label: 'Scale',
          value: '3',
        },
      ],
    },
  },
}
