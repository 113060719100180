import classNames from 'classnames'
import Button from 'react-bootstrap/Button'

import { useAppState } from 'hooks'
import { SideBarHeader } from 'components/SideBar'
import { CloseIcon } from 'components/icons'

import { Form } from 'react-bootstrap'
import { STATES } from 'helpers'

const questionSettingsOptions = [
  {
    label: 'General',
    value: 'general',
  },
  {
    label: 'Presentation',
    value: 'presentation',
  },
  {
    label: 'Privacy Policy',
    value: 'privacyPolicy',
  },
  {
    label: 'Participants',
    value: 'participants',
  },
  {
    label: 'Notifications and data',
    value: 'notificationsData',
  },
  {
    label: 'Publication & access',
    value: 'publicationAccess',
  },
  {
    label: 'Advanced options',
    value: 'advancedOptions',
  },
]

export const SurveySettingsComponent = () => {
  const [clickedQuestionSettings, setClickedQuestionSettings] = useAppState(
    STATES.CLICKED_QUESTION_SETTINGS,
    {
      label: 'General',
      value: 'general',
    }
  )
  const [, setEditorStructurePanelOpen] = useAppState(
    STATES.IS_EDITOR_STRUCTURE_PANEL_OPEN,
    true
  )

  const handleClose = () => setEditorStructurePanelOpen(false)

  return (
    <div
      data-testid="survey-settings-panel"
      className="d-flex"
      style={{ height: '100%' }}
    >
      <div
        className="survey-structure px-2"
        style={{ overflowY: 'auto', width: '290px' }}
      >
        <div className={classNames('survey-settings')}>
          <SideBarHeader className="right-side-bar-header primary">
            Survey Settings
            <Button
              variant="link"
              className="p-0 btn-close-lime"
              onClick={handleClose}
              data-testid="btn-close-survey-settings-panel"
            >
              <CloseIcon className="text-black fill-current" />
            </Button>
          </SideBarHeader>
          {questionSettingsOptions.map((option, idx) => {
            return (
              <div
                key={`${idx}-${option.value}`}
                onClick={() => setClickedQuestionSettings({ ...option })}
                className={`px-4 py-3 d-flex align-items-center cursor-pointer rounded ${
                  clickedQuestionSettings?.value === option?.value
                    ? 'bg-primary text-white'
                    : 'text-black'
                }`}
              >
                <Form.Label
                  className={` cursor-pointer ${
                    clickedQuestionSettings?.value === option?.value
                      ? 'text-white'
                      : 'text-black'
                  } mb-0`}
                >
                  {option.label}
                </Form.Label>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
