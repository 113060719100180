import { SETTINGS_TITLES } from 'helpers'

import {
  TIMER_ATTRIBUTES,
  STATISTICS_ATTRIBUTES,
  OTHER_ATTRIBUTES,
  LOGIC_ATTRIBUTES,
  DISPLAY_ATTRIBUTES,
  GENERAL_ATTRIBUTES,
} from '../attributes'

const simpleSettings = [
  GENERAL_ATTRIBUTES.QUESTION_CODE,
  GENERAL_ATTRIBUTES.QUESTION_TYPE,
  GENERAL_ATTRIBUTES.MANDATORY,
  GENERAL_ATTRIBUTES.NUMBERS_ONLY,
  GENERAL_ATTRIBUTES.MAX_CHARACTERS,
  DISPLAY_ATTRIBUTES.IMAGE_SETTINGS,
  GENERAL_ATTRIBUTES.LOGIC,
  STATISTICS_ATTRIBUTES.SHOW_IN_STATISTICS,
]

const generalSettings = [
  GENERAL_ATTRIBUTES.QUESTION_CODE,
  GENERAL_ATTRIBUTES.QUESTION_TYPE,
  GENERAL_ATTRIBUTES.MANDATORY,
  GENERAL_ATTRIBUTES.NUMBERS_ONLY,
  GENERAL_ATTRIBUTES.MAX_CHARACTERS,
  GENERAL_ATTRIBUTES.ENCRYPTED,
  GENERAL_ATTRIBUTES.SAVE_AS_DEFAULT,
  GENERAL_ATTRIBUTES.INPUT_VALIDATION,
]

const displaySettings = [
  DISPLAY_ATTRIBUTES.IMAGE_SETTINGS,
  DISPLAY_ATTRIBUTES.ANSWER_PREFIX,
  DISPLAY_ATTRIBUTES.ANSWER_SUFFIX,
  DISPLAY_ATTRIBUTES.HIDE_TIP,
  DISPLAY_ATTRIBUTES.ALWAYS_HIDE_THIS_QUESTION,
  DISPLAY_ATTRIBUTES.CSS_CLASSES,
  DISPLAY_ATTRIBUTES.TEXT_INPUT_WIDTH,
  DISPLAY_ATTRIBUTES.TEXT_INPUT_BOX_SIZE,
  DISPLAY_ATTRIBUTES.DISPLAY_ROWS,
]

const logicSettings = [
  LOGIC_ATTRIBUTES.RANDOMIZATION_GROUP_NAME,
  LOGIC_ATTRIBUTES.QUESTION_VALIDATION_EQUATION,
  LOGIC_ATTRIBUTES.QUESTION_VALIDATION_TIP,
]

const otherSettings = [
  OTHER_ATTRIBUTES.INSERT_PAGE_BREAK_IN_PRINTABLE_VIEW,
  OTHER_ATTRIBUTES.SPSS_EXPORT_SCALE_TYPE,
]

const inputSettings = []

const statisticsSettings = [STATISTICS_ATTRIBUTES.SHOW_IN_STATISTICS]

const timerSettings = Object.values(TIMER_ATTRIBUTES)

const themeOptionsSettings = []

const fileMetaDataSettings = []

const locationSettings = []

const sliderAttributes = []

export const SHORT_TEXT_SETTINGS = [
  { title: SETTINGS_TITLES.SIMPLE, attributes: simpleSettings },
  { title: SETTINGS_TITLES.GENERAL, attributes: generalSettings },
  { title: SETTINGS_TITLES.DISPLAY, attributes: displaySettings },
  { title: SETTINGS_TITLES.LOGIC, attributes: logicSettings },
  { title: SETTINGS_TITLES.OTHER, attributes: otherSettings },
  { title: SETTINGS_TITLES.INPUT, attributes: inputSettings },
  { title: SETTINGS_TITLES.STATISTICS, attributes: statisticsSettings },
  { title: SETTINGS_TITLES.TIMER, attributes: timerSettings },
  {
    title: SETTINGS_TITLES.THEME_OPTIONS,
    attributes: themeOptionsSettings,
  },
  {
    title: SETTINGS_TITLES.FILE_META_DATA,
    attributes: fileMetaDataSettings,
  },
  { title: SETTINGS_TITLES.LOCATION, attributes: locationSettings },
  {
    title: SETTINGS_TITLES.SLIDER,
    attributes: sliderAttributes,
  },
]
