import { DateTimePickerComponent } from 'components/UIComponents'

import { SurveySettingComponent } from '../SurveySettingComponent'

export const surveyPublicationAccessAttributes = [
  {
    component: SurveySettingComponent,
    keyPath: 'startDate',
    props: {
      id: 'start-date-time',
      mainText: 'Start date time',
      subText: 'optional multiline text description',
      childComponent: DateTimePickerComponent,
      noPermissionDisabled: true,
    },
  },
  {
    component: SurveySettingComponent,
    keyPath: 'expires',
    props: {
      id: ' end-date-time',
      mainText: 'End date time',
      subText: 'optional multiline text description',
      childComponent: DateTimePickerComponent,
      noPermissionDisabled: true,
    },
  },
]
