import { useEffect } from 'react'
import { Form } from 'react-bootstrap'

import { useAppState } from 'hooks'
import { STATES, TOOLTIP_MESSAGES } from 'helpers'

import { TooltipContainer } from '../../TooltipContainer/TooltipContainer'

export const Input = ({
  value = '',
  dataTestId,
  onChange = () => {},
  onBlur = () => {},
  inputRef = { current: { value: '' } },
  type = 'text',
  role = 'input',
  rows = 1,
  placeholder = 'Enter here',
  id = '',
  Icon,
  leftIcons,
  labelText,
  paddinRight = '40px',
  paddingLeft = '40px',
  disabled = false,
  max = Infinity,
  className = '',
  style = {},
  errorMessage,
  inputClass = '',
  labelClass = '',
  showClassWhenValue = false,
  update = () => {},
  activeDisabled = false,
  noPermissionDisabled = false,
  autoComplete = true,
  toolTipClassName = '',
}) => {
  useEffect(() => {
    if (!inputRef?.current) {
      return
    }

    inputRef.current.value = value
  }, [inputRef, value])

  const handleOnChange = (event) => {
    onChange(event)
    update(event.target.value)
  }

  const [isSurveyActive] = useAppState(STATES.IS_SURVEY_ACTIVE)
  const [hasSurveyUpdatePermission] = useAppState(
    STATES.HAS_SURVEY_UPDATE_PERMISSION
  )
  const inputDisabled =
    (isSurveyActive && activeDisabled) ||
    disabled ||
    (!hasSurveyUpdatePermission && noPermissionDisabled)

  const toolTip =
    isSurveyActive && activeDisabled
      ? TOOLTIP_MESSAGES.ACTIVE_DISABLED
      : !hasSurveyUpdatePermission && noPermissionDisabled
        ? TOOLTIP_MESSAGES.NO_PERMISSION
        : ''

  return (
    <Form.Group
      style={{
        position: Icon && 'relative',
        ...style,
      }}
      className={`qe-input-group ${className}`}
    >
      {leftIcons && typeof leftIcons === 'string' && (
        <img
          style={{ left: 0, right: '100%' }}
          src={leftIcons}
          className="qe-input-icon-left"
          alt="input icon"
        />
      )}
      {leftIcons && typeof leftIcons !== 'string' && (
        <span className="qe-input-icon-left">{leftIcons}</span>
      )}
      {labelText && (
        <Form.Label className={`ui-label ${labelClass}`}>
          {labelText}
        </Form.Label>
      )}
      <TooltipContainer
        className={toolTipClassName}
        tip={toolTip}
        showTip={inputDisabled}
      >
        <Form.Control
          pattern="[A-Z0-9]+"
          disabled={inputDisabled}
          id={id}
          ref={inputRef}
          data-testid={dataTestId}
          placeholder={placeholder}
          onChange={handleOnChange}
          defaultValue={value}
          type={type}
          role={role}
          rows={rows}
          as={role}
          onBlur={onBlur}
          style={{
            paddingRight: Icon && paddinRight,
            paddingLeft: leftIcons && paddingLeft,
          }}
          className={`form-control ${
            showClassWhenValue ? (value ? inputClass : '') : inputClass
          }`}
          maxLength={max ? max : Infinity}
          autoComplete={autoComplete ? 1 : 0}
        />
      </TooltipContainer>
      {Icon && typeof Icon === 'string' && (
        <img src={Icon} className="qe-input-icon-right" alt="input icon" />
      )}
      {Icon && typeof Icon !== 'string' && (
        <span className="qe-input-icon-right">{Icon}</span>
      )}
      {errorMessage && (
        <Form.Text
          style={{ fontSize: '14px', fontWeight: '500' }}
          className="text-danger"
        >
          {errorMessage}
        </Form.Text>
      )}
    </Form.Group>
  )
}
