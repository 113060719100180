import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Form, Button, Image } from 'react-bootstrap'
import { UploadIcon } from 'components/icons'
import { DeleteIcon } from 'components/icons'

export const DropZone = ({
  onReaderResult = () => {},
  image,
  labelText = '',
  dataTestId = 'dropzone',
}) => {
  // const [styleWithImage, setStyleWithImage] = useState({})
  const [showTrashIcon, setShowTrashIcon] = useState(false)
  const handleOnMouseHover = () => setShowTrashIcon(true)
  const handleOnMouseLeave = () => setShowTrashIcon(false)
  const [file, setFile] = useState(null)
  useEffect(() => {
    if (image) {
      setFile(image)
    }
  }, [image])

  // should be updated like file upload editor
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })

        onReaderResult({
          origin: URL.createObjectURL(file),
          preview: URL.createObjectURL(file),
        })
        setFile(file)
        // setStyleWithImage({
        //   backgroundImage: `url(${file.preview})`,
        //   backgroundRepeat: 'no-repeat',
        //   backgroundSize: 'cover',
        //   backgroundPosition: 'center',
        // })
      })
    },
    [onReaderResult]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  })

  // useEffect(() => {
  //   setStyleWithImage(
  //     image
  //       ? {
  //           backgroundImage: `url(${image})`,
  //           backgroundRepeat: 'no-repeat',
  //           backgroundSize: 'cover',
  //           backgroundPosition: 'center',
  //         }
  //       : {}
  //   )
  // }, [image])

  return (
    <div style={{ cursor: 'pointer' }} data-testid={dataTestId}>
      {labelText.length ? <Form.Label>{labelText}</Form.Label> : <></>}
      {file && file.preview ? (
        <div
          className="border border-3 border-secondary rounded"
          onMouseOver={handleOnMouseHover}
          onMouseLeave={handleOnMouseLeave}
        >
          <div
            className="position-relative "
            style={{
              maxWidth: '300px',
              background: 'rgba(0, 0, 0, 0.05)',
            }}
          >
            <Image
              src={file?.preview}
              alt="Image Select List"
              style={{
                width: '100%',
              }}
            />
            <div
              className={`position-absolute image-handle-btn-wrapper ${
                showTrashIcon ? '' : 'd-none'
              }`}
            >
              <Button
                variant="outline-light"
                className="image-handle-btn ms-1"
                size="sm"
                onClick={() => {
                  setShowTrashIcon(false)
                  setFile(null)
                }}
              >
                <DeleteIcon className="text-primary fill-current" />
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <UploadIcon className="icon" />
          <p className="label">Drop image here</p>
        </div>
      )}
    </div>
  )
}
