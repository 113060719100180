import React, { useRef, useState, useEffect } from 'react'
import { XLg } from 'react-bootstrap-icons'
import copy from 'copy-text-to-clipboard'
import QRCode from 'react-qr-code'
import classNames from 'classnames'

import { Button, Input } from 'components'
import { ReactComponent as CopyIcon } from 'assets/icons/copy-icon.svg'
import { ReactComponent as SettingsIcon } from 'assets/icons/setttings-icon.svg'
import { DownloadIcon } from 'components/icons'
import { errorToast } from 'helpers'

export const PublishMenu = React.forwardRef(function PublishMenu(props, ref) {
  const linkPrefix =
    window.location.protocol + '//' + window.location.host + '/'

  const linkRef = useRef(null)
  const qrCodeRef = useRef(null)
  const [link, setLink] = useState('' + props.survey.sid)
  const [editingLink, setEditingLink] = useState(false)

  useEffect(() => {
    if (editingLink && linkRef.current) {
      linkRef.current.focus()
    }
  }, [editingLink])

  const handleOnCopyButtonClick = () => {
    copy(linkPrefix + link)
  }

  const handleLinkChange = (value) => {
    setLink(value)
  }

  const handleEditingLink = () => {
    if (!link.trim()) {
      errorToast('Link cannot be empty.')
      return
    }

    setEditingLink(!editingLink)
  }

  const convertSvgToPngAndDownload = () => {
    const svgString = new XMLSerializer().serializeToString(qrCodeRef.current)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const img = new Image()
    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0)

      const link = document.createElement('a')
      link.setAttribute('href', canvas.toDataURL('image/png'))
      link.setAttribute('download', 'QRCode.png')
      link.click()
    }

    img.src =
      'data:image/svg+xml;base64,' +
      btoa(unescape(encodeURIComponent(svgString)))
  }

  return (
    <div {...props} ref={ref} className="publish-settings-popover">
      <div className="p-3 pb-0 header fw-bold d-flex align-items-center justify-content-between">
        <p>Publish</p>
        <p>
          <XLg
            cursor={'pointer'}
            stroke={'black'}
            fontWeight={800}
            color="black"
            size={15}
            onClick={() => props.setIsSurveyShareMenuOpen(false)}
          />
        </p>
      </div>
      <div className="px-3 pt-2">
        <p className="public-link-header mb-2">PUBLIC LINK</p>
        <div className="mb-4">
          {editingLink && (
            <Input
              inputRef={linkRef}
              disabled={!editingLink}
              placeholder="Link"
              value={link}
              paddinRight="60px"
              onChange={({ target: { value } }) => handleLinkChange(value)}
              leftIcons={
                <span
                  className={classNames('limesurvey-link', {
                    disabled: editingLink,
                  })}
                >
                  {linkPrefix}
                </span>
              }
              paddingLeft="166px"
              Icon={
                <>
                  <Button
                    variant="light"
                    className="copy-button me-1"
                    onClick={handleEditingLink}
                  >
                    <SettingsIcon />
                  </Button>
                  <Button
                    variant="light"
                    className="settings-button"
                    onClick={handleOnCopyButtonClick}
                  >
                    <CopyIcon />
                  </Button>
                </>
              }
            />
          )}
          {!editingLink && (
            <Input
              inputRef={linkRef}
              disabled={!editingLink}
              placeholder="Link"
              value={linkPrefix + link}
              paddinRight="60px"
              onChange={({ target: { value } }) => handleLinkChange(value)}
              paddingLeft="166px"
              Icon={
                <>
                  <Button
                    variant="light"
                    className="copy-button me-1"
                    onClick={handleEditingLink}
                  >
                    <SettingsIcon />
                  </Button>
                  <Button
                    variant="light"
                    className="settings-button"
                    onClick={handleOnCopyButtonClick}
                  >
                    <CopyIcon />
                  </Button>
                </>
              }
            />
          )}
        </div>
        <div>
          <div className="d-flex justify-content-center my-4">
            <QRCode
              title="Survey link"
              value={linkPrefix + link}
              className="qr-code"
              ref={qrCodeRef}
            />
          </div>
          <div className="text-end">
            <Button
              variant="outline-light"
              onClick={convertSvgToPngAndDownload}
              className="d-flex justify-content-between"
            >
              Download QR Code <DownloadIcon height={24} width={24} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
})
