import { STATES } from 'helpers'

import { ToggleButtons, Select } from 'components/UIComponents'

import { SurveySettingComponent } from '../SurveySettingComponent'

export const surveyGeneralAttributes = [
  {
    component: Select,
    keyPath: 'language',
    props: {
      labelText: 'Language',
      dataTestId: '',
      noPermissionDisabled: true,
    },
  },
  {
    component: Select,
    keyPath: 'template',
    props: {
      labelText: 'Theme',
      dataTestId: '',
      noPermissionDisabled: true,
    },
  },
  {
    component: SurveySettingComponent,
    keyPath: 'showQuestionCode',
    entity: STATES.USER_SETTINGS,
    props: {
      id: 'show-question-code',
      mainText: 'Show Question codes in structure',
      subText: '',
      childComponent: ToggleButtons,
      toggleOptions: [
        { name: 'On', value: true },
        { name: 'Off', value: false },
      ],
      noPermissionDisabled: true,
    },
  },
  {
    component: SurveySettingComponent,
    keyPath: 'cookieConsent',
    props: {
      id: 'cookie-consent',
      mainText: 'Cookie consent',
      subText: '',
      childComponent: ToggleButtons,
      toggleOptions: [
        { name: 'On', value: true },
        { name: 'Off', value: false },
      ],
      noPermissionDisabled: true,
    },
    hidden: !process.env.REACT_APP_DEV_MODE,
  },
  {
    component: SurveySettingComponent,
    keyPath: 'limeSurveyBranding',
    props: {
      id: 'limeSurvey-branding',
      mainText: 'LimeSurvey Branding',
      subText: '',
      childComponent: ToggleButtons,
      toggleOptions: [
        { name: 'On', value: true },
        { name: 'Off', value: false },
      ],
      noPermissionDisabled: true,
    },
    hidden: !process.env.REACT_APP_DEV_MODE,
  },
]
