import { STATES, createBufferOperation, getAnswerExample } from 'helpers'
import { useAppState, useBuffer } from 'hooks'

import { SingleChoiceAnswers } from './SingleChoiceAnswers'

export const SingleChoice = ({
  question: { answers, qid } = {},
  question,
  handleUpdate = () => {},
  isFocused,
}) => {
  const [surveyLanguage] = useAppState(STATES.SURVEY_LANGUAGE)
  const { addToBuffer } = useBuffer()

  const handleUpdateAnswer = (newAnswerValue, index) => {
    const updatedQuestionAnswers = [...answers]

    if (updatedQuestionAnswers[index]['l10ns'] === undefined) {
      updatedQuestionAnswers[index]['l10ns'] = {}
    }
    if (updatedQuestionAnswers[index]['l10ns'][surveyLanguage] === undefined) {
      updatedQuestionAnswers[index]['l10ns'][surveyLanguage] = {}
    }

    updatedQuestionAnswers[index]['l10ns'][surveyLanguage].answer =
      newAnswerValue

    const operation = createBufferOperation(question.qid)
      .answer()
      .update({
        ...updatedQuestionAnswers,
      })

    addToBuffer(operation)
    handleUpdate({ question })
  }

  const handleUpdateNoAnswerAttribute = (value) => {
    question.attributes.no_answer = {
      ...question.attributes.no_answer,
      [surveyLanguage]: {
        value: value,
      },
    }

    handleUpdate({ attributes: question.attributes })
  }

  const handleAddingAnswers = () => {
    const updatedQuestionAnswers = [...answers]

    const newAnswer = getAnswerExample({
      qid,
      sortOrder: updatedQuestionAnswers.length + 1,
      language: surveyLanguage,
    })

    updatedQuestionAnswers.push(newAnswer)

    const operation = createBufferOperation(question.qid)
      .answer()
      .create({ ...updatedQuestionAnswers })

    addToBuffer(operation)
    handleUpdate({ answers: updatedQuestionAnswers })
  }

  const handleRemovingAnswers = (answerId) => {
    const updatedQuestionAnswers = answers.filter(
      (answer) => answer.aid !== answerId
    )

    const operation = createBufferOperation(answerId).answer().delete()

    addToBuffer(operation)
    handleUpdate({ answers: updatedQuestionAnswers })
  }

  const handleOnDragEnd = (dropResult) => {
    // dropped outside the list
    if (!dropResult.destination) {
      return
    }

    const updatedQuestionAnswers = reorderQuestionAnswers(
      answers,
      dropResult.source.index,
      dropResult.destination.index
    )

    const operation = createBufferOperation(question.qid)
      .answer()
      .update({ ...updatedQuestionAnswers })

    addToBuffer(operation)

    handleUpdate({ answers: updatedQuestionAnswers })
  }

  const reorderQuestionAnswers = (listRadioAnswers, startIndex, endIndex) => {
    const updatedList = [...listRadioAnswers]
    const [removed] = updatedList.splice(startIndex, 1)
    updatedList.splice(endIndex, 0, removed)

    return updatedList.map((answer, index) => {
      answer.sortOrder = index + 1
      return answer
    })
  }

  return (
    <SingleChoiceAnswers
      handleAddingAnswers={handleAddingAnswers}
      handleOnDragEnd={handleOnDragEnd}
      handleRemovingAnswers={handleRemovingAnswers}
      handleUpdateAnswer={handleUpdateAnswer}
      isFocused={isFocused}
      question={question}
      handleUpdateNoAnswerAttribute={handleUpdateNoAnswerAttribute}
      surveyLanguage={surveyLanguage}
    />
  )
}
