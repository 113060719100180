import React from 'react'
export const FeedbackIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="4" fill="#14AE5C" />
      <path
        d="M17.9999 22.695L12.7102 25.656L13.8914 19.71L9.44019 15.594L15.4604 14.88L17.9999 9.375L20.5394 14.88L26.5597 15.594L22.1084 19.71L23.2897 25.656L17.9999 22.695Z"
        fill="white"
      />
      <path
        d="M6.99982 24.1088L4.35134 25.5913L4.94277 22.6142L2.71411 20.5534L5.72834 20.1959L6.99982 17.4397L8.2713 20.1959L11.2855 20.5534L9.05687 22.6142L9.6483 25.5913L6.99982 24.1088Z"
        fill="white"
      />
      <path
        d="M28.8773 24.1088L26.2288 25.5913L26.8202 22.6142L24.5916 20.5534L27.6058 20.1959L28.8773 17.4397L30.1487 20.1959L33.163 20.5534L30.9343 22.6142L31.5257 25.5913L28.8773 24.1088Z"
        fill="white"
      />
    </svg>
  )
}
