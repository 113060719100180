import { useState } from 'react'
import { Form, FormCheck } from 'react-bootstrap'
import classNames from 'classnames'

import { QuestionTypeInfo } from '../QuestionTypeInfo'
import { COMMENTED_CHECKBOX_OPTIONS } from 'helpers'
import { ContentEditor } from 'components/UIComponents'

export const MultipleChoiceCheckboxSubquestion = ({
  subQuestion,
  questionThemeName,
  value,
  commentedCheckbox,
}) => {
  const [isChecked, setIsChecked] = useState(false)

  const showCommentInput =
    ((isChecked &&
      commentedCheckbox === COMMENTED_CHECKBOX_OPTIONS.CHECKED.value) ||
      (!isChecked &&
        commentedCheckbox === COMMENTED_CHECKBOX_OPTIONS.UNCHECKED.value) ||
      commentedCheckbox === COMMENTED_CHECKBOX_OPTIONS.ALWAYS.value) &&
    questionThemeName === QuestionTypeInfo.MULTIPLE_CHOICE_WITH_COMMENTS.theme

  return (
    <div className="d-flex align-items-center w-100 gap-4 multiple-choice-checkbox-subquestion">
      <Form.Group className="d-flex gap-4 align-items-center">
        <FormCheck
          value={subQuestion.qid}
          type={'checkbox'}
          checked={isChecked}
          name={`${subQuestion.qid}-multiple-choice-checkbox`}
          data-testid="multiple-choice-checkbox-input"
          onChange={() => {}}
          onClick={(e) => {
            e.stopPropagation()
            setIsChecked(!isChecked)
          }}
        />
        <ContentEditor
          className={classNames('multi-choice-checkbox-label choice', {
            'active-green-color': isChecked,
          })}
          data-placeholder="Edit text"
          value={value}
          disabled={true}
        />
      </Form.Group>
      {showCommentInput && (
        <div className="flex-1 w-50">
          <Form.Control
            onClick={(e) => {
              e.stopPropagation()
            }}
            value=""
            placeholder="Enter your comment here."
            rows={1}
            maxLength={Infinity}
            data-testid="multiple-choice-comment-input"
            defaultValue={subQuestion.assessmentComment}
            disabled={true}
          />
        </div>
      )}
    </div>
  )
}
